@font-face {
  font-family: "OswaldBold";
  src: local("Oswald-Bold"),
    url("../../assets/fonts/Oswald/Oswald-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "BarlowSemiCondensed-Bold";
  src: local("BarlowSemiCondensed-Bold"),
    url("../../assets/fonts/barlow-semi-condensed/BarlowSemiCondensed-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Regular";
  src: local("Inter-Regular"),
    url("../../assets/fonts/inter/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url("../../assets/fonts/roboto/Roboto-Regular.ttf") format("truetype");
}

.d-btn {
  height: 48px;
  width: 160px;
  border-radius: 100px !important;
  border: 2px solid #2dd0ff !important;
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  /* box-shadow: 0px 4px 10px 2px #00000080; */
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin-top: 32px;
  border: 2px solid #2dd0ff !important;
  padding: 0 !important;
}

#home .swiper-button-prev {
  top: 57.8% !important;
}

#home .swiper-button-next {
  top: 57.8% !important;
}

.d-veuitbgimg {
  background-image: url("../images/path659.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

#favorites-contens .swiper-container {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.d-veuit-img {
  height: 232px;
  width: 156px;
  border: 1px solid #1891b4;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px #00000080;
  object-fit: cover;
}

img.img-fluid.d-influ-img {
  height: 232px;
  width: 156px;
  border: 1px solid #1891b4;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px #00000080;
  object-fit: cover;
}

.d-video-banner {
  height: 100%;
  /* width: 100%; */
  object-fit: fill !important;
  left: 0;
  /* position: absolute; */
  top: 0;
  width: auto !important;
  margin-right: auto;
  margin-left: auto;
}

.d-block-description {
  top: -80px;
}

.d-movie-title {
  font-size: 28px !important;
  font-weight: 700 !important;
  line-height: 32px !important;
  letter-spacing: 1px !important;
  text-align: left !important;
  font-family: "Roboto", sans-serif;
}

.d-view-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 1px;
  text-align: right;
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  font-family: "Poppins", sans-serif;
}

.main-content {
  padding-top: 36px;
}

.d-slide-txt {
  width: 100% !important;
}

/* 
#d-slider {
    width: 194px !important;
    height: 288px !important;
    margin-right: 20px !important;
}  */

/* #d-influ-slider {
    width: 194px !important;
    height: 194px !important;
    margin-right: 20px !important;
} */

#d-play-btn {
  font-family: "Barlow Semi Condensed", sans-serif;
  border: 2px solid #2dd0ff !important;
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%) !important;
}

.d-set-prev {
  background-color: unset !important;
  left: -43px !important;
  top: 49% !important;
  height: auto !important;
}

.d-set-next {
  right: -43px !important;
  background-color: unset !important;
  top: 49% !important;
  height: auto !important;
}

.s-set-prev {
  background-color: unset !important;
  left: -43px !important;
  top: 49% !important;
  height: auto !important;
}

.s-set-next {
  right: -43px !important;
  background-color: unset !important;
  top: 49% !important;
  height: auto !important;
}

#d-container {
  width: 90% !important;
  margin: 0 auto;
  padding: 0 !important;
}

.d-icon {
  height: 11px !important;
  width: 20px !important;
  color: #2dd0ff;
}

#iq-tvthrillers .d-icon {
  color: #ffffff !important;
}

#iq-brand .d-icon {
  color: #ffffff !important;
}

.d-title-txt-one {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 40px;
  letter-spacing: 1px;
  color: #ffffff;
  padding-bottom: 25px;
}

.d-txt-one {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 38px;
  letter-spacing: 1px;
  color: #ffffff;
  overflow: scroll;
  height: 106px !important;
}

.d-txt-one::-webkit-scrollbar {
  display: none;
}

.d-txt-one {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.d-set-overflow {
  overflow: unset !important;
}

#home-slider .slick-bg {
  height: 600px !important;
}

/* .d-vedio-border {
    border-top: 1px solid #1891B4;
    border-bottom: 1px solid #1891B4;
} */

.d-brand-img {
  height: 136px;
  width: 224px;
  border-radius: 20px;
  box-shadow: 0px 0px 15px 2px #000000bf;
  border: 1px solid #616161;
  object-fit: cover;
}

/* #d-brand-slider {
    width: 289px !important;
    height: 194px !important;
    margin-right: 11px !important;
} */

.d-brand-network {
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight: 900;
  line-height: 48px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #ffffff;
  margin-bottom: 15px;
}

#b-container {
  width: 90%;
  padding: 0 !important;
  margin: 0 auto !important;
}

.b-set-prev {
  background-color: unset !important;
  top: 31% !important;
  height: auto !important;
  left: -43px !important;
}

.b-set-next {
  background-color: unset !important;
  top: 31% !important;
  height: auto !important;
  right: -43px !important;
}

.d-brand-con {
  width: 100% !important;
  padding: 0 !important;
}

.d-brand-prev {
  background-color: unset !important;
  height: auto !important;
}

.e-brand-prev {
  top: 56.8% !important;
  background-color: unset !important;
  height: auto !important;
}

.d-channel-prev {
  top: 45.5% !important;
  background-color: unset !important;
  height: auto !important;
  left: 32px !important;
}

.d-channel-next {
  top: 45.5% !important;
  background-color: unset !important;
  height: auto !important;
  right: 32px !important;
}

#brand-slider .slick-bg {
  height: auto !important;
}

.d-brand-btn {
  height: 48px;
  width: 160px;
  border-radius: 100px !important;
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%) !important;
  border: 2px solid #2dd0ff;
  font-size: 16px;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: 1px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  padding: 0 !important;
  margin-top: 32px;
  box-shadow: 0px 4px 10px 2px #00000080;
  text-transform: uppercase;
}

#d-brand-network {
  /* z-index: 2;
    position: absolute;
    left: 0;
    right: 0; */
  display: block !important;
}

.d-brand-networks {
  position: relative;
  padding: 0 !important;
}

.ratings span {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 38px;
  letter-spacing: 1px;
  color: #ffffff;
}

.d-season {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 38px;
  letter-spacing: 1px;
  color: #ffffff;
  padding-top: 17px;
}

.d-ratings-and-season {
  display: flex;
  padding-bottom: 10px;
}

.d-trending-info {
  padding: 115px 0 0 0;
}

.d-title-feature {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  padding-top: 11px;
}

#feature-bgimg {
  background-size: cover !important;
}

.d-set-featured {
  padding-top: 291px;
  padding-bottom: 94px;
}

.set-brand-con {
  margin: 0 !important;
}

#d-space-show {
  margin: 0 !important;
}

.parallax-window {
  padding: 99px 0;
}

.d-para-window {
  padding-top: 99px;
}

.education-block {
  height: 500px;
  padding-top: 1px;
  /* margin-top: 72px; */
}

.parallax-img img {
  text-align: end;
  height: 379px;
  width: 677px !important;
  border-radius: 30px;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.6);
}

.edu-initi {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 45px;
  font-weight: 700;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: left;
  color: #f2f2f2 !important;
}

.para-window {
  width: 1364px !important;
  margin: 0 auto;
}

.edu-description {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #e4e4e4;
  margin: 0 !important;
}

/* #d-tvchannel-slider {
    width: 289px !important;
    height: 194px !important;
    margin-right: 22px !important;
} */

.d-tvchannel-img {
  width: 289px !important;
  height: 194px !important;
  border-radius: 10px !important;
  object-fit: cover;
}

.exclusive-event {
  height: 555px !important;
  width: 100% !important;
}

.exclusive-events {
  box-sizing: content-box;
  /* border-top: 1px solid #1891B4; */
  width: 100% !important;
  /* border-bottom: 1px solid #1891B4; */
}

.event-videos {
  object-fit: fill !important;
  width: 100% !important;
  height: 438px !important;
  padding: 1px;
}

.event-video {
  object-fit: cover !important;
  width: 100% !important;
  height: 555px !important;
}

.exclusive-event-events {
  box-sizing: content-box;
  /* border-top: 1px solid #1891B4; */
  width: 100% !important;
  /* border-bottom: 1px solid #1891B4; */
}

.event-video-events {
  object-fit: fill !important;
  width: 100% !important;
  height: 438px !important;
  padding: 1px;
}

#event-slider .slick-bg {
  height: auto !important;
}

img.d-img1 {
  height: auto !important;
  width: 230px !important;
}

.d-img-brand {
  height: auto !important;
  width: 216px !important;
}

.swiper-slide {
  /* width: 100% !important; */
  height: auto !important;
}

/* .s-margin {
    border-top: 1px solid #2DD0FF;
    margin-top: 0 !important;
    border-bottom: 1px solid #2DD0FF;
} */

.s-margin {
  margin-top: 33px !important;
}

section#space-series {
  margin-top: 0px !important;
}

section#iq-brand {
  margin-top: 36px !important;
}

#iq-trending {
  margin-top: 0px !important;
}

section#iq-tvthrillers {
  border-bottom: 0 !important;
  margin-top: 36px !important;
  border-top: 0 !important;
}

/* #main-content {
    padding-bottom: 64px !important;
} */

#parallex {
  margin-top: 0px;
}

.veuit-content p {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #bfbfbf;
  padding-top: 12px;
  margin-bottom: 29px !important;
}

.veuit-content h4 {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 55px;
  font-weight: 700;
  line-height: 59px;
  letter-spacing: 0em;
  text-align: center;
}

.veuit-technologies {
  padding-bottom: 214px;
  padding-top: 11px;
  margin: 0 auto;
}

.tech-border {
  padding: 10px !important;
}

#tech-section {
  width: 1055px;
  margin: 0 auto;
}

.tech-video {
  height: 285px;
  border-radius: 30px;
  width: 508px !important;
}

#series-rating-mob {
  display: none;
}

#series-rating {
  display: block;
}

.series-btn {
  margin-top: 40px !important;
}

.series-btn1 {
  margin-top: 40px !important;
}

#slider-ratting {
  margin-right: 16px !important;
}

div#feature-bgimg {
  height: 553px !important;
}

div#prevBrand3 {
  top: 56.8%;
}

div#nextBrand3 {
  top: 56.8%;
}

div#nextEvent {
  top: 43%;
}

div#prevEvent {
  top: 43%;
}

.events-title {
  padding-bottom: 12px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px !important;
  line-height: 24px !important;
  letter-spacing: 1px;
  color: #ffffff;
}

.sign-in-page {
  /* background-position-x: 50% !important; */
  background-repeat: no-repeat !important;
  height: 100vh;
  position: relative;
  background: url(../images/desktop.png);
  background-size: cover !important;
}

#set-sign-in {
  height: 682px;
  width: 482px;
  background: linear-gradient(0deg, #000000 0%, #181818 100%);
  box-shadow: 0px -8px 50px rgba(45, 208, 255, 0.3);
  border-radius: 35px;
}

#set-sign-in1 {
  /* background: linear-gradient(180deg, #2DD0FF 0%, #1891B4 100%);
    padding: 1px;
    border-radius: 35px; */
  border-radius: 35px;
  border: 2px solid #2dd0ff;
  background: #000;
}

.tx-uppercase {
  text-transform: uppercase;
}

.login-acc {
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  padding-top: 23px;
  margin: 0 !important;
  padding-bottom: 15px !important;
}

.login-acc span {
  /* font-weight: 700; */
  color: #2dd0ff !important;
  margin-left: 5px;
  font-family: "Poppins", sans-serif;
}

#set-input-width {
  width: 356px;
  margin: 0 auto;
  padding-top: 40px;
}

#set-input-width input::placeholder {
  color: #939393 !important;
}

#set-input-width .first-name {
  padding-right: 10px !important;
}

#set-input-width .second-name {
  padding-left: 10px !important;
}

#set-input-width .form-group {
  margin-bottom: 16px !important;
}

.sign-in-input {
  height: 40px !important;
  border-radius: 23px !important;
  border: 0.5px solid #2dd0ff !important;
  font-family: "Inter-Regular" !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff !important;
  background-color: #252525 !important;
}

.sign-in-input-pwd {
  height: 40px !important;
  border-radius: 23px !important;
  border: 0.5px solid #2dd0ff !important;
  font-family: "Inter-Regular" !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff !important;
  background-color: #252525 !important;
  border-right: none !important;
  border-bottom-right-radius: unset !important;
  border-top-right-radius: 0px !important;
}

.sign-pwd-i-icon {
  border-bottom-right-radius: 23px !important;
  border-top-right-radius: 23px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border: 0.5px solid #2dd0ff !important;
  border-left: none !important;
  background-color: #252525 !important;
}

.sign-in-forgot-pwd {
  display: flex;
  width: 342px;
  margin: 0 auto;
  justify-content: center;
  padding-bottom: 14px;
  padding-top: 8px;
}

.set-veuit-signin {
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #2dd0ff;
  padding-bottom: 10px;
}

#set-veuit-signin {
  color: #ffffff !important;
}

#sign-inbtn.disabled {
  border: 1px solid #616161;
  background: linear-gradient(180deg, #101010 0%, #000000 100%),
    linear-gradient(0deg, #616161, #616161);
  height: 40px;
  width: 356px;
  /* background: linear-gradient(180deg, rgba(45, 208, 255, 0.5) 0%, rgba(24, 145, 180, 0.5) 100%);
    border: 1px solid #2DD0FF;
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.5); */
  border-radius: 18px !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px !important;
  letter-spacing: 0em !important;
  text-align: center;
  color: #ffffff;
  margin-bottom: 24px !important;
  text-transform: unset;
  opacity: 1 !important;
}

#sign-inbtn {
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 1px solid #2dd0ff;
  height: 40px;
  width: 356px;
  border-radius: 18px !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px !important;
  letter-spacing: 0em !important;
  text-align: center;
  color: #ffffff;
  margin-bottom: 24px !important;
  text-transform: unset;
}

.form-group {
  margin-bottom: 1.5rem !important;
}

.sign-in-logo {
  text-align: center;
  margin-top: 40px;
}

.sign-in-logo1 {
  text-align: center;
  margin-top: 40px;
}

.d-container2 {
  width: 479px;
}

.set-sign-up {
  padding-top: 8px;
  padding-bottom: 16px;
}

#sign-up {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.d-eye-off {
  display: block;
  display: inline-block;
  background: url(../images/Vector.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 16px;
  width: 20px;
}

.d-eye-on {
  display: block;
  display: inline-block;
  background: url(../images/Vector-blue.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 16px;
  width: 20px;
}

.sign-vector-img {
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 5px;
}

.set-sign-height {
  height: 422px !important;
}

.forgot-pwd-height {
  height: 454px !important;
}

#sign-group {
  margin-bottom: 9px !important;
}

.sign-up-policy p {
  font-family: "Inter-Regular" !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #bdbdbd;
  margin: 0 !important;
  padding-bottom: 41px;
}

.terms-use {
  color: #2dd0ff;
  font-family: "Inter-Regular" !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.sign-up-policy {
  width: 276px;
  margin: 0 auto;
}

.sign-in-logo img {
  width: 215px;
  height: auto !important;
}

.sign-in-logo1 img {
  width: 215px;
  height: auto !important;
}

.forgot-pwd {
  margin: 0 !important;
  padding-bottom: 0px;
  padding-top: 24px;
  font-family: "Inter-Regular" !important;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.text-body-pwd {
  padding-top: 8px;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 auto !important;
  width: 358px;
  letter-spacing: 0em;
  text-align: center;
  padding-bottom: 24px;
  color: #ffffff;
}

#forgot-inbtn {
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 1px solid #2dd0ff;
  height: 40px;
  width: 356px;
  border-radius: 18px !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px !important;
  letter-spacing: 0em !important;
  text-align: center;
  color: #ffffff;
  margin-bottom: 24px !important;
  text-transform: unset;
}

#forgot-inbtn.disabled {
  border: 1px solid #616161;
  background: linear-gradient(180deg, #101010 0%, #000000 100%),
    linear-gradient(0deg, #616161, #616161);
  height: 40px;
  width: 356px;
  border-radius: 18px !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px !important;
  letter-spacing: 0em !important;
  text-align: center;
  color: #ffffff;
  margin-bottom: 24px !important;
  text-transform: unset;
  opacity: 1 !important;
}

.forgot-input {
  height: 40px !important;
  border-radius: 23px !important;
  border: 0.5px solid #2dd0ff !important;
  font-family: "Inter-Regular" !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px !important;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff !important;
  background-color: #252525 !important;
}

.back-login {
  text-align: center;
  padding-bottom: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #2dd0ff;
  font-family: "Inter-Regular" !important;
}

.block-social-info {
  margin-top: 10px;
}

.trending-button {
  display: none !important;
}

.tranding-block2 {
  height: 552px;
  background-size: cover !important;
}

.veuit-channel-btn {
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 2px solid #2dd0ff;
  color: #ffffff !important;
  transition: color 0.3s ease;
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);
  position: relative;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 1px;
  padding: 0 !important;
  width: 179px;
  height: 36px;
}

.veuit-channel-signin {
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 2px solid #2dd0ff;
  color: #ffffff !important;
  transition: color 0.3s ease;
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);
  position: relative;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  letter-spacing: 1px;
  width: 110px;
  height: 36px;
  padding: 0 !important;
}

.set-view-all-text {
  align-items: baseline !important;
  margin-bottom: 12px !important;
}

.badge-secondary {
  background: linear-gradient(180deg, #181818 0%, #000000 100%) !important;
  color: #fff;
  height: 23px;
  width: auto;
  border-radius: 5px !important;
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  border: 1px solid #000000 !important;
  line-height: 13px !important;
}

.d-season1-series {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 100px;
  line-height: 40px;
  letter-spacing: 1px;
  color: #ffffff;
  text-align: center;
}

.block-description-featured-channel-video {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 10px;
  z-index: 999;
  text-align: left;
  color: #f2f2f2 !important;
}

.featured-channel-video {
  object-fit: cover !important;
  width: 100% !important;
  height: 555px !important;
}

.d-set-featured-video,
.d-set-featured-channel-photo {
  padding-top: 125px;
  text-align: center;
}

.d-image-1,
.d-image-2 {
  width: 70%;
  padding-bottom: 20px;
}

.d-parallax-window {
  padding: 0 !important;
}

.world-edu-event {
  height: 578px !important;
  width: 100% !important;
}

.world-edu-video {
  object-fit: cover !important;
  width: 100% !important;
  height: 578px !important;
}

.education-block-img {
  object-fit: fill !important;
  width: 100% !important;
  height: 500px !important;
  /* border-bottom: 1px solid #1891B4;
    border-top: 1px solid #1891B4; */

  background-size: cover;
  position: relative;
  z-index: 5;
}

.inner-education-block-img {
  position: absolute;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(20px);
}

.inner-education-block-vid {
  object-fit: cover;
  z-index: -2;
}

#series-rating {
  display: block;
  width: 220px;
}

.dynamic-star-container {
  height: 40px;
}

.d-ratings-and-season {
  display: flex;
  padding-bottom: 10px;
  height: 50px;
}

.network-timeline {
  width: 829px;
  margin: 0 auto;
  margin-bottom: 62px !important;
}

.set-timeline {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.timeline-circle {
  height: 32px;
  width: 32px;
  border-radius: 25px;
  background: #fff;
  z-index: 100;
  color: #616161;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 23px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
}

.timeline-circle-line {
  width: 100%;
  height: 2px;
  position: absolute;
  color: #d9d9d9;
  border: none !important;
}

.timeline-text {
  position: absolute;
  top: 31px;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 23px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  color: #ffffff;
  margin: 0 !important;
}

/* .channel-registration {
    height: 662px;
    width: 1005px;
    margin: 0 auto;
    border-radius: 15px;
    background: #181818;
    border: 1px solid #1891B4;
    padding-left: 74px;
    padding-right: 64px;
} */

.channel-set-center {
  text-align: center;
}

.channel-logos {
  padding-top: 17px;
}

.channel-card-title {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 12px;
  color: #ffffff;
}

.channel-upgrade {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #989898;
  padding-top: 8px;
  margin-bottom: 38px !important;
}

.choose-domain {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin-bottom: 18px;
}

.set-domain {
  width: 508px;
  margin-bottom: 30px;
}

.channel-label {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.set-domain-name {
  height: 46px;
  width: 867px;
}

.set-domain-name1 {
  background: #252525 !important;
  border: none !important;
  border-bottom-left-radius: 5px !important;
  border-top-left-radius: 5px !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px !important;
  letter-spacing: 0em;
  height: 46px !important;
}

.set-domain-name1::placeholder {
  color: #797979 !important;
}

.set-domain-name2 {
  background: #252525 !important;
  border: none !important;
  border-bottom-right-radius: 5px !important;
  border-top-right-radius: 5px !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px !important;
  letter-spacing: 0em;
  height: 46px !important;
  color: #797979 !important;
}

.set-domain-name2::placeholder {
  color: #797979 !important;
}

.set-channel-img {
  background-repeat: no-repeat !important;
  height: 100vh;
  position: relative;
  background: url(../images/desktop.png);
  background-size: cover !important;
}

.channel-basic {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin-bottom: 4px;
}

.basic-channel-field {
  height: 46px !important;
  width: 198px !important;
  border-radius: 5px !important;
  background: #252525 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff !important;
  border: none !important;
}

.basic-channel-field::placeholder {
  color: #797979 !important;
}

.basic-channel-field1 {
  height: 46px !important;
  width: 423px !important;
  border-radius: 5px !important;
  background: #252525 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #fff !important;
  border: none !important;
}

.basic-channel-field1::placeholder {
  color: #797979 !important;
}

.select-channel {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  padding-bottom: 30px;
}

.select-channel-type {
  height: 46px !important;
  width: 420px !important;
  border-radius: 5px !important;
  background: #252525 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px !important;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff !important;
}

.select-channel-subtype {
  height: 46px !important;
  width: 423px !important;
  border-radius: 5px !important;
  background: #252525 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px !important;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff !important;
}

.d-bgcolor-round {
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
}

.d-text-color-round {
  color: #2dd0ff;
}

.footer-channel-text {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #616161;
  padding-top: 114px;
}

.footer-channel-text span {
  color: #2dd0ff;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

.choose-plan {
  width: 278px;
}

.show-movie-details {
  padding-top: 90px;
}

.show-movie-img,
.show-movie-video {
  position: relative;
}

.movie-bgimg {
  width: 100%;
  height: 604px;
  border-top: 1px solid #2dd0ff;
  border-bottom: 1px solid #2dd0ff;
}

.view-movie-details {
  position: absolute;
  left: 90px;
  top: 130px;
}

.movie-name {
  font-family: OswaldBold;
  font-size: 55px;
  font-weight: 700;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.ratings-img {
  height: 16px;
  width: 16px;
  margin-right: 2px;
}

.show-star-content {
  display: flex;
  align-items: center;
}

.show-ratings {
  margin-right: 5px;
}

.show-rating-details {
  display: flex;
  align-items: center;
}

.show-rating-details p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #2dd0ff;
  padding-left: 3px;
  margin: 0 !important;
}

.show-rating-details h3 {
  font-family: "Poppins", sans-serif;
  font-size: 17px !important;
  font-weight: 400 !important;
  line-height: 32px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  padding-left: 14px;
}

.show-rating-details h4 {
  font-family: "Poppins", sans-serif;
  font-size: 17px !important;
  font-weight: 400 !important;
  line-height: 32px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  padding-left: 17px;
}

.show-tv-btn {
  height: 24px;
  min-width: 71px;
  border-radius: 0px !important;
  border: 1px solid #bdbdbd;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #bdbdbd;
  margin-left: 18px;
  padding: 0px 1px !important;
  background: none !important;
}

.movie-description p {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  width: 549px;
  padding-top: 9px;
}

.movies-reviews {
  padding-top: 13px;
  width: 624px;
}

.show-movie-reviews {
  display: flex;
}

.movie-starrings p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  margin: 0 !important;
  width: 415px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.movie-starring p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  margin: 0 !important;
}

.movie-starring-content p {
  width: 415px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #2dd0ff;
  margin: 0 !important;
}

.movie-starring-content {
  padding-left: 92px;
}

.movie-starrings.movie-starring1 {
  padding-left: 98px;
}

.movie-starrings.movie-starring2 {
  padding-left: 87px;
}

.movie-starrings.movie-starring3 {
  padding-left: 16px;
}

.show-like-details {
  display: flex;
  padding-top: 35px;
  align-items: center;
}

.show-like-img {
  height: 24px;
  width: 24px;
}

.show-like-details p {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  padding-left: 8px;
}

.show-episodes-btn {
  padding-top: 20px;
}

.show-episode {
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 2px solid #2dd0ff;
  box-shadow: 0px 4px 10px 2px #00000080;
  height: 45px;
  width: 214px;
  border-radius: 30px !important;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  padding: 0 !important;
  box-shadow: 0px 4px 10px 2px #00000080;
}

.show-episode span {
  font-weight: 700;
}

.show-rent {
  height: 45px;
  width: 130px;
  border-radius: 30px !important;
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 2px solid #2dd0ff;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  padding: 0 !important;
  color: #ffffff;
  margin-left: 23px;
  box-shadow: 0px 4px 10px 2px #00000080;
}

.show-rent span {
  font-weight: 700;
}

.show-like-btn {
  height: 44px;
  width: 44px;
  border-radius: 10px !important;
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 2px solid #2dd0ff;
  box-shadow: 0px 4px 10px 2px #00000080;
  padding: 0 !important;
  margin-left: 23px;
}

/* .show-like-img1 {
    height: 26px;
    width: 28px;
    margin-top: 8px;
} */

.show-watchlist-icon {
  height: 44px;
  width: 44px;
  border-radius: 10px !important;
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 2px solid #2dd0ff;
  box-shadow: 0px 4px 10px 2px #00000080;
  padding: 0 !important;
  margin-left: 11px;
}

.show-dinner-btn {
  margin-left: 11px;
  padding: 0 !important;
  height: 45px;
  width: 163px;
  border-radius: 30px !important;
  background: linear-gradient(180deg,
      rgba(148, 255, 132, 0.35) 0%,
      rgba(71, 199, 69, 0.35) 100%);
  border: 2px solid #66ca6a;
  box-shadow: 0px 4px 10px 2px #00000080;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.show-terms p {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  padding-top: 23px;
}

.show-terms span {
  color: #2dd0ff;
  font-size: 15px !important;
}

.show-movie-tabs {
  padding: 40px 90px;
}

.movie-tab-item {
  padding: 0 !important;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd !important;
  padding-right: 50px !important;
}

.movie-tab-item.active {
  color: #2dd0ff !important;
  background: none !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link .movie-tab-item {
  background: none !important;
}

.show-movie-pills {
  padding-bottom: 7px;
  border-bottom: 1px solid #616161;
  justify-content: space-between;
}

.show-tabs-icon {
  height: 39px;
  width: 43px;
  border-radius: 10px !important;
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 2px solid #2dd0ff;
  box-shadow: 0px 4px 10px 2px #00000080;
  padding: 0 !important;
}

.show-tabs-img {
  width: 23px;
  height: 26px;
}

.show-nav-items {
  display: flex;
}

.show-home-tabs {
  padding-top: 40px;
}

.show-detailtab-img {
  height: 297px;
  width: 200px;
  border-radius: 10px;
  border: 2px solid #1891b4;
}

.show-tab-details {
  display: flex;
}

.detail-tab-content {
  padding-left: 41px;
  flex: 1;
}

.detail-actions {
  display: flex;
  align-items: center;
}

.detail-actions p {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.detail-action-btn {
  padding: 0px 1px !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #bdbdbd;
  height: 24px;
  min-width: 71px;
  border-radius: 0px !important;
  border: 1px solid #bdbdbd;
  background: none !important;
  margin-left: 10px;
}

.details-action-content p {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  padding-top: 7px;
  padding-right: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.details-of-movie {
  display: flex;
  align-items: center;
}

.details-of-movie h4 {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  width: 116px;
}

.details-of-movie p {
  width: 472px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #2dd0ff;
  padding-left: 51px;
}

.details-of-movie span {
  color: #bdbdbd;
}

.details-show-movie {
  padding-top: 15px;
}

.details-subtitle p {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: right;
  color: #bdbdbd;
}

.details-subtitle h4 {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: right;
  color: #2dd0ff;
}

.details-subtitle {
  padding-top: 40px;
}

.details-contents-button {
  display: flex;
  padding-top: 33px;
}

.crime-btn {
  padding: 0px 1px !important;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  background: none !important;
  border: 2px solid#1891B4;
  height: 26px;
  min-width: 90px;
  color: #ffffff;
}

.drama-btn {
  padding: 0px 1px !important;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  height: 26px;
  min-width: 90px;
  border-radius: 13px !important;
  background: none !important;
  border: 2px solid#1891B4;
  margin-left: 20px;
  color: #ffffff;
}

.comedy-btn {
  padding: 0px 1px !important;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  height: 26px;
  min-width: 111px;
  border-radius: 13px !important;
  background: none !important;
  border: 2px solid#1891B4;
  margin-left: 20px;
}

.top-cast {
  padding-bottom: 37px;
  border-bottom: 1px solid #616161;
  padding-top: 37px;
}

.top-cast p {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  margin: 0 !important;
  padding-bottom: 10px;
  border-bottom: 1px solid #616161;
}

.show-profile-img {
  height: 100px;
  width: 100px;
  border-radius: 200px;
  border: 2px solid#1891B4;
}

.show-img-boxes h4 {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  padding-top: 9px;
}

#show-img-boxes h4 {
  text-align: center;
}

.top-cast .show-img-boxes p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  padding-bottom: 0;
  border-bottom: none;
  padding: 0 !important;
}

.show-cast-agency {
  text-align: center;
  padding-top: 29px;
}

.show-casting {
  height: 45px;
  min-width: 226px;
  padding: 0 1px !important;
  border-radius: 22px !important;
  background: linear-gradient(180deg,
      rgba(255, 228, 0, 0.5) 0%,
      rgba(254, 122, 0, 0.5) 100%);
  border: 2px solid #e29e19;
  box-shadow: 0px 4px 10px 2px #00000040;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.show-img-boxes {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: fit-content;
}

.m-set-prev {
  background-color: unset !important;
  left: -53px !important;
  top: 22% !important;
  height: auto !important;
}

.m-set-next {
  background-color: unset !important;
  right: -53px !important;
  top: 22% !important;
  height: auto !important;
}

.movie-veuit-img {
  height: 288px;
  width: 194px;
  border-radius: 10px;
}

.sm-set-prev {
  background-color: unset !important;
  left: -53px !important;
  top: 38% !important;
  height: auto !important;
}

.sm-set-next {
  background-color: unset !important;
  right: -53px !important;
  top: 38% !important;
  height: auto !important;
}

.movie-extra {
  padding-top: 40px;
  border-bottom: 1px solid #616161;
  display: flex;
  padding-bottom: 40px;
}

.show-extratab-img {
  height: 130px;
  width: 200px;
  border-radius: 20px;
  border: 2px solid #1891b4;
}

.show-movie-tab {
  padding-left: 41px;
  flex: 1 1;
}

.show-movie-tab h4 {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.show-movie-tab p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  padding-top: 3px;
  margin-bottom: 0px;
  width: 46%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.show-subtitle p {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: right;
  color: #bdbdbd;
}

.show-subtitle h4 {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: right;
  color: #2dd0ff;
}

.extra-photo-sec {
  display: flex;
  align-items: center;
}

.extra-photo {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  padding-bottom: 10px;
  margin: 0 !important;
  border-bottom: 1px solid #616161;
  padding-top: 40px;
}

.show-count-data {
  padding-bottom: 18px;
}

.ratings-review {
  display: flex;
}

.rating-progressbar {
  display: flex;
  padding-top: 4px;
  align-items: center;
}

.rating-progressbar p {
  padding-right: 6px;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  margin: 0 !important;
  width: 55px;
}

.rating-progressbar span {
  width: 49px;
  padding-left: 16px;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: right;
  color: #2dd0ff;
}

.show-count-data p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  margin: 0 !important;
  padding-top: 7px;
}

.set-progress-bg {
  height: 10px !important;
  width: 220px !important;
  border-radius: 4px !important;
  background: linear-gradient(270deg, #2dd0ff 0%, #29b4dc 100%) !important;
}

.write-review-btn {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
  height: 46px;
  width: 326px;
  border-radius: 23px !important;
  padding: 0 !important;
  margin-top: 32px;
}

.review-description {
  padding-left: 57px;
  width: 100%;
}

.filter-review {
  display: flex;
}

.filter-review p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
  color: #bdbdbd;
  text-transform: initial;
  padding-right: 18px;
}

.filter-review h6 {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
  color: #bdbdbd;
  text-transform: initial;
}

.top-reviews {
  /* margin-left: 18px; */
  align-items: center;
  display: flex;
}

.top-comment {
  /* margin-left: 18px; */
  align-items: center;
  display: flex;
}

.sorted-top-review p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
  color: #bdbdbd;
  padding-top: 5px;
}

.review-tab-description {
  background: #181818;
  border-radius: 20px;
  margin-top: 31px;
  padding: 23px;
}

.view-tab {
  display: flex;
  align-items: center;
}

.view-tab1 {
  display: flex;
  align-items: center;
}

.review-tab-img {
  height: 36px;
  width: 36px;
  border-radius: 50px;
  border: 1px solid#1891B4;
}

div#movie-detail-ratting {
  margin: 0 !important;
}

#movie-rating .dynamic-star-container {
  height: auto !important;
}

.photos-extra-img {
  height: 130px;
  width: 200px;
  border-radius: 20px;
  border: 2px solid #1891b4;
}

.p-set-prev {
  background-color: unset !important;
  left: -53px !important;
  top: 31% !important;
  height: auto !important;
}

.p-set-next {
  background-color: unset !important;
  right: -16% !important;
  top: 31% !important;
  height: auto !important;
}

.movies-content {
  padding-top: 40px;
}

.movies-content p {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  padding-bottom: 10px;
  margin: 0 !important;
  border-bottom: 1px solid #616161;
}

.view-tab p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  margin: 0 !important;
  padding-left: 15px;
}

.view-tab span {
  color: #2dd0ff;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  padding-left: 14px;
}

.view-tab1 p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  margin: 0 !important;
  padding-left: 15px;
}

.view-tab1 span {
  color: #2dd0ff;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  padding-left: 14px;
}

.verified-ratings {
  display: flex;
  padding-top: 11px;
}

.verified-ratings p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  padding-left: 15px;
  margin: 0 !important;
}

.review-purchase h5 {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #2dd0ff;
  padding-top: 14px;
}

.review-purchase p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
  color: #bdbdbd;
  padding-top: 13px;
}

.review-purchase h6 {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #616161;
  padding-top: 8px;
}

.review-rating-calc {
  margin-top: 21px;
  display: flex;
  align-items: center;
}

.review-rating-calc p {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: #bdbdbd;
  margin: 0 !important;
  padding-left: 18px;
}

.rating-percentage {
  text-align: right;
}

.review-report {
  display: flex;
  padding-top: 22px;
  align-items: center;
}

.review-report p {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #2dd0ff;
  margin: 0 !important;
  padding-left: 31px;
}

img.review-blue-img {
  height: 18px;
  width: 20px;
}

.verified-ratings>div>span {
  display: none;
}

.review-avg-rating {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin: 0 !important;
  padding-left: 20px;
}

.show-ratings>div>span {
  display: none;
}

#pills-tab a {
  cursor: pointer;
}

.remaining-photo p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: right;
  color: #bdbdbd;
  margin: 0 !important;
}

.remaining-photo {
  width: 10%;
}

.main-content.extra-tabs-set {
  width: 90%;
}

#movie-rating {
  height: 16px;
  width: 100px;
}

.show-time-details-mobile {
  display: none;
}

.show-minute-mobile,
.show-date-mobile {
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  color: #616161;
  padding-right: 5px;
}

.show-subtitle-mobile {
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  color: #2dd0ff;
}

#details-subtitle-mob {
  display: none;
}

.details-share-mob {
  display: none;
}

.details-share-mob p {
  padding-right: 7px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin: 0 !important;
}

.share-mob-view {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 19px;
}

.show-share-img {
  width: 12px;
  height: 14px;
}

.details-contents-button-mob {
  display: none;
}

.show-mob-btn {
  display: none;
}

.show-comments {
  display: flex;
}

.filter-comment {
  display: flex;
}

.filter-comment h6 {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
  color: #bdbdbd;
  text-transform: initial;
}

.filter-comment p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
  color: #bdbdbd;
  text-transform: initial;
  padding-right: 18px;
}

.sorted-top-comment p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
  color: #bdbdbd;
  padding-top: 5px;
}

.comment-review-right {
  height: 1330px;
  width: 352px;
  border-radius: 20px;
  background: #181818;
  padding: 24px;
  padding-bottom: 3px !important;
}

.comment-people p {
  width: 181px;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #bdbdbd;
  margin: 0 auto;
}

.set-follow-info {
  display: flex;
  align-items: center;
  border-top: 1px solid #616161;
  padding-top: 10px;
  padding-bottom: 10px;
}

.set-follow-info h4 {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  padding-left: 14px;
}

.comments-img {
  height: 36px;
  width: 36px;
  border-radius: 50px;
  border: 1px solid #1891b4;
}

.set-follow-info p {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;
  color: #616161;
  margin: 0 !important;
  padding-left: 30px;
}

.comment-review-left {
  flex: 1;
  margin-right: 36px;
}

.comment-button-visit {
  height: 45px;
  min-width: 226px;
  border-radius: 22px !important;
  background: linear-gradient(180deg,
      rgba(255, 228, 0, 0.5) 0%,
      rgba(254, 122, 0, 0.5) 100%);
  border: 2px solid #e29e19;
  box-shadow: 0px 4px 10px 2px #00000040;
  padding: 0 !important;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.comments-show {
  display: flex;
  justify-content: space-between;
}

.comment-people {
  margin-bottom: 20px;
}

.create-comment p {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  margin: 0 !important;
}

.create-comment1 p {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  margin: 0 !important;
}

.create-comment1 {
  margin-top: 24px;
}

.create-comment {
  margin-top: 24px;
}

.set-comment-title {
  margin-top: 37px;
  background: linear-gradient(0deg, #181818, #181818) !important;
  border: 1px solid #1891b4 !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  letter-spacing: 0em;
  text-align: left;
  color: #797979;
  border-radius: 10px !important;
  height: 103px !important;
  padding-top: 6px !important;
  padding-left: 14px !important;
  resize: none;
}

.set-comment-title::placeholder {
  color: #797979 !important;
}

.comment-vector-img {
  position: absolute;
  height: 20px;
  width: 20px;
  bottom: 18px;
  right: 9px;
}

.comment-title {
  position: relative;
}

.comment-title1 {
  position: relative;
}

.show-comment-sec {
  margin-top: 36px;
  padding: 23px;
  background: #181818;
  border-radius: 20px;
}

.review-report1 {
  display: flex;
  align-items: center;
  padding-top: 26px;
}

.review-report1 .MuiCheckbox-colorSecondary.Mui-checked {
  color: #616161 !important;
}

.review-report1 label.MuiFormControlLabel-root {
  margin: 0 !important;
}

.review-report1 .MuiCheckbox-root {
  color: #616161 !important;
  padding: 0 !important;
  height: 15px !important;
  width: 18px !important;
  font-size: 1.356rem !important;
}

.review-report1 .MuiSvgIcon-root {
  /* height: 15px !important;
    width: 18px !important; */
  font-size: 1.356rem !important;
}

.review-like-img {
  height: 15px;
  width: 18px;
  color: #616161;
}

.review-report1 input[type="checkbox"] {
  width: 18px;
  margin-right: 0px !important;
  height: 15px;
  display: inline-block;
}

.review-comment-img {
  height: 15.75px;
  width: 17px;
  color: #616161;
  margin-left: 24px;
}

.review-report1 h6 {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  padding-left: 5px;
}

.review-report1 p {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #2dd0ff;
  margin: 0 !important;
  padding-left: 30px;
}

.review-share-img {
  margin-left: 24px;
  margin-bottom: 3px;
}

.comment-new-sec {
  padding-top: 29px;
}

#set-comment-border {
  padding-bottom: 23px;
  border-bottom: 1px solid #616161;
}

.comment-sec-new {
  padding-left: 53px;
  padding-top: 7px;
}

.comment-para {
  padding-left: 47px;
  margin-top: 17px;
}

.comment-para p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  margin: 0 !important;
  background-color: #272626;
  border-radius: 10px;
  padding-top: 11px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 14px;
  width: 800px;
}

.like-comment-icon {
  display: flex;
  align-items: center;
  padding-top: 13px;
}

#comment-para p {
  width: 325px;
}

.comment-reply-sec {
  display: flex;
  padding-top: 23px;
}

.set-reply-title {
  height: 47px !important;
  border-radius: 23px !important;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #797979;
  margin-left: 14px;
  border: 1px solid #1891b4;
  background: linear-gradient(0deg, #181818, #181818);
  padding-top: 9px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 9px;
  resize: none;
}

.set-reply-title::placeholder {
  color: #797979 !important;
}

.comment-scrolbar {
  height: 1204px;
  overflow-y: scroll;
}

.comment-scrolbar::-webkit-scrollbar {
  width: 15px;
  height: 25px;
}

.comment-scrolbar::-webkit-scrollbar-track {
  border: 1px solid #616161;
  background: linear-gradient(0deg, #000000, #000000);
}

.comment-scrolbar::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
  border: 1px solid #2dd0ff;
  border-radius: 4.5px;
  height: 25px;
  width: 11px;
}

.create-comment1 {
  display: none;
}

.comment-title1 {
  display: none;
}

.review-purchase1 p {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
  color: #bdbdbd;
  padding-top: 13px;
}

.review-purchase1 h6 {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #616161;
  padding-top: 8px;
}

.like-comment-icon .MuiCheckbox-colorSecondary.Mui-checked {
  color: #616161 !important;
}

.like-comment-icon label.MuiFormControlLabel-root {
  margin: 0 !important;
}

.like-comment-icon .MuiCheckbox-root {
  color: #616161 !important;
  padding: 0 !important;
  height: 15px !important;
  width: 18px !important;
  font-size: 1.356rem !important;
}

.like-comment-icon .MuiSvgIcon-root {
  font-size: 1.356rem !important;
}

.like-comment-icon input[type="checkbox"] {
  width: 18px;
  margin-right: 0px !important;
  height: 15px;
  display: inline-block;
}

.review-report .MuiCheckbox-colorSecondary.Mui-checked {
  color: #2dd0ff !important;
}

.review-report label.MuiFormControlLabel-root {
  margin: 0 !important;
}

.review-report .MuiCheckbox-root {
  color: #2dd0ff !important;
  padding: 0 !important;
  height: 18px !important;
  width: 20px !important;
  font-size: 1.35rem !important;
}

.review-report .MuiSvgIcon-root {
  font-size: 1.35rem !important;
}

.review-report input[type="checkbox"] {
  width: 20px;
  margin-right: 0px !important;
  height: 18px;
  display: inline-block;
}

.show-like-btn .MuiCheckbox-colorSecondary.Mui-checked {
  color: #ffffff !important;
}

.show-like-btn label.MuiFormControlLabel-root {
  margin: 0 !important;
}

.show-like-btn .MuiCheckbox-root {
  color: #ffffff !important;
  padding: 0 !important;
  height: 18px !important;
  width: 20px !important;
  font-size: 1.35rem !important;
}

.show-like-btn .MuiSvgIcon-root {
  font-size: 1.35rem !important;
}

.show-like-btn input[type="checkbox"] {
  width: 20px;
  margin-right: 0px !important;
  height: 18px;
  display: inline-block;
}

img.topreview-vector {
  margin-left: 9px;
  height: 7px;
  width: 13px;
}

.review-description .btn {
  background: none !important;
  padding: 0 !important;
  border: none !important;
  border-radius: unset !important;
}

.review-description .dropdown-toggle::after {
  display: none !important;
}

.filter-comment .btn {
  background: none !important;
  padding: 0 !important;
  border: none !important;
  border-radius: unset !important;
}

.filter-comment .dropdown-toggle::after {
  display: none !important;
}

.rating-vector-img {
  height: 11.375px;
  width: 20.001039505004883px;
}

.set-accordion-ratings .MuiButtonBase-root.MuiAccordionSummary-root.review-rating-calc {
  flex-direction: row-reverse;
  padding: 0 !important;
  min-height: 0 !important;
  background-color: unset !important;
  color: none !important;
  align-items: center;
  margin-top: 21px;
}

.MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.MuiIconButton-edgeEnd {
  height: 11px !important;
  width: 20px !important;
  border-radius: 0px;
  color: #616161 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.our-ratings .MuiAccordionSummary-content {
  margin: 0 !important;
}

.MuiPaper-root.MuiAccordion-root.set-accordion-ratings.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
  flex-direction: row-reverse;
  padding: 0 !important;
  min-height: 0 !important;
  background-color: unset !important;
  color: none !important;
}

.review-rating-calc1 p {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: #bdbdbd;
  margin: 0 !important;
}

.MuiAccordionDetails-root.review-rating-calc1 {
  padding: 10px !important;
  background-color: #181818;
  margin-top: 7px;
}

.our-ratings {
  width: 294px !important;
}

.set-accordion-ratings .MuiSvgIcon-root {
  font-size: 2.5rem !important;
  padding: 0 !important;
}

.show-star-content h5 {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  padding-right: 12px;
}

.veuit-series p {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 31.5px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
  margin: 0 !important;
}

.movie-starring-content span {
  color: #bdbdbd;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  font-family: "Poppins", sans-serif;
}

#view-series-details {
  top: 98px;
}

#series-season .btn {
  display: flex;
  background: none !important;
  padding: 0 !important;
  border: none !important;
  border-radius: unset !important;
  align-items: center;
}

/* #series-season .dropdown-toggle::after {
    display: none !important;
} */

.movie-tab-item span {
  font-weight: 400;
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 30px;
}

.season-vector {
  height: 11px;
  width: 20px;
}

#pills-season-tab {
  padding-right: 15px !important;
}

#series-season {
  padding-right: 50px;
}

.series-tabs-icon {
  height: 39px;
  width: 43px;
  border-radius: 10px !important;
  background: linear-gradient(180deg,
      rgba(255, 227, 1, 0.5) 0%,
      rgba(255, 123, 1, 0.5) 100%);
  border: 2px solid #e29e19;
  box-shadow: 0px 4px 10px 2px #00000080;
  padding: 0 !important;
}

.show-season-description {
  display: none;
}

.mobile-season {
  border-bottom: 0;
  padding-bottom: 0;
}

.details-moive-btn {
  padding: 0px 10px !important;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  height: 26px;
  min-width: 111px;
  border-radius: 13px !important;
  background: none !important;
  border: 2px solid#1891B4;
  margin-right: 20px;
}

video.movie-bgvideo {
  width: 100%;
  height: 604px;
  border-top: 1px solid #2dd0ff;
  border-bottom: 1px solid #2dd0ff;
  object-fit: cover;
}

p.slider-text.d-slide-txt {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 !important;
  color: #ffffff !important;
  padding-bottom: 16px;
}

h1.slider-text.text-uppercase {
  font-family: "Roboto", sans-serif;
  color: #ffffff !important;
  font-size: 48px;
  font-weight: 900;
  line-height: 40px;
  letter-spacing: 0.01em;
  text-align: center;
  margin: 0 !important;
}

/* #b-container .set-col-brand {
    padding: 0 !important;
} */

.main-content.set-main-brand {
  padding-top: 21px;
  padding-bottom: 0px;
}

img.count-series-img {
  width: 352px;
  height: auto;
  margin-bottom: 24px;
}

.exclusive-event-series {
  box-sizing: content-box;
  border-top: 1px solid #1891b4;
  width: 100% !important;
  border-bottom: 1px solid #1891b4;
}

.event-video-series {
  object-fit: fill !important;
  width: 100% !important;
  height: 438px !important;
  padding: 1px;
}

#d-series-network {
  margin-top: 36px;
  display: block !important;
}

.WEI-txt {
  text-transform: uppercase;
  padding-top: 24px;
  width: 404px !important;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 auto !important;
  color: #ffffff !important;
  /* box-shadow: 0px 4px 4px 0px #00000040; */
}

.set-veuit-footer {
  width: 100%;
  display: flex;
  align-items: center !important;
}

#set-list-footer {
  margin-left: 113px;
}

.footer-list-text {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #2dd0ff !important;
  cursor: pointer;
}

footer.footer-one .widget .textwidget p {
  color: #cccccc;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 0;
  padding-top: 36px;
}

img.img-fluid.logo {
  max-width: 143px !important;
  width: 143px !important;
}

video.d-video-banner {
  height: 600px !important;
  width: 100vw !important;
}

#footer-container {
  width: 83% !important;
  margin: 0 auto;
  padding: 0 !important;
}

.info-share-mob {
  display: none;
}

.musicartists-img {
  height: 268px;
  width: 186.3333282470703px;
  border-radius: 15px;
  border: 1px solid #1891b4;
  object-fit: cover;
}

.world-event-stock {
  margin: 0 auto;
  width: 1118px;
  display: flex;
  padding-top: 35px;
  padding-bottom: 35px;
  justify-content: center;
}

.world-stock-img {
  height: 196px;
  width: 352px;
  border-radius: 20px;
  box-shadow: 0px 0px 15px 0px #00000080;
}

.secure-connect {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.05em;
  text-align: center;
  margin: 0 !important;
  color: #ffffff !important;
  padding-top: 12px;
}

.set-section-stock {
  padding-right: 32px;
}

.set-section-stock1 {
  padding-right: 32px;
}

.modal-fullscreen {
  width: 95vw !important;
  max-width: none !important;
  height: 100% !important;
  margin: 0px auto !important;
}

.modal-fullscreen .close {
  opacity: 1 !important;
}

.modal-fullscreen span {
  font-size: 26px !important;
  font-weight: 900 !important;
  line-height: 39px !important;
  opacity: 1 !important;
}

.modal-fullscreen button.close {
  padding: 8px 21px !important;
}

.modal-header {
  border-bottom: none !important;
  padding-bottom: 0 !important;
  padding-top: 10px !important;
}

#series-bg-img .d-btn {
  margin-top: 16px !important;
}

.podcast-img {
  height: 156px;
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  object-fit: cover;
  /* border: 1px solid #616161;
    border-bottom: 0; */
}

.maintenance-phase {
  width: 100%;
  height: 76px;
  /* border: 1px solid #616161;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px; */
  padding: 16px 12px;
  border-top: 0;
}

p.set-maintenance {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin: 0 !important;
  height: 42px;
}

/* .world-event-stock-border {
    border-bottom: 1px solid #1891B4;
} */

.set-profile-info h6 {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  font-family: "Poppins", sans-serif;
}

div#series-bg-img {
  /* border-bottom: 1px solid #1891B4;
    border-top: 1px solid #1891B4; */
  height: 440px;
  background-size: cover !important;
}

#set-privacy-policy {
  width: 1064px;
  border-radius: 15px;
  box-shadow: 0px 0px 25px 10px #00000080;
  margin: 0 auto;
}

.privacy-text-sec {
  height: 100px;
  background: linear-gradient(180deg, rgba(45, 208, 255, 0.5) 0%, #000000 116%);
  border-radius: 15px 15px 0px 0px;
  border: 2px solid #2dd0ff;
}

.privacy-text-sec p {
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 95px;
  letter-spacing: 0em;
  text-align: center;
  box-shadow: 0px 1px 4px 0px #000000;
  margin: 0 !important;
  /* text-shadow: 0px 1px 4px #000000; */
  background: -webkit-linear-gradient(180deg, #ffffff 0%, #c5c5c5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  border-radius: 13px 13px 0px 0px;
}

.iq-privacy-policy1 {
  border-bottom: 2px solid #616161;
  border-left: 2px solid #616161;
  border-right: 2px solid #616161;
  background: #181818;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.contact-privacy p {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin: 0 !important;
}

.contact-privacy {
  margin-left: 24px;
  margin-right: 24px;
  padding-top: 32px;
  padding-bottom: 31px !important;
  border-bottom: 1px solid #616161;
}

.add-info {
  color: #2dd0ff;
}

.set-bottom-privacy {
  padding-top: 10px;
  padding-left: 19px;
  padding-right: 29px;
}

.privacy-text-set p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 28px !important;
  color: #ffffff !important;
}

.privacy-text-set span {
  font-weight: 700 !important;
}

.contacting-us {
  font-family: "BarlowSemiCondensed-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  color: #ffffff;
  margin-top: 30px !important;
}

.list-contact-set {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 28px !important;
  color: #ffffff !important;
  margin-left: -16px !important;
  list-style-type: disc;
}

.d-btn-view-now {
  margin-left: 20px;
}

.show-podcast-details {
  padding-top: 90px;
}

.show-podcast-tabs {
  padding: 40px 90px;
}

.podcast-sec {
  display: flex;
  justify-content: space-between;
}

.podcast-sec-right1 {
  height: 603px;
  width: 603px;
  border-radius: 12px;
}

.podcast-sec-right {
  text-align: right;
  position: relative;
}

.podcast-sec-left {
  width: 50%;
}

.podcast-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 95px;
  line-height: 120px;
  color: #ffffff;
  width: 87%;
}

.podcast-para {
  font-family: "Poppins", sans-serif;
  margin: 0 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  width: 83%;
  padding-top: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.set-btns-now {
  display: flex;
  align-items: center;
  padding-top: 71px;
}

.show-Listen-btn {
  width: 321px;
  height: 59.09px;
  background: linear-gradient(180deg, #0065d7 0%, #002679 100%);
  border-radius: 4px !important;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24.6923px;
  line-height: 37px;
  letter-spacing: 0.08em;
  color: #ffffff;
  padding: 0 !important;
  border: none !important;
  z-index: 9;
}

.show-Subscribe-btn {
  font-family: "Poppins", sans-serif;
  border: 2px solid #002679;
  width: 321px;
  height: 59.09px;
  border-radius: 4px !important;
  font-style: normal;
  font-weight: 700;
  font-size: 24.6923px;
  line-height: 37px;
  letter-spacing: 0.08em;
  color: #ffffff;
  background: transparent;
  padding: 0 !important;
  margin-left: 30px;
  z-index: 9;
}

.podcast-footer {
  background: linear-gradient(180deg, #141313 0%, #0f0e0e 53.87%, #141313 100%);
  width: 100%;
  position: fixed;
  margin-top: 73px;
  padding: 28px 90px;
  bottom: 0;
  z-index: 999;
}

.podcast-footer-list {
  display: flex;
  align-items: center;
}

.podcast-footer-list1 img {
  width: 100px;
  border-radius: 5px;
}

.podcast-center {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  border-bottom: 1px solid #616161;
  padding-bottom: 40px;
}

.podcast-center-left1 {
  width: 231px;
  height: 153px;
  border-radius: 7px;
  max-width: unset !important;
}

.podcast-timingsec {
  display: flex;
  align-items: center;
}

.podcast-calender-sec {
  display: flex;
  align-items: center;
}

/* .podcast-calender-sec img {
    height: 23.814661026000977px;
    width: 23.814661026000977px;
    max-width: unset !important;
} */

.podcast-calender-sec p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #ffffff;
  margin-bottom: 0px;
  padding-left: 13px;
}

.podcast-episodes {
  padding-top: 30px;
}

.podcast-timing-sec {
  display: flex;
  align-items: center;
  margin-left: 40px;
}

/* .podcast-timing-sec img {
    height: 17.79323959350586px;
    width: 23.814661026000977px;
    max-width: unset !important;
} */

.podcast-timing-sec p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #ffffff;
  margin-bottom: 0px;
  padding-left: 13px;
}

.podcast-episode-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 32px;
  color: #ffffff;
}

.podcast-episode-para {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  color: #bdbdbd;
  margin-bottom: 0px;
  padding-top: 5px;
  width: 97%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.podcast-center-right {
  margin-left: 51px;
  flex: 1;
}

p.podcast-episode-para.podcast-episode-para1 {
  display: none;
}

.podcast-footer-list2 p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
  margin-bottom: 0 !important;
}

.podcast-footer-list2 {
  padding-left: 22px;
  width: 100%;
}

.podcast-sec {
  margin-bottom: 40px;
}

.set-play-img {
  width: 104px !important;
}

button.btn.set-play-img1 {
  position: absolute;
  bottom: 20px;
  left: 30px;
  background-color: unset !important;
  padding: 0 !important;
}

img.podcast-center-left2 {
  width: 40px;
  z-index: 9;
}

button.btn.podcast-center-left2 {
  position: absolute;
  left: 10px;
  bottom: 10px;
  background-color: unset !important;
  padding: 0 !important;
}

.podcast-center-left {
  position: relative;
}

/* ------------------------Audio Player----------------------------------------------- */

.podcast-footer-list2 .rhap_container {
  background-color: unset !important;
  padding: 0;
}

.podcast-footer-list2 .rhap_additional-controls {
  display: none !important;
}

.podcast-footer-list2 .rhap_progress-filled {
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%) !important;
}

.podcast-footer-list2 .rhap_volume-filled {
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%) !important;
}

.podcast-footer-list2 .rhap_progress-indicator {
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%) !important;
  width: 14px;
  height: 14px;
  top: -5px;
  margin-left: -1px;
}

.podcast-footer-list2 .rhap_time {
  color: #ffffff !important;
  font-size: 15px !important;
}

.podcast-footer-list2 .rhap_progress-bar {
  height: 6px !important;
  background-color: #ffffff !important;
  border-radius: 6px !important;
}

.podcast-footer-list2 .rhap_progress-container {
  margin: 0;
  height: 13px;
}

.podcast-footer-list2 .rhap_progress-section {
  position: relative;
  display: block;
  padding-top: 10px;
}

.podcast-footer-list2 div#rhap_current-time {
  position: absolute;
  top: 26px;
}

.podcast-footer-list2 .rhap_time.rhap_total-time {
  padding-top: 3px;
  text-align: right;
}

.podcast-footer-list2 .rhap_main {
  flex-direction: row;
}

.podcast-footer-list2 .rhap_stacked .rhap_controls-section {
  margin-top: 0px;
  /* margin-left: 60px; */
  /* flex: 1 !important; */
}

/* .podcast-footer-list2  .rhap_volume-bar {
    background: linear-gradient(180deg, #0065D7 0%, #002679 100%) !important;
} */

.podcast-footer-list2 .rhap_main-controls {
  flex: 1 1 auto;
}

.podcast-footer-list2 .rhap_volume-indicator {
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%) !important;
}

.podcast-footer-list2 .rhap_volume-button {
  font-size: 20px !important;
  width: 20px !important;
  height: 20px !important;
  color: #ffffff !important;
  margin-right: 3px !important;
}

.podcast-footer-list1 p {
  display: none;
}

.podcast-footer-list2 .rhap_play-pause-button {
  font-size: 38px;
  width: 38px;
  height: 38px;
}

.podcast-footer-list2 .rhap_main-controls-button {
  /* font-size: 30px;
    width: 30px;
    height: 30px; */
  color: #ffffff;
}

.podcast-footer-list2 button.rhap_button-clear.rhap_main-controls-button.rhap_rewind-button {
  height: 42px;
  color: #ffffff00 !important;
  width: 42px;
  font-size: 42px;
  margin-bottom: 6px;
  margin-right: 30px;
}

.podcast-footer-list2 button.rhap_button-clear.rhap_main-controls-button.rhap_forward-button {
  height: 42px;
  color: #ffffff00 !important;
  width: 42px;
  font-size: 42px;
  margin-left: 20px;
  margin-bottom: 6px;
}

.podcast-footer-list2 button.rhap_button-clear.rhap_main-controls-button.rhap_rewind-button svg {
  background-image: url("../images/Foreward.svg");
}

.podcast-footer-list2 button.rhap_button-clear.rhap_main-controls-button.rhap_forward-button {
  background-image: url("../images/Backward.svg");
}

#donate-now {
  margin-left: 10px;
  padding: 0 !important;
  line-height: 46px;
}

/* 
div#movie {
    height: 88vh !important;
} */

.modal-body {
  padding-top: 7px !important;
  /* height: 90vh !important; */
}

div#movie {
  height: 100% !important;
  min-height: 100% important;
  max-height: 87vh !important;
}

div#series {
  height: 100% !important;
  min-height: 100% important;
  max-height: 87vh !important;
}

.jwplayer.jw-stretch-uniform .jw-media video {
  object-fit: cover !important;
}

#header-container {
  padding: 0 96px;
}

#d-header {
  height: 88px !important;
  margin: 0 !important;
  align-items: center;
}

img.d-video-banner {
  height: 440px !important;
}

.set-gradient-border {
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
  margin: 0 !important;
  border: 0 !important;
  /* height: 1px !important; */
  height: 0px !important;
  padding-top: 1px !important;
}

.set-gradient-border1 {
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
  margin: 0 !important;
  border: 0 !important;
  height: 0px !important;
  padding-top: 1px !important;
}

#set-gradient-border {
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
  margin: 0 !important;
  border: 0 !important;
  /* height: 1px !important;
    padding-bottom: 1px !important; */
  height: 0px !important;
  padding-top: 1px !important;
}

.set-watchlistadd {
  background-color: #50c7076b !important;
  border: 2px solid #50c707 !important;
}

.wraper {
  flex-grow: 1;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#set-seconds-hours {
  width: 63px;
  text-align: right;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #e6e6e6 !important;
}

#set-watchlist-row .col-1-5 {
  flex: 0 0 20% !important;
  max-width: 20% !important;
}

.watchlist-container {
  padding: 40px 90px;
  margin-top: 70px;
}

.watchlist-text p {
  font-size: 28px !important;
  font-weight: 700 !important;
  line-height: 32px !important;
  letter-spacing: 1px !important;
  text-align: left !important;
  font-family: "Roboto", sans-serif;
  margin: 0 !important;
}

.watchlist-container nav.nav.nav-tabs {
  border: 2px solid #2dd0ff;
  border-top: 2px solid #2dd0ff;
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  margin-top: 31px;
  height: 51px;
  padding-left: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.watchlist-container .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none !important;
  color: #ffffff !important;
  background-color: transparent;
  /* border-bottom: 2px solid white !important; */
  padding-bottom: 1px;
  border-radius: 1px;
  margin-bottom: 0px;
}

.watchlist-container .nav-link {
  padding: 0 !important;
}

.watchlist-container .nav-link {
  /* padding-top: 8px !important; */
  border: none !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  font-family: "Poppins", sans-serif;
  color: #f9f3f37d;
  margin-bottom: 0;
}

.watchlist-container .tab-content {
  margin-top: 88px;
}

a#watchlist-tabs-tab-second {
  margin-left: 68px;
}

#watchlist-episodename {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  margin-bottom: 17px !important;
}

#watchlist-date {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

#watchlist-season {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  color: #ffffff;
}

#watchlist-episode {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #dcc07b;
}

#episode-watchlist {
  margin-top: 9px;
}

#set-watchlist-column {
  padding: 0 20px;
  max-width: 348px !important;
}

.watchlist-container .epi-desc.p-3 {
  padding: 8px 16px !important;
  width: 100%;
}

.footer-podscast {
  height: 400px;
}

/* #series-remove {
    margin-left: 5px;
} */

#series-remove {
  height: 40px;
  /* margin-left: 10px; */
  width: 40px;
  cursor: pointer;
}

.episode-play-info {
  display: flex;
  flex-direction: row;
}

#series-play {
  height: 40px;
  width: 40px;
}

.episode-play-info:hover>* {
  display: block;
  /* box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.9);
    background: rgba(0, 0, 0, 0.9); */
}

.episode-play-info:hover {
  background: rgba(0, 0, 0, 0.9);
}

.episode-play-info>* {
  display: none;
}

a#changepwd-save {
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5),
      rgba(24, 145, 180, 0.5));
  border: 2px solid #2dd0ff;
  box-shadow: 0 0 1px transparent;
  color: #fff !important;
  display: inline-block;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  height: 36px;
  letter-spacing: 1px;
  line-height: 31px;
  padding: 0 !important;
  position: relative;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  transition: color 0.3s ease;
  vertical-align: middle;
  width: 120px;
}

#viewall-images {
  aspect-ratio: 35/52;
  width: 90%;
  border: 1px solid #1891b4;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  border-radius: 15px;
}

#watchlist-img-set {
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  aspect-ratio: 9/9;
  width: 100%;
  object-fit: cover;
}

#payment-back {
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 2px solid #2dd0ff;
  color: #ffffff !important;
  transition: color 0.3s ease;
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);
  position: relative;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  padding: 0 !important;
  width: 179px;
  height: 36px;
  margin-top: 30px !important;
}

.payment-img {
  margin-top: 30px;
  width: 80px;
}

#payment-msg {
  margin-top: 25px;
}

#ongoing-color {
  color: #0082f9;
}

.show-episode-text {
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 2px solid #2dd0ff;
  box-shadow: 0px 4px 10px 2px #00000080;
  height: 45px;
  width: 140px;
  border-radius: 30px !important;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  padding: 0 !important;
  box-shadow: 0px 4px 10px 2px #00000080;
}

.series-movie-text {
  padding: 15px;
}

#d-space-show .swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled .d-icon {
  color: #ffffff !important;
}

#d-space-show .swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled .d-icon {
  color: #ffffff !important;
}

a#create-channel {
  display: flex;
  justify-content: center;
  align-items: center;
}

div#viewall-row {
  padding: 0 !important;
  padding-top: 30px !important;
}

#view-all-movie {
  flex: 0 0 14.285%;
  max-width: 14.285%;
}

#view-all-movie1 {
  flex: 0 0 14.285%;
  max-width: 14.285%;
}

#img-box-podcast {
  border-radius: 15px;
  border: 1px solid #616161;
}

#view-all-podcast {
  flex: 0 0 10%;
  max-width: 10%;
}

.episode-play {
  margin-right: 10px;
}

.iq-privacy-policy2 {
  border-bottom: 2px solid #616161;
  border-left: 2px solid #616161;
  border-right: 2px solid #616161;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 30px;
}

#info-logopages {
  width: 222px;
  height: 80px;
}

.set-input-width {
  padding-top: 24px !important;
}

.back-login {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #2dd0ff;
  font-family: "Inter-Regular" !important;
}

svg.sign-vector-img1 {
  margin-bottom: 6px;
  margin-left: 7px;
}

.set-reset-height {
  height: 474px !important;
}

.set-reset-height #set-input-width {
  padding-top: 15px;
}

.set-reset-height #sign-inbtn {
  margin-top: 23px;
}

#d-header .rightside-icon {
  text-align: right;
}

#d-header .center-logo {
  text-align: center;
  display: flex;
  justify-content: center;
  flex: 1 1;
}

/* #d-header .left-logo {
    justify-content: left !important;
} */

div#right-sidetoggle {
  display: flex;
  flex-direction: row-reverse;
  flex: 1 1;
}

#d-header-col {
  display: flex;
  align-items: center;
  flex: 1 1;
  padding: 0 !important;
  justify-content: space-between;
}

.header-profile {
  display: flex;
  flex: 1 1;
  align-items: center;
}

.header-profile-drawer {
  display: flex;
  flex: 1 1;
  align-items: center;
}

.header-profile p {
  margin: 0 !important;
  padding-left: 12px;
  /* font-family: "Inter-Regular" !important; */
  font-family: "Poppins-Regular", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #ffffff;
  width: 184px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.header-profile-drawer p {
  margin: 0 !important;
  padding-left: 12px;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #ffffff;
  width: 184px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.iq-sub-dropdown.dropdown-menu.dropdown-menu-right.dropdown-menu.show {
  width: 256px !important;
  background: linear-gradient(180deg,
      #181818 10.62%,
      #000000 94.74%) !important;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.75) !important;
  height: auto !important;
  border-radius: 20px !important;
  border: none !important;
  transform: translate3d(37px, 54px, 0px) !important;
}

svg.black-polygon {
  position: absolute;
  top: -19px;
  right: 31px;
}

#polygon-set {
  position: relative;
}

#header-drawer .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
  width: 256px !important;
  background: #181818 !important;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
}

#address-drawer .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
  width: 256px !important;
  background: #181818 !important;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
}

.drawer-content {
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
}

#header-drawer .drawer-profile {
  width: 32px;
  height: 32px;
}

#header-drawer .header-profile p {
  padding-left: 8px;
  margin: 0 !important;
  font-family: "Roboto-Regular" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  width: 184px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #bdbdbd;
  text-align: left;
}

#header-drawer .header-profile-drawer p {
  padding-left: 8px;
  margin: 0 !important;
  font-family: "Roboto-Regular" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  width: 184px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #bdbdbd;
  text-align: left;
}

.profilename-text {
  display: none;
}

#header-drawer .header-category {
  display: flex;
  align-items: center;
  margin-top: 28px;
  height: 23px;
}

#header-drawer .iq-sub-card.setting-dropdown {
  padding: 0 !important;
}

#header-drawer .set-profile-info h6 {
  padding-left: 8px;
  font-family: "Roboto-Regular" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  color: #bdbdbd;
}

#header-drawer .right-icon {
  width: 32px;
  text-align: center;
}

#header-drawer .drawer-icon {
  font-size: 22px !important;
  color: #bdbdbd !important;
}

#header-drawer .drawer-icon-active {
  font-size: 22px !important;
  color: #2dd0ff !important;
}

#header-drawer .set-profile-info-active h6 {
  color: #2dd0ff !important;
  padding-left: 8px;
  font-family: "Roboto-Regular" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
}

#right-sidetoggle .iq-card-body.iq-card-body {
  padding: 40px 32px 24px 32px;
}

#right-sidetoggle .veuit-channel-create {
  width: 192px;
  height: 36px;
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 1px solid #2dd0ff;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  /* font-family: 'Poppins', sans-serif; */
  font-family: "Poppins-Regular", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 33px;
  text-transform: capitalize;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 16px;
  padding: 0 !important;
}

#right-sidetoggle .veuit-channel-signin {
  width: 192px;
  height: 36px;
  background: rgba(97, 97, 97, 0.5);
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 100px !important;
  /* font-family: 'Poppins', sans-serif; */
  font-family: "Poppins-Regular", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 34px;
  color: #ffffff !important;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none !important;
}

#right-sidetoggle .toggle-text {
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 24px;
}

#right-sidetoggle .toggle-text p {
  /* font-family: 'Poppins', sans-serif; */
  font-family: "Poppins-Regular", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  color: #ffffff;
  padding: 0 22px;
  margin: 0 !important;
}

#right-sidetoggle .toggle-text-line {
  width: 64px;
  height: 1px;
  background: #616161;
  border: none !important;
  margin: 0 !important;
}

#create-channels {
  height: auto !important;
  /* width: 986px;
    background: linear-gradient(0deg, #000000 0%, #181818 100%);
    box-shadow: 0px -8px 50px rgba(45, 208, 255, 0.3); */
  width: 928px;
  border-radius: 35px;
  padding: 46px 56px !important;
  min-height: 571px;
  /* padding: 40px 84px !important;
    min-height: 626px; */
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  padding-top: 28px !important;
}

#channel-logos {
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  margin-bottom: 29px;
  align-items: center;
}

#create-channels .veuit-logo {
  width: 122px;
  height: 48px;
}

#create-channels .set-rounds {
  display: flex;
  align-items: center;
}

#create-channels .set-rounds-no {
  border: 2px solid #616161;
  background: transparent !important;
  font-family: "Roboto-Regular" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #616161;
  height: 28px;
  width: 28px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#create-channels .set-rounds-no.visited {
  border-radius: 28px;
  border: 2px solid #2dd0ff;
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%) !important;
  color: #fff;
}

#create-channels .set-rounds-no.active {
  border: 2px solid #2dd0ff;
  color: #2dd0ff;
}

#create-channels .set-rounds .set-rounds-text {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #616161;
  margin: 0 !important;
  padding-left: 8px;
}

#create-channels .set-rounds .set-rounds-text.active {
  color: #2dd0ff;
}

#create-channels .set-rounds .set-rounds-text.visited {
  color: #fff;
}

#create-channels .set-rounds-active {
  display: flex;
  align-items: center;
}

#create-channels .set-rounds-no-active {
  border: 2px solid #2dd0ff;
  background: transparent !important;
  font-family: "Roboto-Regular" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23.44px;
  color: #2dd0ff;
  height: 28px;
  width: 28px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#create-channels .set-rounds-active p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #2dd0ff;
  margin: 0 !important;
  padding-left: 8px;
}

#create-channels .close-vector {
  width: 22px;
  height: 22px;
  margin-top: 5px;
  cursor: pointer;
}

.channel-registration {
  background: #181818;
  border: 1px solid #1891b4;
  border-radius: 15px;
  margin: 0 auto;
  padding-left: 74px;
  padding-right: 64px;
  width: 1005px;
}

.set-channel-img {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: auto;
  padding-top: 206px !important;
}

#m-profile {
  padding-top: 0px !important;
}

#m-channel-newprofile {
  padding-top: 0px !important;
}

#create-channels .new-channel-text {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 24px !important;
  text-align: left;
  padding-left: 12px;
}

#create-channels .new-channel-text span {
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

#newchannel-fieldset {
  padding-top: 24px;
}

#create-channels .new-channel-form-label {
  font-family: "Roboto-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding-bottom: 8px;
  padding-left: 12px;
  margin: 0 !important;
}

#create-channels .new-channel-form-label span {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #bdbdbd;
  padding-left: 8px;
}

#create-channels .new-channel-form-label1 {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
}

#create-channels .new-channel-form-label1 p {
  font-family: "Roboto-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding-left: 12px;
  margin: 0 !important;
}

#create-channels .new-channel-form-label1 span {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #bdbdbd;
  padding-left: 8px;
}

#create-channels .new-channel-field {
  background: #252525 !important;
  border-radius: 20px !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  border: none !important;
  height: 40px !important;
}

#create-channels .new-channel-field::placeholder {
  color: #939393 !important;
}

#create-channels .new-channel-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#create-channels .new-channel-select {
  background: #252525 !important;
  border-radius: 20px !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  border: none !important;
  height: 40px !important;
  width: 400px !important;
  flex: unset !important;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../images/category.svg") !important;
  /* background-image: url("../images/arrow.png") !important; */
  background-repeat: no-repeat !important;
  background-position: calc(100% - 12px) 19px !important;
  background-size: 12px !important;
}

#create-channels .custom-control-inline {
  padding: 0 12px;
  width: 316px !important;
  height: 40px !important;
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 20px !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#create-channels .new-channel-radio {
  right: 0;
  width: 16px;
  height: 16px;
  top: 1px;
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
  border: 2px solid #2dd0ff;
  border-radius: 5px;
  margin: 0 !important;
  accent-color: #1891b4;
}

.header-profile {
  width: 33%;
}

.center-logo {
  width: 33%;
}

#create-channels #input-group-dropdown-1 {
  background: #252525 !important;
  border-radius: 20px !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  border: none !important;
  height: 40px !important;
  width: 400px !important;
  flex: unset !important;
}

#create-channels .custom-control-inline1 {
  width: 484px !important;
  height: 40px !important;
  background: rgba(255, 255, 255, 0.1) !important;
  border-radius: 20px !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 12px;
}

#create-channels .channel-label1 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

#create-channels .custom-control-label::after {
  position: unset !important;
  top: 0.25rem;
  left: 16.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

#create-channels .custom-control-label::before {
  position: absolute;
  right: 0;
  left: 0 !important;
  display: flex;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  user-select: none;
  background-color: #dee2e6;
}

#create-channels .channel-label1 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}

#create-channels .set-domain-name1 {
  background: #252525 !important;
  border-radius: 20px 0px 0px 20px !important;
  height: 40px !important;
  width: 538px;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  flex: unset !important;
}

#create-channels .set-domain-name2 {
  background: #252525 !important;
  border-radius: 0px 20px 20px 0px !important;
  height: 40px !important;
  width: 112px;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff !important;
  flex: unset !important;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../images/category.svg") !important;
  /* background-image: url("../images/arrow.png") !important; */
  background-repeat: no-repeat !important;
  background-position: calc(100% - 20px) 17px !important;
  background-size: 15px !important;
}

#create-channels .input-group-prepend {
  margin-right: -1px;
  margin-left: 2px;
}

#create-channels .form-check {
  padding-left: 0 !important;
}

#create-channels .set-domain-name1::placeholder {
  color: #939393;
}

#new-channel-row {
  padding-top: 99px !important;
  margin: 0 !important;
  height: auto !important;
}

#create-channels .new-channel-check {
  width: 148px;
  height: 36px;
  background: linear-gradient(180deg, #101010 0%, #000000 100%);
  border: 1px solid #616161;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.5);
  border-radius: 18px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  padding: 0 !important;
}

#create-channels .new-channel-check.active {
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 1px solid #2dd0ff;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.5);
}

#create-channels .new-channel-check-mob {
  width: 100%;
  height: 40px;
  background: linear-gradient(180deg, #101010 0%, #000000 100%);
  border: 1px solid #616161;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.5);
  border-radius: 18px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  padding: 0 !important;
  display: none;
}

#create-channels .new-channel-check-mob.active {
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 1px solid #2dd0ff;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.5);
}

#create-channels .new-channel-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#create-channels .new-channel-back {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#create-channels .new-channel-back1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#create-channels .new-back-btn {
  background: rgba(97, 97, 97, 0.5);
  border-radius: 18px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  width: 120px;
  height: 32px;
  margin-right: 14px;
  padding: 0 !important;
}

#create-channels .new-channel-next {
  width: 120px;
  height: 32px;
  background: linear-gradient(180deg, #101010 0%, #000000 100%);
  border: 1px solid #616161;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.5);
  border-radius: 18px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0 !important;
}

#create-channels .new-channel-next.active {
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 1px solid #2dd0ff;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.5);
}

#create-channels .footer-channel-text {
  padding: 0 !important;
}

#create-channels .footer-channel-text a {
  color: #2dd0ff !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0 3px;
}

#create-channels .footer-channel-text p {
  margin: 0 !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #bdbdbd;
}

.error-code {
  font-family: "Inter-Regular" !important;
}

.error-text {
  font-family: "Inter-Regular" !important;
}

div#set-header-line {
  border-top: 1px solid #616161;
}

#iq-main-slider {
  padding-top: 88px !important;
}

.header-profile img {
  cursor: pointer;
}

div#BannerSeriesTrailer {
  max-height: 90vh;
}

.modal-body {
  max-height: 92vh;
}

.video-mobile-back {
  display: flex;
  align-items: center;
}

.video-mobile-back .video-series-title {
  color: #ffffff;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  margin: 0 !important;
}

.video-movies {
  padding-left: 10px;
}

.video-mobile-back .video-series-episode {
  color: #ffffff;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin: 0 !important;
}

.new-channel-myexample {
  padding-top: 16px;
  padding-bottom: 22px;
}

#create-channels .new-channel-availability {
  margin: 0 !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: #ffffff;
  padding-bottom: 8px;
  padding-left: 12px;
}

#create-channels .new-channel-availability span {
  color: #2dd0ff;
}

#create-channels .newchannel-domain-example {
  height: 48px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 12px;
}

#create-channels .newchannel-mydomain {
  margin: 0 !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  width: 50%;
  text-align: left;
}

#create-channels .newchannel-close {
  display: flex;
  align-items: center;
}

#create-channels .newchannel-close p {
  margin: 0 !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  padding-left: 9px;
}

#create-channels .domain-suggestions {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  width: 820px;
  height: 48px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px 10px 0px 0px;
  padding: 0 12px;
  margin-bottom: 2px;
}

#create-channels #domain-suggestion {
  margin-top: 40px !important;
}

#create-channels #domain-suggestions1 {
  margin-top: 2px;
  border-radius: 0px;
}

#create-channels #domain-suggestions2 {
  margin-top: 2px;
  border-radius: 0px 0px 10px 10px;
}

#create-channels .newchannel-available {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#create-channels .newchannel-available p {
  margin: 0 !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  padding-left: 9px;
}

#create-channels .new-channel-radio1 {
  right: 0;
  width: 16px;
  height: 16px;
  top: 4px;
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
  border: 2px solid #2dd0ff;
  border-radius: 5px;
  margin: 0 !important;
  accent-color: #1891b4;
}

#create-channels .channel-notavailable {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

/* #create-channels [type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #000000 !important;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
} */

.new-channel-dataplan {
  display: flex;
  align-items: center;
}

.new-create-channel {
  margin: 50px 0;
}

.channel-plan {
  padding: 24px 16px 20px 0;
  width: 258px;
  height: 386px;
  border-radius: 15px;
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1));
}

.dataplan-standard {
  background: linear-gradient(165.99deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0) 90.01%);
  border-radius: 15px;
  padding: 1px;
  margin-right: 20px;
}

.dataplan-premium {
  background: linear-gradient(165.99deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0) 90.01%);
  border-radius: 15px;
  padding: 1px;
  margin-right: 20px;
}

.dataplan-standard-text {
  width: 167px;
  height: 40px;
}

.dataplan-standard-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dataplan-enterprise {
  background: linear-gradient(165.99deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0) 90.01%);
  border-radius: 15px;
  padding: 1px;
}

.dataplan-standard-price-tag p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  margin: 0 !important;
}

.dataplan-standard-price-tag span {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}

.dataplan-standard-timings {
  padding-left: 16px;
  padding-top: 20px;
}

.dataplan-standard-timing {
  display: flex;
  align-items: center;
  padding-bottom: 9px;
}

.dataplan-standard-mins {
  padding-left: 8px;
  text-align: left;
}

.dataplan-standard-mins p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  text-align: left;
  margin: 0 !important;
}

.dataplan-standard-mins span {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #bdbdbd;
  text-align: left;
}

.dataplan-select {
  width: 148px;
  height: 32px;
  border: 1px solid #ffffff;
  border-radius: 16px !important;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  background: transparent;
  padding: 0 !important;
  margin-top: 5px;
  text-transform: unset;
  color: #ffffff !important;
  cursor: pointer;
}

.dataplan-select:hover {
  background-color: unset !important;
  text-transform: unset;
  border-color: #ffffff;
}

.dataplan-standard .dataplan-select.active {
  background: linear-gradient(180deg, #126088 0%, #06344b 100%) !important;
  border: unset !important;
  text-transform: unset !important;
}

.dataplan-premium .dataplan-select.active {
  background: linear-gradient(180deg, #1d9bd1 0%, #04628a 100%) !important;
  border: unset !important;
  text-transform: unset !important;
}

.dataplan-enterprise .dataplan-select.active {
  background: linear-gradient(180deg, #63cdfa 0%, #1891b4 100%) !important;
  border: unset !important;
  text-transform: unset !important;
}

.dataplan-standard-price-tag .dollar {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  margin: 0 !important;
}

.payments-option {
  display: flex;
  margin-bottom: 28px;
}

.payment-monthly {
  padding-top: 16px;
}

.payment-monthly h4 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  padding-bottom: 12px;
  text-align: left;
}

.payment-method {
  width: 481px;
  min-height: 296px;
  border: 1px solid #616161;
  border-radius: 10px;
  padding-top: 16px;
}

.payment-method h5 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding-bottom: 16px;
  text-align: left;
  padding-left: 16px;
}

.new-card-add p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  margin: 0 !important;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-top: 12px;
}

.payments-monthly {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #616161;
  padding: 15.5px 15.59px 15.5px 21.8px;
}

.billing-address {
  display: flex;
  align-items: center;
}

.billing-address-selected p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin: 0 !important;
  text-align: left;
}

.billing-address-selected {
  padding-left: 22px;
  text-align: left;
}

.billing-address-selected span {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #bdbdbd;
  text-align: left;
}

.series-listname {
  font-family: "Roboto", sans-serif;
  color: #ffffff !important;
  font-size: 48px;
  font-weight: 900;
  line-height: 40px;
  letter-spacing: 0.01em;
  text-align: center;
  margin-bottom: 10px !important;
  text-transform: uppercase;
}

.payments-order {
  padding: 16px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  margin-left: 19px;
  width: 315px;
  height: auto;
}

.payments-order-total {
  display: flex;
  justify-content: space-between;
  padding-bottom: 11.1px;
  border-bottom: 1px solid #616161;
  margin-bottom: 7px;
}

.payments-order-total p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: 0 !important;
}

.payments-order-total h4 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.payments-dataplan {
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
}

.payments-dataplan p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #bdbdbd;
  margin: 0 !important;
  text-align: left;
}

.payments-dataplan h4 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #bdbdbd;
}

.setup-monthly {
  padding-top: 100px;
}

.setup-monthly-pay {
  width: 284px;
  height: 48px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px 10px 0px 0px;
  padding: 4px 12px;
}

.setup-monthly-autopay h4 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  text-align: left;
}

.setup-monthly-autopay h4>span {
  cursor: pointer;
}

.setup-monthly-autopay p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #bdbdbd;
  text-align: left;
  margin: 0 !important;
}

.setup-monthly-pay {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-channel-radio-pay {
  right: 0;
  width: 16px;
  height: 16px;
  bottom: -8px;
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
  border: 2px solid #2dd0ff;
  border-radius: 5px;
  margin: 0 !important;
  accent-color: #1891b4;
}

.setup-monthly-amount {
  width: 284px;
  height: 48px;
  background: rgba(255, 255, 255, 0.1);
}

.setup-monthly-price {
  width: 284px;
  height: 48px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 12px;
  margin: 4px 0;
}

.setup-monthly-payment {
  width: 284px;
  height: 48px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 12px;
  border-radius: 0px 0px 10px 10px;
}

.place-order-btn {
  margin-top: 19px;
  margin-bottom: 25px;
  width: 282px;
  height: 40px;
  background: linear-gradient(180deg, #101010 0%, #000000 100%);
  border: 1px solid #616161;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.5);
  border-radius: 18px;
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.place-order-btn.active {
  border-radius: 22px;
  border: 1px solid #19e221;
  background: linear-gradient(180deg,
      rgba(110, 250, 87, 0.5) 0%,
      rgba(11, 189, 7, 0.5) 100%);
}

.place-order-btn:hover,
.place-order-btn:hover {
  outline: none !important;
}

.discount-code {
  width: 282px !important;
  height: 36px !important;
  background: #252525 !important;
  border: 1px solid #616161 !important;
  border-radius: 20px !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff !important;
  margin-top: 24px;
}

.discount-code::placeholder {
  color: #939393 !important;
}

.discout-form-group {
  margin: 0 !important;
}

.terms-services {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  margin: 0 auto !important;
  text-align: center;
  color: #bdbdbd;
  padding-bottom: 15px;
}

.terms-services span {
  color: #2dd0ff;
  font-size: 12px;
}

#address-modal {
  /* width: 524px !important; */
  background: linear-gradient(0deg, #000000 0%, #181818 100%);
  border: 1px solid #616161;
  border-radius: 35px;
  padding: 23px 20px;
}

#modal-content-wrapper {
  /* width: 524px !important; */
  background: linear-gradient(0deg, #000000 0%, #181818 100%);
  border: 1px solid #616161;
  border-radius: 35px;
  padding: 23px 20px;
}

.customer-billing h4 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
}

.customer-billing p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin: 0 !important;
  padding-top: 8px;
}

.customer-billing span {
  color: #2dd0ff;
}

#billing-address-modal .modal-content {
  background-color: unset !important;
  border: none !important;
  border-radius: unset !important;
}

#billing-address-modal .modal-header {
  padding: 0 !important;
}

#billing-address-modal .modal-header .close {
  padding: 14px 16px 0px 0px;
  /* padding: 16px; */
  margin: -1rem -1rem -1rem auto;
  color: #ffffff !important;
  opacity: unset !important;
  text-shadow: unset !important;
}

#billing-address-modal .modal-header .close span {
  font-size: 27px;
  color: #ffffff;
  font-weight: 300;
}

#billing-address-modal\ hide-mobile-modal .modal-content {
  background-color: unset !important;
  border: none !important;
  border-radius: unset !important;
}

#billing-address-modal\ hide-mobile-modal .modal-header {
  padding: 0 !important;
}

#billing-address-modal\ hide-mobile-modal .modal-header .close {
  padding: 14px 16px 0px 0px;
  /* padding: 16px; */
  margin: -1rem -1rem -1rem auto;
  color: #ffffff !important;
  opacity: unset !important;
  text-shadow: unset !important;
}

#billing-address-modal\ hide-mobile-modal .modal-header .close span {
  font-size: 27px;
  color: #ffffff;
  font-weight: 300;
}



#billing-address-modal\ show-model .modal-content {
  background-color: unset !important;
  border: none !important;
  border-radius: unset !important;
}

#billing-address-modal\ show-model .modal-header {
  padding: 0 !important;
}

#billing-address-modal\ show-model .modal-header .close {
  padding: 14px 16px 0px 0px;
  /* padding: 16px; */
  margin: -1rem -1rem -1rem auto;
  color: #ffffff !important;
  opacity: unset !important;
  text-shadow: unset !important;
}

#billing-address-modal\ show-model .modal-header .close span {
  font-size: 27px;
  color: #ffffff;
  font-weight: 300;
}

#app-modal .modal-content {
  background-color: unset !important;
  border: none !important;
  border-radius: unset !important;
}

#app-modal .modal-header {
  padding: 0 !important;
}

#app-modal .modal-header .close {
  padding: 14px 16px 0px 0px;
  /* padding: 16px; */
  margin: -1rem -1rem -1rem auto;
  color: #ffffff !important;
  opacity: unset !important;
  text-shadow: unset !important;
}

#app-modal .modal-header .close span {
  font-size: 27px;
  color: #ffffff;
  font-weight: 300;
}

#app-modal\ hide-mobile-modal .modal-content {
  background-color: unset !important;
  border: none !important;
  border-radius: unset !important;
}

#app-modal\ hide-mobile-modal .modal-header {
  padding: 0 !important;
}

#app-modal\ hide-mobile-modal .modal-header .close {
  padding: 14px 16px 0px 0px;
  /* padding: 16px; */
  margin: -1rem -1rem -1rem auto;
  color: #ffffff !important;
  opacity: unset !important;
  text-shadow: unset !important;
}

#app-modal\ hide-mobile-modal .modal-header .close span {
  font-size: 27px;
  color: #ffffff;
  font-weight: 300;
}

.customer-billing {
  margin-bottom: 24px;
}

.new-billing-add {
  display: flex;
  align-items: self-start;
  width: 484px;
  height: 124px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  margin-bottom: 8px;
  padding: 12px;
}

#new-billing-add {
  justify-content: space-between;
}

#new-billing-add .newbilling {
  display: flex;
}

#new-billing-add .editaddress p {
  color: #2dd0ff;
  text-align: right;
  font-size: 14px;
  font-family: "Inter-Regular" !important;
  line-height: 20px;
  margin: 0;
  cursor: pointer;
}

.new-billing-add.active {
  background: rgba(45, 208, 255, 0.25);
  border-radius: 10px;
}

.new-billing-add-info {
  padding-left: 12px;
}

.new-billing-add-info h4 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.new-billing-add-info p {
  margin: 0 !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #bdbdbd;
}

.new-billing-radio {
  right: 0;
  width: 16px;
  height: 16px;
  top: 2px;
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
  border: 2px solid #2dd0ff;
  border-radius: 5px;
  margin: 0 !important;
  accent-color: #1891b4;
}

.add-new-address {
  padding: 16px 12px;
  width: 484px;
  height: 48px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.add-new-address p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #2dd0ff;
  padding-left: 12px;
  margin: 0 !important;
}

.address-btn {
  width: 188px;
  height: 36px;
  background: linear-gradient(180deg, #101010 0%, #000000 100%);
  border: 1px solid #616161;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  margin-top: 24px;
  text-transform: unset;
  padding: 0 !important;
  cursor: pointer;
}

.address-btn:hover,
.address-btn:focus {
  background-color: unset !important;
  text-transform: unset;
  border-color: #616161 !important;
  outline: none !important;
}

.address-btn.active {
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  border: 1px solid #2dd0ff;
  text-transform: unset !important;
}

.add-new-address-btn {
  text-align: center;
}

.dataplan-enterprises {
  background: #000000;
  border-radius: 15px;
}

.add-address-btn {
  width: 188px;
  height: 36px;
  background: linear-gradient(180deg, #101010 0%, #000000 100%);
  border: 1px solid #616161;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.add-address-btn.active {
  border: 1px solid #2dd0ff;
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.5);
}

.payment-form-label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding-bottom: 7px;
  padding-left: 12px;
}

.payment-phn-number {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment-phn-number span {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #2dd0ff;
  padding-bottom: 6px;
  cursor: pointer;
}

.payment-form-field {
  width: 484px !important;
  height: 40px !important;
  background: #252525 !important;
  border-radius: 20px !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #ffffff !important;
  border: none !important;
}

#payment-form-field {
  margin-top: 8px;
}

.payment-form-field::placeholder {
  color: #939393 !important;
}

.new-payment-select {
  background: #252525 !important;
  border-radius: 20px !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  border: none !important;
  height: 40px !important;
  width: 484px !important;
  flex: unset !important;
  margin: 0;
  webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../images/category.svg") !important;
  background-repeat: no-repeat !important;
  background-position: calc(100% - 12px) 19px !important;
  background-size: 11px !important;
}

.new-payment-select1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  background: #252525 !important;
  border-radius: 20px !important;
  height: 40px !important;
  width: 484px !important;
  cursor: pointer;
}

.new-payment-select1 p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  margin: 0;
}

.new-payment-select::placeholder {
  color: #fff !important;
}

.new-payment-select:hover {
  color: #fff !important;
}

.new-payment-select:focus {
  color: #fff !important;
}

.new-country-select:focus {
  color: #fff !important;
}

.new-country-select {
  width: 232px;
  height: 40px;
  background: #252525 !important;
  border-radius: 20px !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  border: none !important;
  flex: unset !important;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../images/category.svg") !important;
  /* background-image: url("../images/arrow.png") !important; */
  background-repeat: no-repeat !important;
  background-position: calc(100% - 12px) 18px !important;
  background-size: 12px !important;
}

.payment-zipcode {
  width: 232px !important;
  height: 40px !important;
  background: #252525 !important;
  border-radius: 20px !important;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #ffffff !important;
  border: none !important;
}

.payment-zipcode::placeholder {
  color: #939393 !important;
}

.add-address-state {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.setup-default-add {
  width: 484px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 44px;
}

.setup-default-add h4 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

.new-payment-radio-pay {
  right: 0;
  width: 16px;
  height: 16px;
  bottom: -8px;
  background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
  border: 2px solid #2dd0ff;
  border-radius: 5px;
  margin: 0 !important;
  accent-color: #1891b4;
}

.add-address-state .form-group1 {
  margin-bottom: 16px !important;
}

.new-address-field .form-group {
  margin-bottom: 1.27rem !important;
}

#billing-address-modal {
  max-width: 524px !important;
}

#billing-address-modal\ hide-mobile-modal {
  max-width: 524px !important;
}

#billing-address-modal\ show-model {
  max-width: 524px !important;
}


.align-setup-mob {
  display: flex;
  justify-content: space-evenly;
  flex: 1;
}

.order-confirmation h4 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
}

.order-confirmation {
  margin-bottom: 16px;
}

.order-place {
  width: 484px;
  height: 140px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 16px 14px 16px 16px;
}

.order-place1 {
  display: flex;
  align-items: center;
}

.order-place-text {
  padding-left: 16px;
}

.order-place-text h4 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.order-place-text h5 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2dd0ff;
  padding-top: 12px;
}

.order-place-text p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  padding-top: 8px;
  margin: 0;
}

.order-place .confirm-msg {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  padding-top: 8px;
  margin: 0;
  display: none;
}

.orderplace-image {
  width: 100px;
  height: 108px;
}

.order-place-image {
  width: 134px;
}

.order-billing-address {
  border: 1px solid #616161;
  border-radius: 10px;
  width: 484px;
  height: 72px;
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 28px;
  padding: 16px 16px 16px 19px;
}

.order-billing-text {
  padding-left: 15px;
}

.order-billing-text h4 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.order-billing-text p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #bdbdbd;
  margin: 0;
  padding-top: 4px;
}

.premium-dataplan {
  display: flex;
  margin-bottom: 22px;
  align-items: center;
}

.premium-dataplan-text {
  padding-left: 17px;
  width: 100%;
}

.premium-dataplan-text h4 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}

.premium-dataplan-text h5 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #bdbdbd;
}

.premium-dataplan-text p {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  padding-top: 8px;
  margin: 0;
}

.premium-dataplan-text span {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
}

.premium-dataplan-text h6 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: right;
  color: #bdbdbd;
}

.premium-dataplans {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.premium-dataplanimage {
  background: linear-gradient(165.99deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0) 90.01%);
  border-radius: 10px;
  padding: 1px;
}

.premium-dataplan-image {
  background: #000000;
  border-radius: 10px;
}

.premiumdataplan-image {
  padding: 12px;
  width: 80px;
  height: 80px;
  border-radius: 10px;
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1));
}

.review-order-btn {
  width: 484px;
  height: 36px;
  background: rgba(97, 97, 97, 0.5);
  border-radius: 26px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: unset !important;
  cursor: pointer;
}

.new-card-add2 {
  background: linear-gradient(165.99deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0) 90.01%);
  border-radius: 9.64911px;
  padding: 1px;
  min-width: 132px;
  min-height: 84px;
}

.new-card-add1 {
  background: #000000;
  border-radius: 9.64911px;
}

.new-card-add {
  padding: 16px 13px;
  border-radius: 9.64911px;
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1));
  cursor: pointer;
}

.card-information {
  max-width: 484px;
  height: 232px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 14px;
  text-align: center;
  padding: 16px;
  margin-bottom: 22px;
}

.card-information.active {
  border-radius: 14px;
  background: rgba(45, 208, 255, 0.25);
}

.card-information .cardholder-name {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #bdbdbd;
  margin: 0;
  padding-top: 16px;
}

.card-information .cardholder-name.active {
  color: #fff;
}

.card-image {
  width: 270px;
  height: 168px;
  border-radius: 19.6934px;
}

#set-card-img {
  position: relative;
}

.card-image-vec {
  position: absolute;
  right: 12px;
  bottom: 12px;
}

.setup-default-add-card {
  width: 484px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 44px;
}

.setup-default-add-card h4 {
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}

#billing-bottom {
  margin-bottom: 16px !important;
}

#setup-default-add-card {
  margin-bottom: 24px !important;
}

.add-expirydate {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-details .form-group {
  margin-bottom: 1.2rem !important;
}

.dropdown-menu.dropdown-menu-right {
  display: block !important;
}

.bottomDrawer {
  display: none;
}

.payment-method .payment-card {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 9.69px;
  /* margin-right: 16px; */
}

.payment-cards .payment-number {
  margin-right: 16px;
}

.payment-cards .add-cardnumber {
  min-width: 132px;
  width: 132px;
  height: 84px;
  position: relative;
  border-radius: 9.69px;
}

.payment-cards .add-cardnumber.active {
  border: 2px solid #2dd0ff;
}

.payment-cards .add-cardnumber p {
  position: absolute;
  right: 8px;
  bottom: 8px;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  margin: 0;
}

.payment-method .payment-cards {
  display: flex;
  align-items: flex-start;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 16px;
  overflow-x: scroll;
}

.modal::-webkit-scrollbar {
  width: 0 !important;
}

.modal {
  scrollbar-width: none;
}

.modal-open .modal {
  padding: 0 !important;
  /* z-index: 1700 !important; */
}

.payment-method .payment-cards::-webkit-scrollbar {
  width: 1px !important;
  height: 5px;
}

.payment-method .payment-cards::-webkit-scrollbar-thumb {
  background: #2dd0ff;
}

.payment-method .payment-cards {
  scrollbar-color: #2dd0ff #fff !important;
  scrollbar-width: thin;
}

.add-new-address-btn .update-btn {
  border-radius: 20px;
  border: 1px solid #2dd0ff;
  background: linear-gradient(180deg,
      rgba(45, 208, 255, 0.5) 0%,
      rgba(24, 145, 180, 0.5) 100%);
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.5);
  width: 148px;
  height: 36px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.14px;
  margin-right: 20px;
  cursor: pointer;
}

.add-new-address-btn .delete-btn {
  border-radius: 20px;
  background: rgba(97, 97, 97, 0.5);
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.5);
  width: 148px;
  height: 36px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.14px;
  border: unset;
  cursor: pointer;
}

.new-card-add svg {
  cursor: pointer;
}

.payments-monthly .Addarrow {
  cursor: pointer;
}

.about-sliderimg {
  height: 100% !important;
  min-height: 100% important;
  max-height: 87vh !important;
  width: 100%;
}

#modal-fullscreen {
  /* margin: 0 auto; */
  /* margin-top: 10px; */
}

/* #modal-fullscreen .modal-content {
    border: 1px solid #616161;
    background: linear-gradient(0deg, #000 0%, #181818 100%);
}

#modal-fullscreen small,
.text_small,
span {
    font-size: 40px;
    color: #fff;
    font-weight: 300;
    line-height: 19px;
}

#modal-fullscreen .modal-header {
    align-items: center;
} */

#payment-modal-new {
  max-width: 928px;
  background: transparent;
}

#payment-modal-new .modal-content {
  background-color: transparent;
}

#button-tooltip {
  z-index: 9999;
}

#button-tooltip .tooltip-inner {
  background: linear-gradient(180deg, #181818 11.11%, #000 100%);
  border: 1px solid #616161;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.75));
  padding: 16px;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  max-width: 200px;
  border-radius: 15px;
  padding-right: 6px;
  margin-right: 10px;
  margin-top: -4px;
}

#button-tooltip .right-arrow {
  right: -23.99px;
  position: absolute;
  top: 12px;
}

#button-tooltip .arrow {
  display: none;
}

#monthlyautopay-tooltip {
  z-index: 9999;
}

#monthlyautopay-tooltip .arrow {
  display: none;
}

#monthlyautopay-tooltip .tooltip-inner {
  background: linear-gradient(180deg, #181818 11.11%, #000 100%);
  border: 1px solid #616161;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.75));
  padding: 8px 6px 8px 12px;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  max-width: 366px;
  border-radius: 15px;
  margin-right: 10px;
}

#monthlyautopay-tooltip .right-arrow {
  right: -24.95px;
  position: absolute;
  top: 13px;
}

#monthlyautopay-tooltip-mobile .right-arrow-mobile {
  position: absolute;
  top: 60px;
  left: 45%;
  right: 50%;
}

#monthlyautopay-tooltip-mobile .tooltip-inner {
  /* margin-bottom: 5px; */
  background: linear-gradient(180deg, #181818 11.11%, #000 100%);
  border: 1px solid #616161;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.75));
  padding: 8px 6px 8px 12px;
  font-family: "Inter-Regular" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  max-width: 366px;
  border-radius: 15px;
  margin-right: 10px;
  text-align: left;
}

#monthlyautopay-tooltip-mobile .arrow {
  display: none;
}

#monthlyautopay-tooltip-mobile {
  display: none;
}

.setup-monthly-autopay .ivector-mobile {
  display: none;
}

#favorites-contens .swiper-wrapper {
  /* padding: 15px 0; */
  padding-top: 15px;
}

.set-view-all-text {
  margin-bottom: 0px !important;
}

.swiper-slide:hover .block-images {
  border-radius: 20px 15px 15px 20px;
}

.swiper-slide:hover .block-images::before {
  border-radius: 15px;
}

.option-select {
  color: #ffffff;
}

/* ---------------------Purchase ------------*/
.purchases-page {
  padding-top: 90px;
}

.purchase-container {
  width: 1165px;
  margin: 40px auto;
}

.searchbar-products {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 41px;
}

.purchase-text {
  display: flex;
  align-items: center;
}

.purchase-text .arrow-right {
  margin-left: -24px;
  cursor: pointer;
}

.purchase-text h4 {
  color: #FFF;
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-left: 12px;
}

.shows-search {
  display: flex;
  align-items: center;
}

.shows-filter {
  position: relative;
}

.shows-filter .search-icon {
  position: absolute;
  top: 7px;
  left: 14px;
}

.shows-filter .search-field {
  color: #fff;
  font-family: "Inter-Regular" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border-radius: 10px;
  border: 1px solid #2dd0ff;
  background: var(--Linear, linear-gradient(90deg, #000 0%, #181818 83.33%));
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.5);
  width: 300px;
  height: 30px;
  padding-left: 52px;
}

.shows-filter .form-group {
  margin-bottom: 0 !important;
}

.shows-search .filter-icon {
  margin-right: 10px;
}

.shows-search .filter-btn {
  border-radius: 10px;
  border: 1px solid #e4e4e4;
  margin-left: 15px;
  color: #e4e4e4;
  text-align: center;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  background: transparent;
  width: 76px;
  height: 30px;
  cursor: pointer;
}

.shows-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid #616161;
}

.shows-name h4 {
  color: #FFF;
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.shows-name p {
  color: #FFF;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
}

.shows-lists {
  margin-bottom: 25px;
}

.show-listsection {
  display: flex;
  margin-top: 25px;
  margin-bottom: 25px;
  flex-wrap: wrap;
}

.show-listsection .show-img {
  border-radius: 15px;
  border: 2px solid #2dd0ff;
  width: 242px;
  height: 158px;
  object-fit: cover;
}

.show-listsub-section {
  margin-right: 47px;
  margin-bottom: 25px;
}

#favorites-contens .show-listsub-section {
  margin-right: 0px;
}

.show-listsub-section .image-tags {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}

#favorites-contens .show-listsub-section .images-name {
  width: 156px;
}

.show-listsub-section .image-tags h4 {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-right: 5px;
  display: -webkit-box;
  max-width: 120px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
}

.show-listsub-section .image-tags h5 {
  color: rgba(255, 255, 255, 0.6);
  text-align: right;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: -webkit-box;
  max-width: 83px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
}

.show-listsub-section .images-name p {
  color: #fff;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 242px;
  text-align: left;
}

.show-listsub-section .image-ownername .profile-img {
  width: 18px;
  height: 18px;
  border-radius: 100px;
  object-fit: cover;
  margin-right: 5px;
}

.show-listsub-section .image-ownername {
  display: flex;
  align-items: center;
}

.show-listsub-section .image-ownername h4 {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  font-weight: 500;
}

.show-listsub-section #image-tags h5 {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}

.show-listsub-section #image-tags {
  padding-top: 5px;
}

.purchase-episode-list h6 {
  color: #FFF;
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding-bottom: 10px;
}

.purchase-episode-list {
  border-bottom: 1px solid #616161;
}

/* -----------mission-purpose -------*/

.mission-purpose p {
  font-family: "Poppins", sans-serif;
  font-size: 31px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin: 0 !important;
}

.mission-purpose {
  margin-left: 24px;
  margin-right: 24px;
  padding-top: 32px;
  padding-bottom: 31px !important;
  border-bottom: 1px solid #616161;
}

.purpose-details .mission-purpose {
  padding-top: 10px;
  margin: 25px auto;
}

footer .footer-standard .widget ul li {
  padding-right: 65px;
}

#set-list-footer {
  margin-left: 90px;
}

/*----------------- my-profile ---------------------*/

.my-profile {
  margin: 0 auto;
  margin-top: 40px;
  width: 776px;
}

.my-profile .sample-user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.50);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  min-height: 56px;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 8px 20px 8px 8px;
  margin-top: 20px;
}

.my-profile-one .my-profile-img {
  width: 776px;
  height: 328px;
  border-radius: 25px;
  object-fit: cover;
}

.sample-user-content {
  display: flex;
  align-items: center;
}

.sample-user-content .profile-info {
  padding-left: 12px;
}

.sample-user-content .profile-info h3 {
  color: #FFF;
  font-family: "Inter-Regular" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.sample-user-content .profile-info p {
  margin: 0;
  color: #BDBDBD;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-top: 4px;
}

.sample-user-content .profile-info .sample-check {
  margin-left: 43px;
}

.my-profile-one {
  position: relative;
}

.profile-image-set .profile-Ellipse {
  width: 112px;
  height: 112px;
  border-radius: 112px;
  border: 2px solid #2DD0FF;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.50);
}

.profile-image-set {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 26px;
}

.profile-image-set .image-profile {
  text-align: center;
  position: relative;
}

.profile-image-set .image-profile .profile-sampleuser .p-image {
  width: 32px;
  height: 32px;
  fill: rgba(0, 0, 0, 0.50);
  border: 2px solid #2DD0FF;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  backdrop-filter: blur(5px);
  border-radius: 32px;
  position: absolute;
  top: 80px;
  right: 23px;
  cursor: pointer;
  background: transparent;
}

.profile-image-set .image-profile .profile-sampleuser .profileedit-icon {
  margin-top: 6px;
}

.profile-image-set .user-btn {
  color: #FFF;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 18px;
  background: rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(5px);
  width: 154px;
  height: 36px;
  flex-shrink: 0;
  border: none;
  margin-top: 16px;
  cursor: pointer;
}

.my-profile-second {
  border-radius: 25px 25px 0px 0px;
  border: 0px solid #616161;
  background: #181818;
  box-shadow: 0px 0px 25px 10px rgba(0, 0, 0, 0.50);
  margin-top: 16px;
  padding: 20px;
}

.user-setting h4 {
  color: #FFF;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.user-setting .user-accordion .MuiAccordionSummary-expandIcon {
  transform: rotate(-90deg) !important;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 12px !important;
}

.user-setting .user-accordion .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(-180deg) !important;
}

.user-setting .user-accordion .MuiSvgIcon-root {
  color: white;
}

.user-setting .user-accordion .MuiAccordionSummary-expandIcon.Mui-expanded .MuiSvgIcon-root {
  color: #1891B4;
}

.user-setting .MuiPaper-root {
  background-color: transparent !important;
  border: none !important;
  border-radius: none !important;
  box-shadow: unset !important;
}

.user-setting .MuiAccordionSummary-root {
  padding: 4px 0px !important;
  min-height: fit-content !important;
}

.user-setting .MuiAccordionSummary-content {
  margin: 0 !important;
  display: flex;
  align-items: center;
}

.user-setting {
  border-radius: 15px;
  background: linear-gradient(270deg, #101010 0%, #000 100%);
  padding: 16px;
}

.user-accordionname p {
  margin: 0;
  color: #FFF;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding-left: 14px;
}

#first-accordionuser .user-accordionname {
  padding-top: 20px !important;
  padding-bottom: 15.5px !important;
}

#first-accordionuser {
  /* border-bottom: 1px solid #616161 !important;
  margin-bottom: 12px; */
}

.user-setting .MuiAccordion-root.Mui-expanded {
  margin: 0px 0;
}

#way-tointeract-section {
  margin: 16px 0;
}

.user-setting .MuiAccordionDetails-root {
  padding: 9px 0px 20px 0px;
  display: block !important;
}

.user-info-fields {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.user-info-fields .user-inputfield {
  border-radius: 20px;
  background: #252525 !important;
  width: 340px;
  height: 40px;
  color: #FFF !important;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
  border: none !important;
}

.user-info-fields .user-inputfield::placeholder {
  color: #FFF !important;
}

.user-info-fields .form-label {
  color: #FFF;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-decoration-line: underline;
  padding-bottom: 12px;
  padding-left: 12px;
}

.user-info-field .country-code {
  display: flex;
  align-items: center;
}

.user-info-field #country-code {
  width: 116px;
  margin-right: 7px;
}

.user-info-field #country-code-number {
  /* width: 217px; */
}

#user-info-field {
  margin-left: 24px;
}

.user-details .savechanges-btn .save-btn {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #FFFFFF;
  width: 140px;
  height: 32px;
  border-radius: 24px;
  border: 1px solid #616161;
  background: linear-gradient(180deg, #101010 0%, #000000 100%),
    linear-gradient(0deg, #616161, #616161);
  text-decoration: underline;
  margin-top: 14px;
  cursor: pointer;
}

.user-details .savechanges-btn {
  text-align: right;
}

.user-social-icons {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.user-socialicons-field {
  margin-left: 12px;
}

#user-socialicons {
  margin-left: 24px;
}

#social-accordion {
  padding-top: 24px !important;
}

.user-socialicons-field .sociallink-inputfield {
  width: 288px;
  height: 40px;
  border-radius: 20px;
  background: #252525 !important;
  color: #FFF;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
  border: none;
}

.user-socialicons-field .sociallink-inputfield::placeholder {
  color: #797979 !important;
  text-decoration-line: underline;
}

.user-socialicons-field .sociallink-inputfield:focus {
  border: none !important;
}

.user-socialicons-field .form-group {
  margin-bottom: 0 !important;
}

.user-social-icons .user-socialicons-subfiled {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

#social-accordion .save-btn {
  margin-top: 0;
}

.my-profile-one .my-profilemobile-img {
  display: none;
}

.user-socialicons-img .mobile-twitter {
  display: none;
}

#third-accordionvlink {
  padding-top: 11px !important;
  padding-bottom: 0px !important;
  border-top: 1px solid #616161 !important;
  margin-top: 11px;
}

#third-accordionvlink .MuiAccordionSummary-content {
  justify-content: space-between;
}

#third-accordionvlink .vlink-text {
  display: flex;
  align-items: center;
}

.toggle-switch .css-jsexje-MuiSwitch-thumb {
  /* border: 2px solid #616161 !important; */
  background-color: #fff !important;
  width: 16px;
  height: 16px;
  /* margin-top: 2.1px;
  margin-left: 2px; */
}

.toggle-switch .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #fff !important;
  padding: 0 !important;
  left: 2px;
  top: 2px;
}

.toggle-switch span.MuiSwitch-root.MuiSwitch-sizeMedium.css-julti5-MuiSwitch-root {
  width: unset !important;
  height: 20px !important;
  padding: 0 !important;
}

.toggle-switch .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase {
  padding: 0 !important;
  left: 2px;
  top: 2px;
}

.toggle-switch .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  border: 2px solid #2DD0FF !important;
  background: linear-gradient(180deg, rgba(45, 208, 255, 0.60) 0%, rgba(24, 145, 180, 0.60) 100%) !important;
}

.toggle-switch .MuiSwitch-track {
  background-color: #343434 !important;
  border-radius: 38px !important;
  border: 2px solid #4B4B4B !important;
  width: 40px !important;
  height: 20px !important;
}

.toggle-switch .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase .MuiSwitch-input {
  left: unset !important;
  width: 16px;
  margin: 0 !important;
  height: 19px;
}

.toggle-switch {
  margin-right: 34px;
}

.user-details .v-linkdetailspara {
  margin: 0;
  color: #BDBDBD;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.v-linkdetails .copyvlink {
  width: 628px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #252525;
  padding: 4px 4px 4px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 15px;
}

.v-linkdetails .copyvlink p {
  color: #FFF;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

.v-linkdetails .copyvlink .linkcopy-btn {
  width: 99px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 22px;
  border: 1px solid #2DD0FF;
  background: var(--gradient, linear-gradient(180deg, rgba(45, 208, 255, 0.60) 0%, rgba(24, 145, 180, 0.60) 100%));
  color: #FFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.14px;
  cursor: pointer;
}

.v-linkdetails .vlink-qr {
  display: flex;
  align-items: center;
  padding-bottom: 22px;
  border-bottom: 1px solid #616161;
  margin: 22px 22px 22px 7px;
}

.v-linkdetails .form-group {
  padding-bottom: 22px;
  border-bottom: 1px solid #616161;
}

.v-linkdetails .form-label {
  color: #FFF;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  padding-bottom: 8px;
  padding-left: 15px;
}

.v-linkdetails .about-field {
  /* width: 704px; */
  height: 104px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #252525 !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #fff;
  border: unset !important;
}

.social-linktoggle {
  display: flex;
  align-items: center;
  padding-top: 24px;
}

.socialdisplay-icons {
  display: flex;
  align-items: center;
  padding-left: 32px;
}

.social-displayicons {
  width: 100%;
}

.social-linktoggle .icons-set {
  margin-right: 26px;
  width: 40px;
  height: 40px;
}

.social-displaytext {
  display: flex;
  align-items: center;
}

.social-displaytext p {
  margin: 0;
  color: #FFF;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding-left: 14px;
}

.social-display {
  padding-bottom: 22px;
  border-bottom: 1px solid #616161;
  margin-bottom: 22px;
}

#vlink-accordion .savechanges-btn {
  margin-top: 10px;
}

.v-linkdetails .social-detailspara {
  color: #BDBDBD;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  padding: 8px 0;
}

.vlink-themes {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vlink-themes .select-themes {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 13px;
}

#vlink-qr-modal {
  max-width: 455px !important;
}

#vlink-qr-modal .modal-content {
  background-color: unset !important;
  border: none !important;
  border-radius: unset !important;
}

#vlink-modal {
  border-radius: 25px;
  border: 2px solid #2DD0FF;
  background: rgba(24, 145, 180, 0.60);
}

#vlink-modal .modal-header {
  border: unset !important;
  padding: 0 !important;
}

#vlink-modal .vlink-userqrset {
  margin-top: 26px;
}

#vlink-modal .sample-bg {
  border-radius: 20px;
  background: #101010;
  margin: 0 auto;
  text-align: center;
  padding: 42px 0;
  width: 280px;
}

#vlink-modal .sample-bg h4 {
  color: rgba(45, 208, 255, 0.80);
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.72px;
  text-transform: capitalize;
  padding-top: 20px;
}

#vlink-modal .sample-bg p {
  color: #999;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
  text-transform: capitalize;
  margin: 0;
  padding-top: 5px;
}

#vlink-modal .qrimg {
  width: 206.873px;
  height: 206.88px;
}

#vlink-modal .scanqr-text {
  display: flex;
  align-items: center;
  margin-bottom: 30.5px;
  justify-content: center;
  margin-top: 50px;
}

#vlink-modal .scanqr-text p {
  color: #FFF;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.6px;
  text-transform: capitalize;
  padding-left: 15px;
  margin: 0;
}

#vlink-modal .shares {
  text-align: center;
  margin-bottom: 54px;
}

#vlink-modal .share-btn {
  color: #FFF;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.14px;
  border-radius: 22px;
  border: 1px solid #2DD0FF;
  background: var(--gradient, linear-gradient(180deg, rgba(45, 208, 255, 0.60) 0%, rgba(24, 145, 180, 0.60) 100%));
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.24);
  width: 141px;
  height: 32px;
  flex-shrink: 0;
  margin: 0 auto;
}

#vlink-modal .modal-header .close {
  padding: 0;
  margin: -1rem -1rem -1rem auto;
  color: #ffffff !important;
  opacity: unset !important;
  text-shadow: unset !important;
  margin-top: 15px;
  margin-right: 27px;
}

#vlink-modal .modal-header .close span {
  font-size: 27px;
  color: #ffffff;
  font-weight: 300;
}

.select-themes .theme-img {
  width: 150px;
  height: 220px;
  border-radius: 5px;
  border: 0.5px solid #BDBDBD;
  margin-bottom: 20px;
}


/* fghfhfg */

.theme-selectbtn {
  display: block;
  position: relative;
  padding-left: 15px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: unset !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.theme-selectbtn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: fit-content !important;
}

/* Create a custom radio button */
.theme-selectbtn .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #353535;
}

/* When the radio button is checked, add a blue background */
.theme-selectbtn input:checked~.checkmark {
  background-color: transparent;
  border: 2px solid rgba(45, 208, 255, 0.60);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.theme-selectbtn .checkmark:after {
  content: "";
  /* position: absolute; */
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.theme-selectbtn input:checked~.checkmark:after {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Style the indicator (dot/circle) */
.theme-selectbtn .checkmark:after {
  top: 2.5px;
  left: 1.5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: linear-gradient(180deg, rgba(45, 208, 255, 0.60) 0%, rgba(24, 145, 180, 0.60) 100%);
}

.vlinkqr-codemodal {
  cursor: pointer;
}

.total-completebtns {
  margin-bottom: 24px;
  margin-top: 12px;
}

.total-completebtns .total-btns {
  width: 216px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid #2DD0FF;
  color: #FFF;
  text-align: center;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 8px;
  background-color: transparent !important;
  cursor: pointer;
}

.total-completebtns .completed-btns {
  width: 232px;
  height: 40px;
  margin-left: 20px;
  border-radius: 10px;
  border: 1px solid #2DD0FF;
  color: #FFF;
  text-align: center;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 8px;
  background-color: transparent !important;
  cursor: pointer;
}

.priority-dm p {
  margin: 0;
  color: #FFF;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.priority-dm {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.priority-dm .toggle-switch {
  margin-left: 100px;
}

.response-time {
  margin-top: 24px;
}

.response-time #time-dropdown #dropdown-basic {
  width: 216px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 20px !important;
  background: #252525;
  color: #FFF;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: unset !important;
  padding-left: 12px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 12px;
}

.response-time #time-dropdown .dropdown-item:focus,
.dropdown-item:hover {
  color: unset;
  text-decoration: none;
  background-color: none;
}

.response-time #time-dropdown .dropdown-menu.show {
  width: 216px;
  background: #252525;
}

.response-time #time-dropdown .dropdown-item {
  color: #FFF;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0.25rem 12px;
}

.response-time #time-dropdown:hover {
  border: unset !important;
}

.response-time #time-dropdown:focus {
  border: unset !important;
}

#time-dropdown .dropdown-toggle::after {
  width: 12px;
  margin-left: 8.255em;
  border-top: 0.41em solid;
  border-right: 0.43em solid transparent;
  border-left: 0.43em solid transparent;
}

.response-time {
  margin-top: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.responsetime p {
  color: #FFF;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  padding-bottom: 8px;
  margin: 0;
  padding-left: 12px;
}

#time-pannel {
  margin-left: 20px;
}

#pricedropdown #dropdown-basic {
  width: 92px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 22px 0px 0px 22px !important;
  background: #252525;
  color: #FFF;
  text-align: center;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: unset !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

#pricedropdown .dropdown-toggle::after {
  margin-left: 0.955em;
  width: 12px;
  border-top: 0.41em solid;
  border-right: 0.43em solid transparent;
  border-left: 0.43em solid transparent;
}

.price-dropdown {
  display: flex;
  align-items: center;
}

.price-dropdown .usd-input {
  width: 374px;
  height: 40px;
  border-radius: 0px 22px 22px 0px;
  background: #252525;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 2px;
  padding: 0 12px;
}

.price-dropdown .usd-input h4 {
  color: #797979;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.price-dropdown .usd-input h5 {
  color: #797979;
  text-align: right;
  font-family: "Inter-Regular" !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

#time-pannel h6 {
  color: #797979;
  text-align: right;
  font-family: "Inter-Regular" !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: none;
}

#priority-dm .MuiAccordionSummary-root {
  padding: 0px !important;
}

#face-toface {
  margin-top: 16px;
}

#face-toface .MuiAccordionSummary-root {
  padding: 0px !important;
}

#face-toface .user-inputfield {
  width: 216px;
  height: 40px;
  border-radius: 20px;
  background: #252525 !important;
  color: #FFF;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: none;
}

#meeting-time {
  width: 228px;
}

.meeting-time {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

#face-toface .response-time {
  margin-bottom: 0px;
}

#face-toface #price-pannel .dropdown-toggle::after {
  margin-left: 0.965em;
  width: 8px;
  border-top: 0.45em solid;
  border-right: 0.33em solid transparent;
  border-left: 0.33em solid transparent;
}

#price-pannel {
  margin-left: 20px;
}

#face-toface .price-dropdown .usd-input h4 {
  color: #FBFBFB;
}

#face-toface .add-slotsbtn {
  width: 152px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 22px;
  background: rgba(97, 97, 97, 0.50);
  color: #FFF;
  text-align: center;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: none;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#face-toface .vec-adslot {
  margin-right: 10px;
  width: 12px;
  height: 12px;
}

#face-toface .priority-dm {
  margin-top: 20px;
}

#face-toface .video-calltext h6 {
  color: #FFF;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: 16px;
  padding-bottom: 10px;
  padding-top: 16px;
}

.video-calltext .usd-input {
  width: 468px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 20px 22px 22px 20px;
  background: #252525;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  margin-bottom: 6px;
}

.video-calltext .usd-input h4 {
  color: #797979;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.video-calltext .usd-input h5 {
  color: #797979;
  text-align: right;
  font-family: "Inter-Regular" !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

#face-toface .priority-dm .toggle-switch {
  margin-left: 179px;
  margin-right: 0;
}

#face-toface #face-twoswitch {
  margin-left: 24px;
}

#face-toface #viedeo-callswitch {
  margin-left: 48px;
}

#face-toface .save-btn {
  text-decoration: unset;
  margin-top: 11px;
  margin-bottom: 20px;
}

.faceto-schedule {
  padding-top: 26px;
  padding-bottom: 28px;
  border-top: 1px solid #616161;
  border-bottom: 1px solid #616161;
  margin-bottom: 21.5px;
}

.faceto-schedule .faceto-scheduletext {
  margin-bottom: 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.faceto-schedule .faceto-scheduletext h4 {
  color: #FFF;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.faceto-schedule .faceto-scheduletext p {
  margin: 0;
  color: #2DD0FF;
  text-align: center;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.calender-schedule {
  display: flex;
}

.schedule-charge-details #time-dropdown #dropdown-basic {
  width: 348px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 20px !important;
  background: #252525;
  color: #FFF;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: unset !important;
  padding-left: 12px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 12px;
}

.responsetime #time-dropdown .dropdown-toggle::after {
  margin-left: 0.255em;
}

.schedule-charge-details h6 {
  color: #BDBDBD;
  text-align: center;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 348px;
  padding-top: 12px;
}

.schedule-tiomezone {
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 28px;
}

.schedule-tiomezone #time-dropdown #dropdown-basic {
  width: 154px;
}

.schedule-tiomezone h2 {
  color: #BDBDBD;
  text-align: center;
  font-family: "Inter-Regular" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 0 8px;
}

.schedule-tiomezone #time-dropdown .dropdown-toggle::after {
  margin-left: 0;
}

#calender-schedule-picker {
  margin-left: 40px;
  width: 316px;
}

#calender-schedule-picker .react-calendar__navigation {
  border-radius: 10px;
  border: 1px solid #2DD0FF;
  background: linear-gradient(180deg, rgba(45, 208, 255, 0.50) 0%, rgba(24, 145, 180, 0.50) 100%);
  height: 40px;
  margin-bottom: 12px;
}

#calender-schedule-picker .react-calendar {
  width: 316px;
  background: transparent;
  border: unset;
  font-family: "Inter-Regular" !important;
  line-height: 1.125em;
  color: white;
}

#calender-schedule-picker button.react-calendar__tile.react-calendar__month-view__days__day {
  color: white;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  border-radius: 5px;
  background: #252525;
  width: 36.571px;
  max-width: 36px;
  margin-left: 9px;
  height: 28px;
  padding: 0;
  margin-bottom: 8px;
}

#calender-schedule-picker .react-calendar__month-view__weekdays__weekday {
  padding: 0;
  margin-bottom: 12px;
  line-height: 19px;
  max-width: 36px;
  margin-left: 9px;
  text-transform: capitalize;
}

#calender-schedule-picker button.react-calendar__tile.react-calendar__tile--now.react-calendar__month-view__days__day {
  border-radius: 5px;
  border: 1px solid #2DD0FF;
  background: var(--gradient, linear-gradient(180deg, rgba(45, 208, 255, 0.60) 0%, rgba(24, 145, 180, 0.60) 100%));
}

#calender-schedule-picker .react-calendar__navigation button {
  color: white;
  background-color: transparent;
  text-transform: uppercase;
}

#calender-schedule-picker button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
  background: transparent;
  color: #BDBDBD;
}

#calender-schedule-picker abbr[data-original-title],
abbr[title] {
  text-decoration: unset;
  text-decoration: unset;
  font-size: 14px;
}

#calender-schedule-picker .react-calendar__month-view__weekNumbers .react-calendar__tile {
  font-weight: unset;
}

#calender-schedule-picker button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

#calender-schedule-picker button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

#calender-schedule-picker button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  font-size: 24px;
  padding: 0;
}

#calender-schedule-picker button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  font-size: 24px;
  padding: 0;
}

#pricedropdown .dropdown-menu.show {
  width: 92px;
  background: #252525;
}

#pricedropdown .dropdown-item {
  color: #FFF;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0.25rem 12px;
}

#pricedropdown .dropdown-item:focus,
.dropdown-item:hover {
  color: unset;
  text-decoration: none;
  background-color: none;
}

#time-dropdown .dropdown-menu.show {
  width: 348px;
  background: #252525;
}

.schedule-tiomezone #time-dropdown .dropdown-menu.show {
  width: 154px;
  background: #252525;
}

#time-dropdown .dropdown-item:focus,
.dropdown-item:hover {
  /* color: unset !important; */
  text-decoration: none !important;
  background-color: none !important;
  background: unset !important;
}

#time-dropdown .dropdown-item {
  color: #FFF;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0.25rem 12px;
}

.response-time #time-dropdown #dropdown-basic:focus,
.response-time #time-dropdown #dropdown-basic:hover {
  border: none !important;
  box-shadow: unset !important;
}

#pricedropdown #dropdown-basic:focus,
#pricedropdown #dropdown-basic:hover {
  border: none !important;
  box-shadow: unset !important;
}

.schedule-charge-details #time-dropdown #dropdown-basic:focus,
.schedule-charge-details #time-dropdown #dropdown-basic:hover {
  box-shadow: unset !important;
  border: none !important;
}

.options-text {
  display: flex;
  align-items: center;
}

.options-text h4 {
  color: #FFF;
  font-family: "Inter-Regular" !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding-right: 16px;
}

.range-datetext p {
  margin: 0;
  padding-top: 10px;
  color: #BDBDBD;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.existing-schedule-btn {
  display: flex;
  align-items: center;
  margin-left: 6px;
}

.existing-schedule-btn h4 {
  border-radius: 15px;
  border: 1px solid #2DD0FF;
  color: rgba(255, 255, 255, 0.80);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Inter-Regular" !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  margin-left: 10px;
  width: 219px;
  text-align: center;
  padding: 10px;
}

.existing-schedule-btn .custom-hours {
  width: 170px;
}

.existing-schedule {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

#existing-schedule-btn {
  margin-left: 20px;
}

.weekly-options {
  padding-left: 26px;
}

.weekly-options .weekly-text {
  color: #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Inter-Regular" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 24px;
}

.existing-schedule-btn h5 {
  color: #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Inter-Regular" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  margin-left: 10px;
  width: 36px;
}

.existing-schedule-btn p {
  color: rgba(255, 255, 255, 0.80);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Inter-Regular" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 0px;
  padding-left: 100px;
}

.weekly-options .existing-schedule-btn {
  margin-left: 0;
  margin-bottom: 25px;
}

.weekly-options .existing-schedule-btn .theme-selectbtn {
  height: 3px;
}

.advanced-options .theme-selectbtn .checkmark {
  border: 1px solid #1891B4;
}

.advanced-options .theme-selectbtn input:checked~.checkmark {
  border: 1px solid #1891B4;
}

.existing-schedule-btn .delete-schedule-btn {
  color: rgba(255, 255, 255, 0.80);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Inter-Regular" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  border-radius: 10px;
  border: 1px solid #2DD0FF;
  padding: 10px;
  background-color: transparent;
}

.existing-schedule-btn #delete-schedule-btn {
  margin-left: 100px;
}

.existing-schedule-btn .space {
  color: rgba(255, 255, 255, 0.80);
  padding: 0 7px;
}

.existing-schedule-btn .delete-scheduleimg {
  margin-left: 40px;
  cursor: pointer;
}

.advanced-options .save-btn {
  margin-bottom: 0 !important;
}

#Experiences {
  margin-top: 16px;
  margin-bottom: 28px;
}

#Experiences .MuiAccordionDetails-root {
  padding: 9px 0px 0px 0px;
}

#Experiences p {
  color: #BDBDBD;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 19px;
  padding-top: 8px;
}

#Experiences .Customize-btn {
  border-radius: 22px;
  border: 1px solid #2DD0FF !important;
  background: var(--gradient, linear-gradient(180deg, rgba(45, 208, 255, 0.60) 0%, rgba(24, 145, 180, 0.60) 100%));
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.24);
  width: 156px;
  height: 32px;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.14px;
  border: unset;
  cursor: pointer;
  margin-bottom: 5px;
}

#Experiences .experience-texts {
  display: flex;
  align-items: center;
}

#Experiences .experience-texts h4 {
  color: #FFF;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-right: 43px;
  margin-left: 8px;
}

#Experiences .MuiAccordionSummary-root {
  padding: 0px 0px !important;
}

#mobile-icons-links {
  display: none;
}

#mobile-response-metting {
  display: none;
}

#priority-dm .user-details .savechanges-btn .save-btn {
  text-decoration: unset;
}

#favorites-contens .show-listsub-section .show-img-new {
  height: 232px;
  width: 156px;
  border: 1px solid #1891b4;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px #00000080;
  object-fit: cover;
}

.purchase-container #favorites-contens .swiper-button-prev {
  left: -50px !important;
  top: 35% !important;
  bottom: 0 !important;
  background: unset !important;
}

.purchase-container #favorites-contens .swiper-button-next {
  right: -50px !important;
  top: 35% !important;
  bottom: 0 !important;
  background: unset !important;
}

.purchase-container #favorites-contens {
  position: relative;
}

#billing-address-modal\ header-mobile-drawer .modal-content {
  background-color: unset !important;
  border: none !important;
  border-radius: unset !important;
}

#billing-address-modal\ header-mobile-drawer .modal-header {
  padding: 0 !important;
}

#billing-address-modal\ header-mobile-drawer .customer-billing .modal-header h4 {
  display: block;
}

#billing-address-modal\ header-mobile-drawer .footer-icon {
  display: none;
}

#billing-address-modal\ header-mobile-drawer .customer-billing h4 {
  display: none;
}

div#billing-address-modal\ header-mobile-drawer {
  max-width: 524px !important;
}

.show-listsection .merch-img {
  border-radius: 10px;
  border: 2px solid #2dd0ff;
  width: 157px;
  height: 157px;
  object-fit: cover;
}

#show-merchsub-section {
  margin-right: 37px;
  margin-bottom: 25px;
}

#show-merchsub-section .images-name p {
  max-width: 157px;
}

#show-merchsub-section .image-ownername h4 {
  max-width: 75px;
  font-size: 10px;
}

#show-merchsub-section #image-tags h5 {
  max-width: 50px;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.60);
}

#show-merchsub-section .image-ownername .profile-img {
  width: 15px;
  height: 15px;
}

.Subscription-listsection {
  width: 240px;
  height: 224px;
  border: 1px solid #1891B4;
  background: rgba(15, 15, 15, 0.70);
  border-radius: 20px;
  position: relative;
  margin-right: 50px;
  margin-bottom: 25px;
}

.Subscription-list {
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
}

.Subscription-listsection .Subscription-img {
  border-radius: 20px 20px 0px 0px;
  object-fit: cover;
  width: 100%;
  height: 90px;
}

.Subscription-details {
  text-align: center;
}

.Subscription-image {
  border-radius: 100px;
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.Subscription-list .Subscription-details {
  text-align: center;
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  padding: 0 5px;
}

.Subscription-details h4 {
  color: #FFF;
  text-align: center;
  font-family: "Inter-Regular" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  padding-top: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.Subscription-details h5 {
  color: #BDBDBD;
  text-align: center;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding-top: 4px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.Subscription-details p {
  color: #2DD0FF;
  text-shadow: 0px 0px 10px #000;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  padding-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.remember-modal {
  background: linear-gradient(180deg, #101010 0%, #000000 100%),
    linear-gradient(0deg, #616161, #616161);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 25px;
  border: 1px solid #616161;
  width: 100%;
  text-align: center;
  max-width: 25rem;
  padding: 20px 20px 35px 20px;
}

.set-verify-btns {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.remember-modal h4 {
  border-bottom: 1px solid #616161;
  font-family: "Roboto-Regular";
  font-size: 15px;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.remember-modal p {
  font-family: "Inter-Regular" !important;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #616161;
}

.set-verify-btns .verify-remember-btn {
  border: 1px solid #2dd0ff !important;
  background: var(--gradient,
      linear-gradient(180deg,
        rgba(45, 208, 255, 0.6) 0%,
        rgba(24, 145, 180, 0.6) 100%));
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  width: 160px;
  height: 32px;
  border-radius: 25px;
}

.set-verify-btns .verify-cancel-btn {
  background: #616161;
  border: none !important;
  font-family: "Inter-Regular" !important;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  width: 160px;
  height: 32px;
  border-radius: 25px;
}

/*----------------- Responsive----------- */

@media only screen and (max-width: 2000px) {
  #view-all-podcast {
    flex: 0 0 11.11%;
    max-width: 11.11%;
  }
}

@media only screen and (max-width: 1800px) {
  #view-all-podcast {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }

  #view-all-movie1 {
    flex: 0 0 16.85%;
    max-width: 16.66285%;
  }
}

@media only screen and (max-width: 1640px) {
  /* .d-trending-info {
        padding: 60px 0;
    } */

  .d-title-txt-one {
    font-size: 30px;
    padding-bottom: 12px;
  }

  .d-txt-one {
    height: 77px !important;
    font-size: 15px;
    line-height: 25px;
  }

  .tranding-block2 {
    background-size: contain !important;
    background-position: center;
    background-repeat: no-repeat;
  }

  .d-set-featured-video,
  .d-set-featured-channel-photo {
    padding-top: 160px;
  }

  .ratings span {
    font-size: 19px;
  }

  .show-movie-tab p {
    width: 73%;
  }

  .comment-para p {
    width: 80%;
  }

  .comment-review-right {
    height: 1405px;
  }

  .comment-scrolbar {
    height: 1294px;
  }

  .podcast-text {
    font-size: 68px;
    line-height: 82px;
  }

  .show-Listen-btn {
    width: 239px;
  }

  .show-Subscribe-btn {
    width: 239px;
  }

  .podcast-sec-right1 {
    height: 500px;
    width: 500px;
  }

  #set-watchlist-row .col-1-5 {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }

  #episode-watchlist {
    margin-top: 2px;
  }

  #view-all-movie {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  #viewall-images {
    width: 100%;
  }

  #watchlist-img-set {
    width: 100%;
  }

  #view-all-podcast {
    flex: 0 0 14.2%;
    max-width: 14.2%;
  }

  .watchlist-container .epi-desc.p-3 {
    padding: 8px 16px !important;
    width: 100%;
  }

  #view-all-movie1 {
    flex: 0 0 19.666667%;
    max-width: 19.666667%;
  }
}

@media only screen and (max-width: 1400px) {
  .d-ratings-and-season {
    padding-bottom: 0;
  }

  .ratings span {
    font-size: 17px;
    line-height: 22px;
    padding-top: 7px;
  }

  .d-season {
    font-size: 17px;
    line-height: 22px;
    padding-top: 28px;
  }

  .comment-review-right {
    height: 1480px;
  }

  .comment-scrolbar {
    height: 1368px;
  }

  #watchlist-episodename {
    margin-bottom: 12px !important;
  }

  #episode-watchlist {
    margin-top: 4px;
  }

  #watchlist-season {
    font-size: 16px;
  }

  #view-all-podcast {
    flex: 0 0 16.2%;
    max-width: 16.2%;
  }
}

@media only screen and (max-width: 1366px) {
  .para-window {
    width: 90% !important;
  }

  .ratings span {
    font-size: 11px;
    line-height: 18px;
  }

  .d-season {
    font-size: 11px;
    line-height: 18px;
    padding-top: 27px;
  }

  .d-set-featured-video,
  .d-set-featured-channel-photo {
    padding-top: 200px;
  }

  .ratings span {
    padding-top: 0px;
  }
}

@media only screen and (max-width: 1360px) {
  .comment-sec-new {
    padding-left: 12px;
  }

  .comment-para p {
    width: 100%;
  }

  #comment-para p {
    width: 100%;
  }

  .comment-para {
    padding-left: 0px;
    margin-top: 10px;
  }

  .review-report1 {
    padding-top: 10px;
  }

  .comment-new-sec {
    padding-top: 10px;
  }

  .comment-review-right {
    height: 1364px;
  }

  .comment-scrolbar {
    height: 1252px;
  }

  .podcast-text {
    font-size: 55px;
    line-height: 64px;
  }

  .set-btns-now {
    margin-right: 22px;
  }

  #view-all-podcast {
    flex: 0 0 16.2%;
    max-width: 16.2%;
  }

  .purchase-container {
    width: 90%;
  }

}

@media only screen and (max-width: 1200px) {
  .d-set-next {
    right: -23px !important;
  }

  .d-set-prev {
    left: -23px !important;
  }

  #tech-section {
    width: 90%;
    margin: 0 auto;
  }

  .tech-video {
    height: 285px;
    border-radius: 30px;
    width: auto !important;
  }

  #slider-ratting {
    margin-right: 6px !important;
  }

  .d-season {
    font-size: 12px;
    line-height: 18px;
    padding-top: 16px;
  }

  .ratings span {
    font-size: 12px;
    line-height: 18px;
  }

  .d-ratings-and-season {
    display: block !important;
    padding-bottom: 10px;
  }

  .d-season1-series {
    font-size: 50px;
  }

  .d-set-featured-video,
  .d-set-featured-channel-photo {
    padding-top: 228px;
  }

  .world-edu-video {
    object-fit: cover !important;
    width: 100% !important;
    height: 438px !important;
  }

  .world-edu-event {
    height: auto !important;
    width: 100% !important;
  }

  .show-movie-tabs {
    padding: 40px 50px;
  }

  .view-movie-details {
    left: 40px;
    top: 30px;
  }

  .m-set-prev {
    left: -39px !important;
  }

  .m-set-next {
    right: -39px !important;
  }

  .sm-set-prev {
    left: -39px !important;
  }

  .sm-set-next {
    right: -39px !important;
  }

  .p-set-prev {
    left: -39px !important;
  }

  .p-set-next {
    right: -16.5% !important;
  }

  .movie-bgimg {
    height: 504px;
  }

  #view-series-details {
    top: 12px;
  }

  .main-content.extra-tabs-set {
    width: 88%;
  }

  video.movie-bgvideo {
    height: 504px;
  }

  .world-event-stock {
    width: 97%;
  }

  .set-section-stock {
    padding-right: 12px;
  }

  .set-section-stock1 {
    padding-right: 12px;
  }

  .show-podcast-tabs {
    padding: 40px 50px;
  }

  .podcast-footer {
    padding: 40px 50px;
  }

  .podcast-episodes {
    padding-top: 15px;
  }

  .set-btns-now {
    padding-top: 40px;
    display: block;
  }

  .podcast-text {
    font-size: 40px;
    line-height: 50px;
  }

  .podcast-para {
    font-size: 20px;
    line-height: 30px;
  }

  .show-Subscribe-btn {
    margin-left: 0;
    margin-top: 19px;
  }

  .b-set-prev {
    left: -23px !important;
  }

  .b-set-next {
    right: -23px !important;
  }

  #set-watchlist-row .col-1-5 {
    flex: 0 0 33.33% !important;
    max-width: 33.33% !important;
  }

  .watchlist-container {
    padding: 40px 50px;
  }

  #view-all-movie {
    flex: 0 0 19.666667%;
    max-width: 19.666667%;
  }

  #view-all-podcast {
    flex: 0 0 19.666667%;
    max-width: 19.666667%;
  }

  #view-all-movie1 {
    flex: 0 0 24.666667%;
    max-width: 24.666667%;
  }

  .video-mobile {
    padding: 0 12px !important;
  }

  .footer-podscast {
    height: 437px;
  }

  /* 
  .show-listsection {
    margin-top: 0px;
  } */
}

@media only screen and (max-width: 1140px) {
  .comment-review-right {
    height: 1439px;
  }

  .comment-scrolbar {
    height: 1326px;
  }
}

@media only screen and (max-width: 1100px) {
  .channel-registration {
    width: 90%;
    padding-left: 50px;
    padding-right: 50px;
  }

  .set-domain-name {
    width: 100%;
  }

  .network-timeline {
    width: 95%;
  }

  .basic-channel-field {
    width: 100% !important;
  }

  .basic-channel-field1 {
    width: 100% !important;
  }

  .select-channel-subtype {
    width: 100% !important;
  }

  .select-channel-type {
    width: 100% !important;
  }

  .movie-tab-item {
    padding-right: 33px !important;
  }

  .details-action-content p {
    padding-right: 80px;
  }

  .detail-tab-content {
    padding-left: 31px;
  }

  .details-of-movie p {
    width: 272px;
    padding-left: 12px;
  }

  .show-movie-tab {
    padding-left: 31px;
  }

  #set-list-footer {
    margin-top: 20px;
  }

  footer .footer-standard .widget ul li {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 1000px) {
  .comment-review-right {
    height: 1614px;
  }

  .comment-scrolbar {
    height: 1501px;
  }

  #set-privacy-policy {
    width: 94%;
  }

  #create-channels .custom-control-inline {
    width: 50% !important;
  }

  #m-channel-newprofile #create-channels {
    width: 100%;
    padding: 40px 30px !important;
  }

  #payment-modal-new #create-channels {
    width: 100%;
    padding: 40px 30px !important;
  }

  #create-channels .domain-suggestions {
    width: 100%;
  }

  #create-channels .set-domain-name1 {
    width: 60%;
  }

  .channel-select-setup {
    width: 50%;
  }

  #create-channels .new-channel-select {
    width: 98% !important;
  }

  #new-channel-row #set-sign-in1 {
    margin: 0 20px;
  }

  #payment-modal-new #set-sign-in1 {
    margin: 0 20px;
  }
}

@media only screen and (max-width: 991px) {
  .edu-initi {
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    padding-top: 4px;
    margin-bottom: 8px !important;
  }

  #home-slider .slick-bg {
    height: auto !important;
  }

  .d-video-banner {
    position: unset;
  }

  .tech-video {
    height: 185px;
    width: 310px !important;
    margin: 0 auto;
  }

  .tech-borders {
    text-align: right;
  }

  .d-season {
    font-size: 12px;
    line-height: 18px;
    padding-top: 10px !important;
  }

  .ratings span {
    font-size: 12px;
    line-height: 18px;
  }

  #slider-ratting {
    margin-right: 5px !important;
  }

  .events-title {
    font-size: 16px !important;
    line-height: 20px !important;
  }

  .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin-bottom: 0;
  }

  .d-block-description {
    top: -84px;
  }

  .trending-content {
    padding: 0px 35px;
  }

  .d-season1-series {
    font-size: 68px;
  }

  .d-set-featured-video,
  .d-set-featured-channel-photo {
    padding-top: 249px;
  }

  .network-timeline {
    width: 94%;
  }

  .set-domain-name {
    width: 100%;
  }

  .movie-tab-item {
    font-size: 21px;
    line-height: 28px;
    padding-right: 30px !important;
  }

  .details-of-movie h4 {
    width: 90px;
  }

  .details-of-movie p {
    width: 199px;
    padding-left: 12px;
  }

  .details-subtitle h4 {
    font-size: 16px;
    line-height: 29px;
  }

  .details-subtitle p {
    font-size: 16px;
    line-height: 29px;
  }

  .details-action-content p {
    padding-right: 40px;
  }

  .show-subtitle p {
    font-size: 16px;
    line-height: 29px;
  }

  .show-subtitle h4 {
    font-size: 16px;
    line-height: 29px;
  }

  .show-movie-tab p {
    width: 86%;
  }

  .set-progress-bg {
    width: 136px !important;
  }

  .write-review-btn {
    width: 100%;
  }

  .review-avg-rating {
    padding-left: 13px;
  }

  .sorted-top-review p {
    font-size: 16px;
  }

  .review-tab-description {
    margin-top: 21px;
    padding: 18px;
  }

  .verified-ratings {
    display: block;
  }

  .verified-ratings p {
    padding-left: 0;
    padding-top: 5px;
  }

  .view-tab p {
    padding-left: 0;
    padding-top: 5px;
  }

  .view-tab {
    display: block;
  }

  .movie-bgimg {
    height: auto;
  }

  .view-movie-details {
    margin-left: 40px;
    position: unset;
    margin-top: 10px;
  }

  .show-movie-details {
    padding-top: 60px;
  }

  .comment-review-right {
    display: none;
  }

  .comment-review-left {
    margin-right: 0px;
  }

  .review-rating-calc p {
    padding-left: 8px;
  }

  #series-season {
    padding-right: 30px;
  }

  #pills-season-tab {
    padding-right: 10px !important;
  }

  .movie-tab-item span {
    font-size: 21px;
    line-height: 28px;
  }

  .main-content.extra-tabs-set {
    width: 80%;
  }

  .remaining-photo {
    width: 17%;
  }

  .p-set-next {
    right: -29.5% !important;
  }

  video.movie-bgvideo {
    height: auto;
  }

  .iq-main-slider {
    padding-top: 77px !important;
  }

  #set-list-footer {
    margin-left: 80px;
  }

  footer .footer-standard .widget ul li {
    padding-right: 60px;
  }

  #footer-container {
    width: 90% !important;
  }

  header .navbar ul li a,
  header .navbar ul li.menu-item a {
    text-align: center !important;
  }

  .set-profile-info h6 {
    font-size: 14px;
    line-height: 18px;
  }

  .show-podcast-details {
    padding-top: 60px;
  }

  .podcast-sec {
    flex-direction: column-reverse;
  }

  .podcast-text {
    font-size: 35px;
    line-height: 40px;
    width: 100%;
  }

  .podcast-para {
    font-size: 20px;
    line-height: 30px;
    width: 100%;
    padding-top: 15px;
  }

  .podcast-center-right {
    margin-left: 50px;
  }

  .set-btns-now {
    padding-top: 35px;
  }

  .podcast-sec-left {
    padding-top: 30px;
  }

  .podcast-footer-list1 img {
    width: 75px;
    height: auto;
  }

  .podcast-sec-left {
    width: 100%;
  }

  .podcast-sec-right {
    text-align: left;
    width: 100%;
  }

  .show-Listen-btn {
    width: 321px;
  }

  .show-Subscribe-btn {
    width: 321px;
    margin-left: 17px;
    margin-top: 0;
  }

  .set-btns-now {
    display: flex;
  }

  .set-play-img {
    display: none;
  }

  .set-btns-now {
    margin-right: 0px;
  }

  .podcast-sec-right1 {
    height: 500px;
    width: 500px;
    border-radius: 12px;
  }

  #header-container {
    padding: 0 45px;
  }

  /* #d-header {
        height: auto !important;
    } */

  .main-header {
    padding: 0 !important;
  }

  .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    background: #2dd0ff !important;
  }

  .footer-podscast {
    height: 460px;
  }

  #viewall-row {
    padding: 0 !important;
  }

  #view-all-movie {
    flex: 0 0 24.666667%;
    max-width: 24.666667%;
  }

  #view-all-podcast {
    flex: 0 0 20%;
    max-width: 20%;
  }

  #view-all-movie1 {
    flex: 0 0 32.666667%;
    max-width: 32.666667%;
  }

  div#right-sidetoggle {
    display: flex !important;
  }

  .channel-registration-dataplan {
    overflow-x: scroll;
    max-width: 86vw;
  }

  .payments-option {
    flex-direction: column;
  }

  .payment-method {
    width: 100%;
    min-height: 292px;
    height: auto;
    margin-bottom: 24px;
    padding-bottom: 0px;
  }

  .payments-monthly {
    padding: 15.5px 22px;
  }

  .payments-order {
    margin-left: 0px;
    width: 100%;
    /* min-height: 536px; */
    height: auto;
  }

  .setup-monthly-pay {
    width: 100%;
    height: 56px;
    padding: 8px 12px;
  }

  .setup-monthly-price {
    width: 100%;
    height: 56px;
    padding: 8px 12px;
  }

  .setup-monthly-payment {
    width: 100%;
    height: 56px;
    padding: 8px 12px;
  }

  .discount-code {
    width: 100% !important;
    height: 40px !important;
    margin-top: 40px;
  }

  .place-order-btn {
    margin-bottom: 12px;
    width: 100%;
    height: 44px;
  }

  .setup-monthly-autopay .ivector-mobile {
    display: unset;
    background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
    border-radius: 100%;
  }

  .setup-monthly-autopay .ivector {
    display: none;
  }

  .setup-monthly-autopay .ivector svg {
    display: none;
  }

  #monthlyautopay-tooltip-mobile {
    display: unset;
  }

  #monthlyautopay-tooltip-mobile .tooltip-inner {
    margin-bottom: 8px;
  }

  .purchase-container #favorites-contens .swiper-button-prev {
    left: -42px !important;
  }

  .purchase-container #favorites-contens .swiper-button-next {
    right: -42px !important;
  }
}

@media only screen and (max-width: 840px) {
  /* .d-trending-info {
        display: none;
    } */

  .trending-button {
    display: block !important;
  }

  .series-btn {
    font-size: 15px;
    font-weight: 700;
    line-height: 33px;
    height: 47px;
    width: 141px;
    /* margin-top: 90% !important; */
  }

  /* .d-season1-series {
    .series-btn1 {
        font-size: 15px;
        font-weight: 700;
        line-height: 33px;
        height: 47px;
        width: 141px;
    }

    .d-season1-series {
        display: none !important;
    } */
  .d-season1-series {
    font-size: 55px;
  }

  .d-tvchannel-img {
    width: 346px !important;
    height: 230px;
  }

  #view-all-podcast {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .my-profile {
    width: 90%;
  }

  .my-profile-one .my-profile-img {
    width: 100%;
  }

  .user-info-field {
    width: 50%;
  }

  .user-info-fields .user-inputfield {
    width: 100%;
    height: 44px;
  }

  .user-info-field #country-code-number {
    width: 100%;
  }

  .user-socialicons-sub {
    width: 50%;
  }

  .user-socialicons-field .sociallink-inputfield {
    width: 100%;
    height: 44px;
  }

  .user-socialicons-img svg {
    height: 44px;
    width: 44px;
  }

  #social-accordion {
    padding-top: 42px !important;
  }

  .user-socialicons-img .web-twitter {
    display: none;
  }

  .user-socialicons-img .mobile-twitter {
    display: block;
  }

  .vlink-themes {
    flex-wrap: wrap;
  }

  .v-linkdetails .copyvlink {
    width: 90%;
  }

  .v-linkdetails .about-field {
    width: 100%;
  }

  .vlink-themes {
    gap: 25px;
    justify-content: unset;
  }

  .price-dropdown .usd-input {
    width: 100%;
  }

  #time-pannel {
    margin-left: 15px;
    width: 100%;
  }

  .calender-schedule {
    flex-direction: column;
    align-items: center;
  }

  #calender-schedule-picker {
    margin-left: 0;
    margin-top: 24px;
    width: 358px;
  }

  #calender-schedule-picker .react-calendar {
    width: 358px;
  }

  .schedule-charge-details {
    width: 100%;
  }

  .schedule-charge-details #time-dropdown #dropdown-basic {
    width: 100%;
    height: 44px;
  }

  .schedule-charge-details #time-dropdown {
    width: 100%;
  }

  .schedule-tiomezone h2 {
    padding: 0px 16px;
  }

  .schedule-charge-details h6 {
    text-align: left;
    width: 100%;
    padding-top: 20px;
  }

  #time-dropdown .dropdown-menu.show {
    width: 100%;
  }

  .schedule-tiomezone #time-dropdown .dropdown-menu.show {
    width: 100%;
  }

  #calender-schedule-picker .react-calendar__month-view__weekdays__weekday {
    margin-left: 13px;
  }

  #calender-schedule-picker button.react-calendar__tile.react-calendar__month-view__days__day {
    margin-left: 9.5px;
    width: 40px;
    max-width: 40px;
    height: 32px;
  }

  #price-pannel {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .d-veuitbgimg {
    background-size: 70% !important;
  }

  .d-title-feature {
    font-size: 40px;
    line-height: 50px;
  }

  .parallax-window {
    padding: 24px 0 32px 0;
  }

  .exclusive-event {
    height: 410px !important;
  }

  .event-video {
    height: 410px !important;
  }

  #d-veuitbgimg {
    background-image: none !important;
  }

  /* div#d-container\ main-content {
        padding-left: 0;
        padding-right: 0;
    } */

  .veuit-technologies {
    padding-bottom: 62px;
    padding-top: 18px;
  }

  .detail-tab-content {
    padding-left: 31px;
  }

  .main-content {
    padding-top: 28px !important;
  }

  #set-brand-con {
    display: block !important;
  }

  .show-movie-tab {
    padding-left: 31px;
  }

  .tech-borders {
    text-align: center !important;
  }

  .tech-videos {
    text-align: center;
  }

  /* #series-rating {
        height: 10.1118803024292px;
        width: 10.1118803024292px;
        background: linear-gradient(0deg, #2DD0FF, #2DD0FF),
            linear-gradient(180deg, #2DD0FF 0%, #1891B4 100%);
        border: 0.3px solid #2DD0FF
    } */

  /* .d-title-feature-2 {
        padding-top: 134px !important;
    } */

  /* #series-rating-mob {
        display: block;
    } */

  /* #series-rating {
        display: none;
    } */

  .ratings span {
    letter-spacing: 0.3px;
    font-size: 10px;
    padding-top: 0 !important;
  }

  .d-season {
    letter-spacing: 0.3px;
    font-size: 11px;
    /* line-height: 11px; */
    padding-top: 8px;
  }

  .tranding-block2 {
    height: 410px;
  }

  .tranding-block2 .d-set-featured {
    padding-top: 200px;
  }

  /* .series-btn {
        margin-top: 86% !important;
    } */

  .d-set-prev {
    left: 15px !important;
  }

  .d-set-next {
    right: 15px !important;
  }

  .d-trending-info {
    padding: 50px 0 0 0;
  }

  .d-season1-series {
    font-size: 35px;
  }

  .d-title-txt-one {
    font-size: 22px;
  }

  .d-txt-one {
    font-size: 12px;
    line-height: 19px;
  }

  .d-set-featured-video,
  .d-set-featured-channel-photo {
    padding-top: 302px;
  }

  .events-title {
    width: 70%;
    margin: 0 auto;
  }

  .d-parallax-window {
    padding: 0;
  }

  .d-para-window {
    padding-top: 20px;
    padding-bottom: 22px;
  }

  .d-parallax-img {
    width: 350px;
    margin-top: 25px;
  }

  .timeline-text {
    font-size: 15px;
    line-height: 18px;
    top: 33px;
  }

  .show-movie-tabs {
    padding: 28px 15px 41px 15px;
  }

  .view-movie-details {
    margin-left: 9px;
    margin-top: 6px;
    margin-left: 15px;
    margin-right: 15px;
  }

  #details-subtitle-mob {
    display: block;
    padding-top: 10px;
  }

  #details-subtitle-mob p {
    text-align: center;
  }

  #details-subtitle-mob h4 {
    text-align: center;
  }

  #details-subtitle {
    display: none;
  }

  .details-share-mob {
    display: block;
  }

  .show-tab-details {
    display: block;
  }

  .details-action-content p {
    overflow: unset;
    text-overflow: unset;
    display: unset;
    padding-right: 0;
    font-size: 14px;
    line-height: 31px;
  }

  .detail-tab-content {
    padding-left: 19px;
  }

  .show-detail-tab {
    margin-right: 13px;
    float: left;
  }

  .detail-tab-content {
    padding-left: 12px;
  }

  .show-tabs-icon {
    display: none;
  }

  .details-contents-button-mob {
    margin-top: 11px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .details-contents-button {
    display: none;
  }

  .drama-btn {
    margin-left: 0px;
    margin-top: 21px;
  }

  .comedy-btn {
    margin-left: 0px;
    margin-top: 21px;
  }

  .top-cast {
    clear: left;
    padding-top: 5px;
  }

  .m-set-prev {
    left: -2px !important;
  }

  .m-set-next {
    right: 2px !important;
  }

  .sm-set-next {
    right: -3px !important;
  }

  .sm-set-prev {
    left: -3px !important;
  }

  .movie-tab-item {
    font-size: 17px;
    line-height: 31px;
  }

  .details-of-movie h4 {
    width: 116px;
  }

  .details-of-movie p {
    width: 100%;
    padding-left: 0;
    overflow: unset;
    text-overflow: unset;
    display: unset;
    white-space: unset;
  }

  .movie-starring-content p {
    width: auto;
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
  }

  .movie-starrings p {
    width: auto;
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
  }

  .movie-description p {
    width: auto;
    font-size: 14px;
    padding-top: 10px;
  }

  .show-detailtab-img {
    height: 217px;
    width: 147px;
  }

  .movies-reviews {
    display: none;
  }

  .show-like-details {
    padding-top: 13px;
  }

  .movie-name {
    font-size: 45px;
    line-height: 49px;
  }

  .show-episode {
    height: 45px;
    width: 123px;
    background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
    border: 2px solid #2dd0ff;
    box-shadow: 0px 4px 10px 2px #00000080;
    font-size: 13px;
    line-height: 19px;
    border: none;
  }

  .show-episode span {
    font-size: 12px;
  }

  .show-rent {
    height: 45px;
    width: 100px;
    font-size: 13px;
    margin-left: 8px;
    background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
    border: none;
  }

  .show-rent span {
    font-size: 13px;
  }

  .show-like-btn {
    margin-left: 8px;
    background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
    border: none;
  }

  .show-watchlist-icon {
    margin-left: 8px;
    background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
    border: none;
  }

  .show-terms p {
    padding-bottom: 10px;
    border-bottom: 1px solid #2dd0ff;
    font-size: 12px;
    text-align: center;
    /* margin-left: -9px !important; */
  }

  .show-dinner-btn {
    display: none;
  }

  .show-mob-btn {
    display: block;
    height: 44px;
    width: 44px;
    border-radius: 10px !important;
    padding: 0 !important;
    background: linear-gradient(180deg, #2dd0ff 0%, #1891b4 100%);
    margin-left: 9px;
  }

  .show-episodes-btn {
    display: flex;
  }

  .movie-veuit-img {
    height: 217px;
    width: 147px;
  }

  .ratings-review {
    display: block;
  }

  .show-ratings-review {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .write-review-btn {
    font-size: 14px;
    height: 45px;
    width: 220px;
    border-radius: 30px !important;
  }

  .review-description {
    padding-left: 0;
    padding-top: 21px;
  }

  .show-count-data {
    padding-bottom: 23px;
  }

  .comments-show {
    flex-direction: column-reverse;
    display: flex;
  }

  .create-comment {
    display: none;
  }

  .create-comment1 {
    display: block;
  }

  .comment-title1 {
    display: block;
    width: 100%;
  }

  .set-comment-title {
    margin-top: 15px;
    font-size: 14px !important;
    line-height: 21px !important;
    height: 96px !important;
    border-radius: 15px !important;
  }

  .create-comment1 p {
    font-size: 17px;
    line-height: 31px;
  }

  .create-comment1 {
    margin-top: 13px;
  }

  .comment-title {
    display: none;
  }

  .set-comment-btn {
    text-align: center;
  }

  .sorted-top-comment p {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    padding-top: 5px;
  }

  .filter-comment p {
    font-size: 14px;
    text-align: center;
    line-height: 21px;
  }

  .filter-comment {
    justify-content: center;
    padding-top: 24px;
    align-items: center;
  }

  .comment-button-visit {
    height: 45px;
    width: 220px;
    border-radius: 30px !important;
    background: linear-gradient(180deg, #f4eca4 0%, #e2aa19 100%);
    border: 1px solid #e2aa19;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
  }

  .show-comment-sec {
    margin-top: 24px;
    padding: 12px;
    border-radius: 10px;
  }

  .view-tab1 span {
    font-size: 14px;
    line-height: 21px;
  }

  .view-tab1 p {
    font-size: 14px;
    line-height: 21px;
  }

  .review-purchase1 p {
    font-size: 14px;
    line-height: 25px;
    padding-top: 10px;
  }

  .review-purchase1 h6 {
    font-size: 12px;
    line-height: 18px;
    padding-top: 3px;
  }

  .review-report1 {
    padding-top: 3px;
  }

  #set-comment-border {
    padding-bottom: 10px;
  }

  .review-report1 span {
    font-size: 12px;
    line-height: 18px;
  }

  .review-report1 p {
    font-size: 12px;
    line-height: 18px;
    padding-left: 15px;
  }

  .review-comment-img {
    margin-left: 15px;
  }

  .review-share-img {
    margin-left: 15px;
  }

  .comment-sec-new {
    padding-top: 0;
  }

  .comment-para p {
    font-size: 14px;
    line-height: 25px;
  }

  #view-tab1 {
    padding-top: 21px;
  }

  .comment-reply-sec {
    padding-top: 20px;
  }

  .set-reply-title {
    height: 36px !important;
    border-radius: 18px !important;
    font-size: 12px;
    line-height: 18px;
    padding-top: 7px;
    padding-bottom: 0px;
  }

  .like-comment-icon {
    padding-top: 12px;
  }

  div#pills-Comments {
    padding-top: 24px;
  }

  .rating-progressbar p {
    font-size: 14px;
    line-height: 21px;
  }

  .rating-progressbar span {
    font-size: 14px;
    line-height: 21px;
  }

  .set-progress-bg {
    width: 220px !important;
  }

  .sorted-top-review p {
    line-height: 21px;
    font-size: 14px;
    text-align: center;
    padding-top: 8px;
  }

  .filter-review p {
    font-size: 14px;
    line-height: 21px;
  }

  .filter-review {
    justify-content: center;
    align-items: center;
  }

  .comment-vector-img {
    height: 15px;
    width: 15px;
  }

  .show-subtitle {
    display: none;
  }

  .show-time-details-mobile {
    display: block;
  }

  .show-movie-description {
    display: none !important;
  }

  .show-extratab-img {
    height: 75px;
    width: 115px;
    border-radius: 15px;
  }

  .show-terms span {
    font-size: 12px !important;
  }

  .movie-tab-item span {
    font-size: 17px;
    line-height: 31px;
  }

  .series-tabs-icon {
    display: none;
  }

  .filter-comment h6 {
    font-size: 14px;
    line-height: 21px;
  }

  .movie-name p {
    margin: 0 !important;
  }

  .show-star-content {
    padding-top: 4px;
  }

  .movie-extra {
    padding-top: 24px;
    border-bottom: none !important;
    padding-bottom: 0px !important;
    align-items: center;
  }

  .mobile-season {
    border-bottom: 1px solid #616161;
    padding-bottom: 16px;
  }

  .show-season-description {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #bdbdbd;
    padding-top: 5px;
    margin-bottom: 0px;
    width: 100%;
    display: block;
  }

  .photos-extra-img {
    height: 75px;
    width: 115px;
    border-radius: 15px;
  }

  .remaining-photo p {
    font-size: 14px;
    line-height: 31px;
  }

  .p-set-prev {
    top: 19% !important;
    left: -4px !important;
  }

  .p-set-next {
    right: -29.5% !important;
    top: 19% !important;
  }

  .remaining-photo {
    width: 18%;
  }

  .main-content.extra-tabs-set {
    width: 78%;
  }

  .show-home-tabs {
    padding-top: 24px;
  }

  .details-show-movie {
    padding-top: 11px;
  }

  .details-of-movie {
    padding-top: 9px;
  }

  .top-cast p {
    font-size: 17px;
    line-height: 32px;
    padding-bottom: 0px;
    padding-top: 18px;
  }

  .show-cast-agency {
    display: none;
  }

  .show-img-boxes h4 {
    font-size: 14px;
    line-height: 26px;
    padding-top: 7px;
  }

  .top-cast .show-img-boxes p {
    font-size: 14px;
    line-height: 19px;
  }

  .details-of-movie h4 {
    font-size: 14px;
    line-height: 31px;
  }

  .details-of-movie p {
    font-size: 13px;
    line-height: 19px;
  }

  .extra-photo {
    font-size: 17px;
    line-height: 31px;
    padding-bottom: 0px;
    padding-top: 28px;
  }

  .movies-content {
    padding-top: 28px;
  }

  .movies-content p {
    font-size: 17px;
    line-height: 31px;
    padding-bottom: 0px;
  }

  .filter-review h6 {
    font-size: 14px;
    line-height: 21px;
  }

  .details-moive-btn {
    margin-right: 0px;
    margin-top: 20px;
  }

  img.img-fluid.logo {
    max-width: 102px !important;
    width: 102px !important;
  }

  video.d-video-banner {
    height: 300px !important;
  }

  #home-slider h1.slider-text {
    font-size: 32px;
    padding-top: 4px !important;
    line-height: 40px !important;
  }

  .d-movie-title {
    font-size: 24px !important;
    line-height: 24px !important;
    padding-top: 12px;
  }

  .d-view-text {
    font-size: 14px;
    line-height: 14px;
    padding-right: 16px;
  }

  .d-brand-network {
    font-size: 28px;
    line-height: 24px;
  }

  img.img-fluid.d-influ-img {
    height: 192px;
    width: 132px;
  }

  .d-veuit-img {
    height: 192px;
    width: 132px;
  }

  section#iq-tvthrillers {
    margin-top: 28px !important;
  }

  .event-videos {
    height: 298px !important;
    padding: 0 !important;
  }

  img.d-img1 {
    height: auto !important;
    width: 128px !important;
  }

  .d-img-brand {
    height: auto !important;
    width: 148px !important;
  }

  .d-btn {
    padding: 0 !important;
    height: 40px;
    width: 171px;
    font-size: 16px;
    line-height: 38px;
  }

  #home-edubtn {
    margin-top: 180px;
  }

  #brand-btn {
    margin-top: 28px !important;
  }

  .d-brand-btn {
    padding: 0 !important;
    height: 40px;
    width: 171px;
    font-size: 16px;
    line-height: 38px;
  }

  .main-content.set-main-brand {
    padding-top: 13px !important;
    padding-bottom: 0px;
  }

  .event-video-events {
    height: 298px !important;
  }

  .event-video-series {
    height: 298px !important;
    padding: 0 !important;
  }

  img.count-series-img {
    width: 224px;
    height: 56px;
    margin-bottom: 20px;
  }

  .education-block {
    height: 300px;
    /* margin-top: 76px; */
  }

  .education-block-img {
    height: 300px !important;
  }

  .set-veuit-footer {
    display: block;
  }

  #set-list-footer {
    margin-left: 0px;
    margin-top: 20px;
  }

  footer.footer-one .widget .textwidget p {
    font-size: 14px;
    line-height: 24px;
    padding-top: 28px;
  }

  h1.slider-text.text-uppercase {
    font-size: 28px;
    line-height: 32px;
  }

  .series-listname {
    font-size: 28px;
    line-height: 32px;
  }

  .WEI-txt {
    padding-top: 20px;
    font-size: 16px;
    line-height: 24px;
  }

  #d-series-network {
    margin-top: 28px;
  }

  p.slider-text.d-slide-txt {
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 0px;
  }

  #veuit-mob-text {
    line-height: 16px;
  }

  #footer-container {
    width: 91% !important;
  }

  #footer-container .footer-standard.row {
    margin: 0 !important;
  }

  /* li.faq-list {
        padding-right: 0 !important;
    } */

  .c-logo {
    width: 102px;
    max-width: 102px;
  }

  footer .footer-standard .widget ul li {
    padding-right: 40px;
  }

  .info-share-mob a {
    width: 48px;
    height: 48px;
    font-size: 24px;
    line-height: 44px;
    position: relative;
    display: inline-block;
    text-align: center;
    margin-right: 0px;
    color: #2dd0ff;
    border-radius: 50%;
    background: linear-gradient(180deg,
        rgba(97, 97, 97, 0.75) 0%,
        rgba(0, 0, 0, 0.75) 100%);
    transition: all 0.4s ease 0s;
    border: 2px solid #616161;
    text-decoration: none !important;
  }

  .info-share {
    display: none;
  }

  .info-share-mob {
    margin-top: 36px;
    display: block;
    text-align: center;
    width: 100%;
    margin-bottom: 100px !important;
    padding: 0 !important;
  }

  footer.footer-one .footer-top {
    padding-top: 48px !important;
    padding-bottom: 16px !important;
  }

  .main-header {
    padding: 20px 0;
  }

  .e-brand-prev {
    top: 55.8% !important;
  }

  .d-set-prev {
    top: 48.1% !important;
  }

  .d-set-next {
    top: 48.1% !important;
  }

  .b-set-next {
    top: 28% !important;
  }

  .b-set-prev {
    top: 28% !important;
  }

  .musicartists-img {
    height: 192px;
    width: 132px;
  }

  .world-event-stock {
    margin: 0 auto;
    display: block;
  }

  .set-section-stock {
    text-align: center;
    padding-right: 0px;
  }

  .set-section-stock1 {
    text-align: center;
    padding-right: 0px;
    padding-top: 24px;
  }

  .set-section-stock2 {
    text-align: center;
    padding-top: 24px;
  }

  .secure-connect {
    font-size: 16px;
    padding-top: 16px;
  }

  .world-stock-img {
    height: 168px;
    width: 300px;
  }

  #series-bg-img .d-btn {
    margin-top: 32px !important;
  }

  #border-set {
    height: 0 !important;
  }

  .world-event-stock {
    padding-top: 32px;
    padding-bottom: 24px;
  }

  .maintenance-phase {
    /* width: 132px; */
    height: 60px;
    padding: 8px 8px 12px 8px !important;
  }

  .podcast-img {
    height: 132px;
    /* width: 132px; */
  }

  p.set-maintenance {
    font-size: 14px;
    line-height: 20px;
  }

  div#series-bg-img {
    height: 300px;
  }

  .show-podcast-tabs {
    padding: 28px 15px 41px 15px;
  }

  .podcast-footer {
    padding: 28px 15px 41px 15px;
  }

  .set-btns-now {
    display: block;
  }

  .podcast-center-left1 {
    height: 75px;
    width: 115px;
  }

  .podcast-center-right {
    margin-left: 17px;
  }

  .podcast-episode-text {
    font-size: 20px;
    line-height: 30px;
  }

  .podcast-episodes {
    padding-top: 7px;
  }

  .podcast-calender-sec p {
    font-size: 12px;
    line-height: 16px;
    padding-left: 8px;
  }

  .podcast-calender-sec img {
    height: 14px;
    width: 14px;
  }

  .podcast-timing-sec img {
    height: 14px;
    width: 14px;
  }

  .podcast-timing-sec p {
    font-size: 12px;
    line-height: 16px;
    padding-left: 8px;
  }

  .podcast-para {
    font-size: 14px;
    line-height: 26px;
    display: unset;
  }

  .show-Subscribe-btn {
    margin-top: 10px;
    margin-left: 0px;
  }

  p.podcast-episode-para {
    display: none;
  }

  p.podcast-episode-para.podcast-episode-para1 {
    display: block;
  }

  .podcast-center {
    justify-content: unset;
    padding-top: 24px;
    border-bottom: none !important;
    padding-bottom: 0px !important;
    align-items: center;
  }

  img.podcast-center-left2 {
    width: 24px;
  }

  button.btn.podcast-center-left2 {
    left: 6px;
    bottom: 0px;
  }

  .podcast-footer-list2 .rhap_main {
    flex-direction: column;
  }

  .podcast-footer-list {
    display: block;
  }

  .podcast-footer-list1 p {
    display: block;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    margin-bottom: 0 !important;
    text-align: center;
    margin-left: 10px;
  }

  .podcast-footer-list2 p {
    display: none;
  }

  .podcast-footer-list1 {
    display: flex;
    align-items: center;
  }

  .podcast-footer-list2 .rhap_stacked .rhap_controls-section {
    margin-left: 0px;
  }

  .podcast-footer-list2 {
    padding: 0;
    margin-top: 13px;
  }

  .podcast-footer-list2 .rhap_time {
    font-size: 12px !important;
  }

  .podcast-footer-list2 .rhap_main-controls {
    flex: 0 1 auto;
  }

  .podcast-footer-list2 button.rhap_button-clear.rhap_main-controls-button.rhap_rewind-button {
    margin-right: 10px;
  }

  .podcast-footer-list2 button.rhap_button-clear.rhap_main-controls-button.rhap_forward-button {
    margin-left: 0px;
  }

  .podcast-sec-right1 {
    height: auto;
    width: 100%;
  }

  .b-set-next {
    right: 15px !important;
  }

  .b-set-prev {
    left: 15px !important;
  }

  #donate-now {
    line-height: 37px;
  }

  img.d-video-banner {
    height: 300px !important;
  }

  #set-watchlist-row .col-1-5 {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  .watchlist-container {
    padding: 25px 15px;
  }

  .footer-podscast {
    height: 615px;
  }

  svg.podcast-center-left2 {
    width: 24px;
  }

  #view-all-movie {
    flex: 0 0 32.666667%;
    max-width: 32.666667%;
  }

  h3.text-uppercase.view-all-text-movie {
    font-size: 30px;
  }

  .watchlist-container .epi-desc.p-3 {
    padding: 8px 10px !important;
  }

  #watchlist-episodename {
    font-size: 16px;
    line-height: 22px;
  }

  #set-seconds-hours {
    font-size: 12px;
    line-height: 18px;
  }

  #watchlist-date {
    font-size: 12px;
    line-height: 18px;
  }

  #view-all-movie1 {
    flex: 0 0 32.666667%;
    max-width: 32.666667%;
  }

  /* 
    .header-profile p {
        display: none !important;
    } */

  .video-mobile-back .video-series-title {
    font-size: 16px;
    line-height: 20px;
  }

  .video-mobile-back .video-back {
    width: 25px !important;
    height: 25px !important;
  }

  .video-mobile-back .video-series-episode {
    font-size: 12px;
    line-height: 18px;
  }

  #new-channel-row #set-sign-in1 {
    background: unset !important;
    padding: 0px;
    border-radius: unset !important;
    margin: 0 16px;
  }

  #payment-modal-new #set-sign-in1 {
    background: unset !important;
    padding: 0px;
    border-radius: unset !important;
    margin: 0 16px;
    border: 0;
  }

  #m-channel-newprofile #create-channels {
    width: 100%;
    padding: 0px !important;
    background: unset !important;
    box-shadow: unset !important;
  }

  #payment-modal-new #create-channels {
    width: 100%;
    padding: 0px !important;
    /* background: unset !important; */
    box-shadow: unset !important;
  }

  #m-channel-newprofile {
    background: unset !important;
  }

  #m-channel-newprofile #create-channels .close-vector {
    display: none;
  }

  #payment-modal-new {
    margin: 0;
    background: linear-gradient(0deg, #000000 0%, #181818 100%) !important;
  }

  #payment-modal-new #create-channels .close-vector {
    display: none;
  }

  .channel-select-setup {
    width: 100%;
  }

  #create-channels .new-channel-line {
    display: block;
  }

  #create-channels .new-channel-select {
    width: 100% !important;
  }

  #create-channels .custom-control-inline {
    width: 100% !important;
  }

  #create-channels .custom-control-inline1 {
    width: 100% !important;
    margin-top: 16px;
  }

  #channel-logos {
    display: block;
    margin-top: 30px;
  }

  .align-setup-mob {
    justify-content: space-between;
    margin-top: 28px;
  }

  #create-channels .set-rounds {
    display: flex;
    flex-direction: column;
  }

  #create-channels .veuit-logo {
    width: 148px;
    height: 52px;
  }

  #create-channels .set-rounds .set-rounds-text {
    padding-left: 0px;
    padding-top: 8px;
    width: 92px;
  }

  #new-channel-row {
    padding-top: 88px !important;
  }

  #new-channel-row .new-create-channel {
    margin: 18px 0;
  }

  .channel-registration-dataplan {
    max-width: 94vw;
  }

  #billing-address-modal {
    margin: auto;
    /* z-index: 2000; */
  }

  #billing-address-modal\ hide-mobile-modal {
    margin: auto;
  }

  #app-modal {
    margin: auto;
  }

  #app-modal\ hide-mobile-modal {
    margin: auto;
  }

  .setup-default-add {
    width: 100%;
    height: 44px;
    margin-bottom: 40px;
  }

  #create-channels .set-rounds-active {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  #create-channels .set-rounds-active p {
    padding-left: 0px;
    padding-top: 8px;
  }

  #create-channels .new-channel-back {
    margin-bottom: 30px;
  }

  .show-listsub-section {
    margin: 0px auto;
    margin-bottom: 24px;
    max-width: 242px;
  }

  #show-merchsub-section {
    margin: 0px auto;
    max-width: 157px;
    margin-bottom: 25px;
  }

  #favorites-contens .show-listsub-section {
    margin: 0px;
    max-width: auto;
  }

  .purchase-text .arrow-right {
    margin-left: 0;
  }

  .shows-search .filter-btn {
    width: 32px;
    margin-left: 5px;
    padding: 0 !important;
  }

  .shows-search .filter-btn span {
    display: none;
  }

  .user-info-field {
    width: 100%;
  }

  .user-info-fields {
    flex-direction: column;
  }

  #user-info-field {
    margin-left: 0px;
  }

  .user-info-fields .form-label {
    text-decoration-line: unset;
    padding-bottom: 8px;
  }

  .user-details .savechanges-btn {
    text-align: center;
  }

  .user-details .savechanges-btn .save-btn {
    width: 171px;
    height: 40px;
    border-radius: 20px;
    text-decoration: unset;
    margin: 20px 0;
  }

  .user-info-field #country-code {
    width: 120px;
    margin-right: 9px;
  }

  .country-code #contact-number {
    width: 100%;
  }

  .user-social-icons {
    flex-direction: column;
  }

  .user-socialicons-sub {
    width: 100%;
  }

  #user-socialicons {
    margin-left: 0;
    width: 100%;
  }

  .user-socialicons-field {
    width: 100%;
  }

  .user-social-icons .user-socialicons-subfiled {
    margin-bottom: 28px;
  }

  .user-info-fields .form-group {
    margin-bottom: 28px !important;
  }

  #social-accordion .save-btn {
    margin-top: 20px;
  }

  .sample-user-content .profile-info .sample-check {
    margin-left: 14px;
  }

  .sample-user-content .profile-info h3 {
    font-size: 20px;
  }

  .shows-search .filter-icon {
    margin: 0px auto;
  }

  .response-time {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 0;
  }

  #time-pannel {
    margin-left: 0px;
    margin-top: 16px;
    width: 100%;
  }

  #time-pannel h6 {
    display: block;
  }

  .price-dropdown .usd-input h5 {
    display: none;
  }

  .total-completebtns .completed-btns {
    width: 171px;
    height: 48px;
    margin-left: 16px;
    font-size: 16px;
  }

  .total-completebtns .total-btns {
    width: 171px;
    height: 48px;
    font-size: 16px;
  }

  .video-calltext .usd-input {
    width: auto;
  }

  .existing-schedule {
    align-items: flex-start;
    margin-top: 10px;
    margin-bottom: 26px;
    flex-direction: column;
  }

  .existing-schedule-btn {
    margin-left: 0px;
  }

  #existing-schedule-btn {
    margin-left: 0px;
    margin-top: 16px;
  }

  .existing-schedule-btn h4 {
    margin-left: 20px;
  }

  .weekly-options {
    padding-left: 13px;
  }

  .existing-schedule-btn p {
    padding-left: 30px;
  }

  .existing-schedule-btn #delete-schedule-btn {
    margin-left: 30px;
  }

  .existing-schedule-btn .delete-scheduleimg {
    margin-left: 20px;
  }

  #calender-schedule-picker .react-calendar__navigation {
    background: var(--gradient, linear-gradient(180deg, rgba(45, 208, 255, 0.60) 0%, rgba(24, 145, 180, 0.60) 100%));
    height: 44px;
    margin-bottom: 24px;
  }

  #web-response-metting {
    display: none;
  }

  #mobile-response-metting {
    display: unset;
  }

  #mobile-response-metting .responsetime {
    margin-top: 20px;
  }

  .purchase-container #favorites-contens .swiper-button-prev {
    left: -0px !important;
  }

  .purchase-container #favorites-contens .swiper-button-next {
    right: 0px !important;
  }

  .Subscription-listsection {
    margin: 0px auto;
    margin-bottom: 24px;
    max-width: 242px;
  }

  .Subscription-list {
    flex-direction: column;
  }
}

@media only screen and (max-width: 576px) {
  .d-set-next {
    right: 15px !important;
  }

  .d-set-prev {
    left: 15px !important;
  }

  .d-set-overflow {
    padding: 0 !important;
    padding-left: 0px !important;
  }

  #favorites-contens .swiper-container {
    padding-bottom: 0px !important;
  }

  .d-veuitbgimg {
    background-image: url("../images/half-logo.png");
    background-attachment: unset;
    background-position-y: 28px !important;
    background-position: unset;
    background-size: unset !important;
  }

  header#main-header {
    position: unset !important;
  }

  .iq-main-slider {
    padding-top: 0px !important;
  }

  .d-brand-img {
    width: 168px !important;
    height: 112px !important;
  }

  /* #d-tvchannel-slider {
        width: 217px !important;
        height: 147px !important;
        margin-right: 8px !important;
    } */

  .d-tvchannel-img {
    width: 200px !important;
    height: 134px !important;
  }

  img.d-img2 {
    width: 26px !important;
  }

  .edu-description {
    font-size: 15px;
  }

  .veuit-content h4 {
    padding-right: 10px;
    font-size: 36px;
    line-height: 36px;
    padding-left: 10px;
  }

  #tech-section {
    width: 100%;
  }

  .d-title-txt-one {
    font-size: 10px;
    padding-bottom: 2px;
    line-height: 11px;
    padding-top: 40px;
  }

  .d-txt-one {
    font-size: 6px;
    letter-spacing: 0.3px;
    font-size: 6px;
    line-height: 11px;
  }

  #slider-ratting {
    margin-right: 4px !important;
    margin-top: 11px !important;
    margin-bottom: 12px !important;
  }

  p.trending-dec.d-txt-one {
    display: none;
  }

  #space-series {
    margin-top: 24px !important;
  }

  .d-title-txt-one {
    display: none;
  }

  .series-btn {
    margin-top: 0px !important;
  }

  div#feature-bgimg {
    height: 272px !important;
  }

  .form-bg-mobile {
    background-size: contain !important;
    background: url(../images/mobile.png);
    background-repeat: no-repeat;
    background-position: top !important;
  }

  .sign-in-page {
    background: none !important;
  }

  .sign-in-page .height-self-center {
    height: auto !important;
    border-radius: 15px;
  }

  #set-sign-in {
    border: unset;
    width: 100%;
    border-radius: unset;
    background: unset;
  }

  .sign-in-logo {
    margin-top: 26px;
    margin-bottom: 16px;
  }

  #sign-in-logo {
    padding-top: 93px;
  }

  .sign-in-logo1 {
    margin-top: 26px;
    margin-bottom: 16px;
  }

  .form-bg-mobile1 {
    margin-top: 24px;
    padding-top: 148px;
  }

  #form-bg-mobile1 {
    margin-top: 24px;
    padding-top: 96px;
  }

  #sign-in-logo1 {
    padding-top: 0;
  }

  .forgot-pwd {
    padding-top: 0px;
  }

  /* input#txtLastname {
        width: 159px;
    }

    input#txtFirstname {
        width: 159px;
    } */

  .d-title-feature-2 {
    padding-top: 184px !important;
  }

  .d-ratings-and-season {
    display: none !important;
  }

  .series-btn {
    line-height: 40px;
    margin-top: 9% !important;
  }

  .trending-button {
    text-align: center;
  }

  .tranding-block2 {
    height: 272px;
  }

  .tranding-block2 .d-set-featured {
    padding-top: 80px;
  }

  #set-sign-in {
    margin: 0 !important;
  }

  .d-image-1,
  .d-image-2 {
    width: 250px;
  }

  .d-set-featured-video,
  .d-set-featured-channel-photo {
    padding-top: 45px;
  }

  .d-title-feature-2 {
    padding-top: 11px !important;
  }

  .d-series-set-btn {
    justify-content: center !important;
  }

  .set-channel-img {
    background: none;
    padding-top: 40px !important;
    min-height: auto !important;
    padding-bottom: 0 !important;
  }

  .channel-registration {
    width: 90%;
    padding: 0;
    background: none;
    border: none;
  }

  .network-timeline {
    width: 97%;
  }

  .select-channel-subtype {
    width: 100% !important;
  }

  .select-channel-type {
    width: 100% !important;
  }

  .set-domain {
    width: auto;
    margin-bottom: 30px;
  }

  .movie-veuit-img {
    height: 217px;
    width: 147px;
  }

  .show-profile-img {
    height: 82px;
    width: 82px;
  }

  .show-rating-details h3 {
    font-size: 14px !important;
    line-height: 31px !important;
  }

  .show-rating-details h4 {
    font-size: 14px !important;
    line-height: 31px !important;
    padding-left: 14px;
  }

  .show-tv-btn {
    height: 21px;
    width: 62px;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0em;
    margin-left: 10px;
  }

  .show-like-details p {
    font-size: 14px;
    line-height: 21px;
  }

  .movie-bgimg {
    height: 219px;
  }

  .show-movie-details {
    padding-top: 0px;
  }

  .detail-action-btn {
    font-size: 14px;
    line-height: 21px;
    height: 21px;
    width: 62px;
    border-radius: 0px !important;
    border: 1px solid #bdbdbd;
    background: none !important;
    margin-left: 8px;
  }

  .show-rating-details p {
    font-size: 14px;
    line-height: 31px;
  }

  .show-count-data p {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #bdbdbd;
    padding-top: 5px;
  }

  .review-rating-calc p {
    font-size: 14px;
    line-height: 21px;
    padding-left: 7px;
  }

  .review-tab-description {
    padding: 10px;
    border-radius: 15px;
  }

  .view-tab span {
    font-size: 14px;
    line-height: 21px;
  }

  .view-tab p {
    font-size: 14px;
    line-height: 21px;
  }

  .verified-ratings p {
    font-size: 14px;
    line-height: 21px;
    padding-top: 10px;
  }

  .review-purchase h5 {
    font-size: 12px;
    line-height: 18px;
    padding-top: 8px;
  }

  .review-purchase p {
    font-size: 14px;
    padding-top: 5px;
  }

  .review-purchase h6 {
    font-size: 12px;
    line-height: 18px;
    padding-top: 4px;
  }

  .review-report {
    padding-top: 15px;
  }

  .review-report p {
    font-size: 12px;
    line-height: 18px;
    padding-left: 40px;
  }

  .review-tab-description {
    margin-top: 24px;
  }

  .movie-tab-item {
    padding-right: 13px !important;
  }

  .set-accordion-ratings .MuiSvgIcon-root {
    font-size: 1.5rem !important;
  }

  .MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.MuiIconButton-edgeEnd {
    height: 7px !important;
    width: 12px !important;
  }

  img.topreview-vector {
    height: 7px;
    width: 12px;
  }

  .show-star-content h5 {
    font-size: 14px;
    line-height: 31px;
  }

  .veuit-series p {
    font-size: 13px;
    line-height: 17px;
  }

  #series-season {
    padding-right: 13px;
  }

  #pills-season-tab {
    padding-right: 5px !important;
  }

  .season-vector {
    height: 7px;
    width: 12px;
    margin-right: 13px;
  }

  .show-movie-tab {
    padding-left: 13px;
  }

  .show-movie-tab h4 {
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .remaining-photo {
    width: 24%;
  }

  .main-content.extra-tabs-set {
    width: 70%;
  }

  .p-set-next {
    right: -43.5% !important;
  }

  video.movie-bgvideo {
    height: 219px;
  }

  .WEI-txt {
    padding-top: 20px;
    width: 90% !important;
    font-size: 16px;
    line-height: 24px;
  }

  h1.slider-text.text-uppercase {
    font-size: 28px;
    line-height: 32px;
  }

  .series-listname {
    font-size: 28px;
    line-height: 32px;
  }

  #home-slider h1.slider-text {
    font-size: 32px;
    padding-top: 4px !important;
    line-height: 40px !important;
  }

  #d-container {
    padding-left: 16px !important;
    padding-right: 0 !important;
    width: auto !important;
  }

  section#iq-brand {
    margin-top: 28px !important;
  }

  #b-container {
    padding-left: 16px !important;
    padding-right: 0 !important;
    width: auto !important;
  }

  .info-share-mob li::marker {
    display: none !important;
  }

  .veuit-channel-signin {
    font-size: 14px;
    line-height: 22px;
    width: 88px;
    height: 28px;
    padding: 0 !important;
  }

  .veuit-channel-btn {
    font-size: 14px;
    line-height: 22px;
    width: 169px;
    height: 28px;
    padding: 0 !important;
  }

  .privacy-text-sec p {
    font-size: 28px;
    line-height: 76px;
  }

  .privacy-text-sec {
    height: 80px;
  }

  .contact-privacy {
    margin-left: 16px;
    margin-right: 16px;
    padding-top: 36px;
    padding-bottom: 23px !important;
  }

  #set-privacy-policy {
    width: 96%;
  }

  .set-bottom-privacy {
    padding-top: 29px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .contacting-us {
    font-size: 32px;
    line-height: 32px;
  }

  .d-bnr-btn {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .d-btn-view-now {
    margin-left: 0px;
  }

  /* 
    .d-bnr-btn .d-btn {
        margin-top: 20px;
    } */

  .show-podcast-details {
    padding-top: 0px;
  }

  .show-Listen-btn {
    width: 100%;
    height: 54.09px;
    font-size: 22.6923px;
  }

  .show-Subscribe-btn {
    width: 100%;
    height: 54.09px;
    font-size: 22.6923px;
    margin-left: 0px;
    margin-top: 13px;
  }

  p.podcast-episode-para.podcast-episode-para1 {
    font-size: 14px;
    padding-top: 10px;
  }

  .podcast-timing-sec {
    margin-left: 10px;
  }

  .podcast-footer-list1 img {
    width: 55px;
  }

  .podcast-footer-list2 p {
    font-size: 14px;
    line-height: 20px;
  }

  #set-donate-btn {
    display: block;
  }

  #header-mobile {
    flex-direction: column;
    align-items: flex-end !important;
  }

  #header-mobile li {
    margin-right: 0px !important;
    margin-top: 15px !important;
  }

  .more-menu .iq-sub-dropdown {
    right: 15px;
    top: 143px;
    width: 200px !important;
  }

  #set-watchlist-row .col-1-5 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .watchlist-container {
    padding: 10px 15px;
    margin-top: 0 !important;
  }

  .watchlist-container .tab-content {
    margin-top: 47px;
  }

  .footer-podscast {
    height: 638px;
  }

  .main-header {
    padding: 12px 0;
  }

  .payment-img {
    margin-top: 0px;
    width: 60px;
  }

  #payment-msg {
    margin-top: 15px;
  }

  #payment-back {
    margin-top: 20px !important;
  }

  #view-all-podcast {
    flex: 0 0 32.666667%;
    max-width: 32.666667%;
  }

  #view-all-movie1 {
    flex: 0 0 49.666667%;
    max-width: 49.666667%;
  }

  .iq-privacy-policy2 {
    padding: 30px 15px;
  }

  .iq-accordion .iq-accordion-details {
    padding: 15px 15px;
  }

  .iq-accordion .iq-accordion-title {
    padding: 30px 75px 30px 15px;
  }

  .faq-col {
    padding: 0 10px !important;
  }

  #set-sign-in1 {
    background: unset !important;
    padding: 0px;
    border-radius: unset !important;
    border: unset !important;
  }

  #set-sign-in {
    height: auto;
    box-shadow: unset !important;
    padding: 0 16px !important;
  }

  #set-input-width {
    padding-top: 24px;
  }

  .sign-in-input {
    height: 44px !important;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.5);
  }

  .sign-in-input-pwd {
    height: 44px !important;
  }

  #set-input-width input::placeholder {
    color: #797979 !important;
  }

  input,
  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="password"],
  textarea {
    padding: 0 16px;
  }

  #set-input-width .second-name {
    padding-left: 7px !important;
  }

  #set-sign-in1 .input-group {
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.5);
  }

  #sign-inbtn {
    font-size: 16px;
  }

  #info-logopages {
    width: 180px;
    height: 72px;
  }

  #container-signin {
    padding: 0 !important;
  }

  .forgot-input {
    height: 44px !important;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.5);
  }

  .set-input-width {
    padding-top: 40px !important;
  }

  #forgot-inbtn {
    height: 44px;
    border-radius: 25px !important;
    font-size: 16px;
  }

  .set-reset-height {
    height: 0px;
  }

  .set-reset-height #set-input-width {
    padding-top: 0px;
  }

  #header-container {
    padding: 0 16px;
  }

  .iq-sub-dropdown.dropdown-menu.dropdown-menu-right.dropdown-menu.show {
    z-index: 9;
  }

  .iq-sub-dropdown.dropdown-menu.dropdown-menu-right.dropdown-menu.show {
    /* height: 212px !important;
        position: fixed !important;
        bottom: 0 !important;
        top: 0 !important;
        width: 100% !important; */
    transform: unset !important;
    background: transparent !important;
    z-index: 99;
    /* border-radius: 35px 35px 0px 0px !important; */
  }

  svg.black-polygon {
    display: none;
  }

  #right-sidetoggle .iq-card-body.iq-card-body {
    padding: 36px 16px 40px 16px;
  }

  #right-sidetoggle .veuit-channel-create {
    width: 100%;
    height: 40px;
    font-size: 16px;
    line-height: 35px;
  }

  #right-sidetoggle .toggle-text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    margin-bottom: 20px;
  }

  #right-sidetoggle .toggle-text-line {
    width: 70px;
  }

  #right-sidetoggle .veuit-channel-signin {
    width: 100%;
    height: 40px;
    font-size: 16px;
    line-height: 36px;
  }

  .dropdown-menu {
    /* right: 0 !important;
        bottom: 0 !important; */
    min-width: 100% !important;
  }

  .iq-card.iq-card.shadow-none.m-0 {
    background: linear-gradient(180deg, #181818 10.62%, #000000 94.74%) !important;
    border-radius: 35px 35px 0px 0px !important;
  }

  .MuiPaper-root {
    background-color: unset !important;
  }

  #d-header {
    height: 82px !important;
  }

  #toggle-contents {
    width: 100%;
    padding: 28px 16px;
  }

  .footer-icon {
    margin: 0 auto;
    text-align: center;
    margin-top: 0px;
    width: 100px;
    padding: 10px 25px;
  }

  div#right-sidetoggle {
    width: 100%;
  }

  .header-profile p {
    display: none;
  }

  .error-content {
    padding: 10px;
    display: block !important;
    height: auto !important;
    margin-bottom: 50px;
  }

  .error-image {
    width: 200px;
  }

  .error-code {
    font-size: 60px !important;
    margin-top: 10px;
  }

  .error-text {
    font-size: 30px;
    margin-top: 20px;
  }

  .left-logo {
    justify-content: left !important;
  }

  .header-profile.small {
    flex: unset !important;
    width: auto;
  }

  h4.mb-4.basic-information {
    text-align: center;
  }

  .change-pwd-save {
    justify-content: center;
  }

  .change-pwd-text {
    text-align: center;
  }

  #iq-main-slider {
    padding-top: 0px !important;
  }

  #video-section .jw-breakpoint-1 .jw-display .jw-icon,
  .jw-breakpoint-1 .jw-display .jw-svg-icon {
    width: 33px;
    height: 33px;
    line-height: 44px;
  }

  #create-channels .new-channel-text span {
    font-size: 16px;
    line-height: 19px;
  }

  #create-channels .new-channel-text {
    font-size: 16px;
    line-height: 19px;
  }

  #create-channels .new-channel-field {
    height: 44px !important;
  }

  #create-channels .new-channel-select {
    height: 44px !important;
  }

  #create-channels .custom-control-inline {
    height: 44px !important;
  }

  #create-channels .custom-control-inline1 {
    height: 44px !important;
  }

  #create-channels .form-group {
    margin-bottom: 1.75rem !important;
    width: 100%;
  }

  #create-channels .new-channel-btns {
    padding-top: 12px;
    flex-direction: column;
  }

  #create-channels .set-domain-name2 {
    height: 44px !important;
    width: 100%;
    background-position: calc(100% - 16px) 20px !important;
    background-size: 12px !important;
  }

  #create-channels .set-domain-name1 {
    width: 80%;
    height: 44px !important;
  }

  #create-channels .input-group-prepend {
    width: 19%;
  }

  .new-channel-form-label1 {
    flex-direction: column;
    align-items: flex-start !important;
  }

  #create-channels .new-channel-form-label1 {
    padding-left: 12px;
  }

  #create-channels .new-channel-form-label1 p {
    padding-left: 0px;
  }

  #create-channels .new-channel-form-label1 span {
    padding-left: 0px;
    padding-top: 4px;
    line-height: 20px;
  }

  #create-channels .new-channel-check-mob {
    display: block;
  }

  #create-channels .new-channel-check {
    display: none;
  }

  #create-channels .new-channel-back {
    padding-top: 10px;
    display: block;
  }

  .setup-monthly {
    padding-top: 50px;
  }

  #create-channels .new-back-btn {
    width: 50%;
    height: 40px;
    margin-right: 16px;
  }

  #create-channels .new-channel-next {
    width: 50%;
    height: 40px;
  }

  #create-channels .footer-channel-text {
    padding-top: 16px !important;
    display: none;
  }

  .new-channel-myexample {
    padding-top: 40px;
    padding-bottom: 0px;
  }

  #create-channels .newchannel-close p {
    display: none;
  }

  #create-channels .newchannel-available p {
    display: none;
  }

  #create-channels .radio-mobile {
    display: none;
  }

  #create-channels .channel-notavailable {
    justify-content: flex-end;
    width: 20%;
  }

  #create-channels .newchannel-close {
    width: 20%;
    justify-content: flex-end;
  }

  #create-channels .newchannel-mydomain {
    width: 80%;
    line-height: 20px;
  }

  #create-channels .new-channel-form-label span {
    padding-left: 4px;
  }

  #new-channel-row {
    padding-top: 0px !important;
  }

  .place-order-btn {
    margin-top: 0;
  }

  .payment-form-field {
    width: 100% !important;
    height: 44px !important;
    border-radius: 22px !important;
    padding: 0 12px !important;
  }

  .new-payment-select {
    width: 100% !important;
    height: 44px !important;
    border-radius: 22px !important;
    padding: 0 12px !important;
    background-size: 12px !important;
  }

  .new-payment-select1 {
    width: 100% !important;
  }

  .card-image-vec {
    right: 14px;
    bottom: 14px;
  }

  .setup-default-add-card {
    height: 44px !important;
    border-radius: 10px !important;
    width: 100%;
    padding: 0 12px !important;
  }

  #setup-default-add-card {
    margin-bottom: 40px !important;
  }

  .dropdown-menu.dropdown-menu-right {
    display: none !important;
  }

  .bottomDrawer {
    display: block;
    /* z-index: 1700 !important; */
  }

  #address-modal {
    border: none;
    border-radius: 0;
    padding: 26px 16px;
  }

  .mob-add-model {
    margin-top: 80px;
  }

  #modal-content-wrapper {
    /* border: none; */
    /* border-radius: 0; */
    padding: 26px 10px;
  }

  .payment-zipcode {
    height: 44px !important;
    border-radius: 22px !important;
    width: 100% !important;
    padding: 0 12px !important;
  }

  #mobile-paymentset {
    margin-right: 10px;
    width: 95% !important;
  }

  #channel-registerpay .form-group {
    margin-bottom: 28px !important;
  }

  .order-confirmation h4 {
    font-size: 20px;
  }

  #channel-registerpay #billing-bottom {
    margin-bottom: 16px !important;
  }

  .card-information {
    margin-bottom: 28px;
  }

  .add-address-btn {
    width: 100%;
    height: 44px;
    border-radius: 22px;
  }

  #billing-address-modal #add-address {
    height: 40px;
    margin-bottom: 20px;
  }

  #app-modal #add-address {
    height: 40px;
    margin-bottom: 20px;
  }

  .order-place {
    width: 100%;
    border-radius: 14px;
    padding: 16px 16px 16px 16px;
    min-height: 132px;
    height: auto;
  }

  .orderplace-image {
    width: 45px;
    height: 48px;
  }

  .order-place-text h5 {
    padding-top: 8px;
  }

  .order-place-text p {
    display: none;
  }

  .order-place .confirm-msg {
    display: block;
    padding-top: 11.99px;
    line-height: 20px;
  }

  .order-billing-address {
    width: 100%;
    margin-bottom: 24px;
    padding: 16px 16px 16px 22px;
  }

  .review-order-btn {
    width: 100%;
    height: 40px;
    border-radius: 27px;
    margin-top: 18px;
  }

  .premiumdataplan-image {
    padding: 15px;
    width: 100px;
    height: 100px;
    border-radius: 12.5px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  }

  .premiumdataplan-image svg {
    width: 70px;
    height: 70px;
  }

  .premium-dataplan-image {
    border-radius: 12.5px;
  }

  .premium-dataplan-text {
    padding-left: 16px;
  }

  .premium-dataplan-text h5 {
    font-size: 12px;
  }

  .premium-dataplans {
    align-items: flex-start;
    flex-direction: column;
  }

  .premium-dataplan-text h6 {
    font-size: 12px;
    padding-top: 4px;
  }

  .new-address-field .form-group {
    margin-bottom: 28px !important;
  }

  .add-address-state .form-group1 {
    margin-bottom: 16px !important;
  }

  .new-billing-add {
    width: 100%;
    height: auto;
    min-height: 125px;
  }

  .new-billing-add-info {
    padding-left: 8px;
  }

  #new-billing-add .editaddress p {
    text-align: right;
    font-size: 12px;
    line-height: 17px;
  }

  .add-new-address {
    padding: 14px 12px;
    width: 100%;
    height: 44px;
    margin-top: 16px;
  }

  #billing-address-modal\ hide-mobile-modal {
    display: none;
  }

  #app-modal\ hide-mobile-modal {
    display: none;
  }

  .customer-billing h4 {
    font-size: 20px;
  }

  .new-billing-add-info p {
    color: #fff;
  }

  .mob-add-model {
    border-radius: 35px 35px 0px 0px !important;
    background: linear-gradient(180deg, #181818 0%, #000 100%) !important;
    padding-top: 0 !important;
  }

  .mob-add-model .customer-billing {
    margin-top: 6px;
  }

  .payment-phn-number span {
    margin-right: 8px;
  }

  .new-country-select {
    height: 44px;
  }

  .order-place-image {
    width: fit-content;
  }

  .shows-filter .search-field {
    width: 161px;
    padding-left: 33px;
  }

  .shows-filter .search-icon {
    left: 12px;
    width: 12px;
  }

  .purchases-page {
    padding-top: 1px;
  }

  .purchase-episode-list h6 {
    font-size: 16px;
    padding-bottom: 6px;
  }

  .purchase-text h4 {
    font-size: 20px;
    line-height: 24px;
    margin-left: 8px;
  }

  .my-profile {
    width: 100%;
    margin-top: -80px;
  }

  .my-profile-one .my-profile-img {
    display: none;
  }

  .my-profile-one .my-profilemobile-img {
    display: unset;
    height: 398px;
    width: 100%;
    border-radius: 0px;
    object-fit: cover;
  }

  .my-profile-second {
    border-radius: 0px;
    margin-top: 0;
    padding: 17px;
    background: linear-gradient(90deg, #000 0%, #181818 83.33%);
    padding-top: 35px;
  }

  .user-setting {
    border-radius: 0px;
    background: unset;
    padding: 0px;
  }

  .profile-image-set .profile-Ellipse {
    width: 120px;
    height: 120px;
    border-radius: 120px;
  }

  .profile-image-set .image-profile .profile-sampleuser .p-image {
    fill: unset;
    background: linear-gradient(180deg, rgba(45, 208, 255, 0.50) 0%, rgba(24, 145, 180, 0.50) 100%);
    border: 2px solid #2DD0FF;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
    -webkit-backdrop-filter: unset;
    backdrop-filter: unset;
    top: 88px;
    right: 15px;
  }

  .profile-image-set .image-profile .profile-sampleuser .profileedit-icon {
    margin-top: 5.5px;
  }

  .profile-image-set .user-btn {
    border-radius: 16px;
    height: 32px;
  }

  #third-accordionvlink .toggle-switch {
    display: none;
  }

  #first-accordionuser {
    margin-bottom: 16px;
  }

  #first-accordionuser .user-accordionname {
    padding-bottom: 19.5px !important;
    padding-top: 40px !important;
  }

  #third-accordionvlink {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    border-bottom: 1px solid #616161 !important;
    margin-top: 16px;
  }

  #way-tointeract-section {
    margin-top: 40px;
    margin-bottom: 26px;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.10);
    padding: 10px;
  }

  .v-linkdetails .vlink-qr {
    padding-bottom: 0;
    border-bottom: unset;
    margin: 19px 0px 36px 0px;
  }

  .v-linkdetails .copyvlink {
    margin-right: 8px;
    height: 40px;
    width: 88%;
  }

  .v-linkdetails .copyvlink .linkcopy-btn {
    width: 95px;
    height: 40px;
  }

  .vlinkqr-codemodal {
    height: 40px;
    width: 40px;
  }

  .v-linkdetails .copyvlink p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 4px;
  }

  .v-linkdetails .form-group {
    padding-bottom: 37px;
    border-bottom: unset;
    margin: 0 !important;
  }

  #third-accordionvlink .socialdisplay-icons .toggle-switch {
    display: unset;
    margin-right: 26px;
  }

  .v-linkdetails .social-detailspara {
    padding: 16px 0;
    padding-top: 12px;
  }

  .vlink-themes {
    justify-content: center;
  }

  #social-displayicons {
    display: none;
  }

  #mobile-icons-links {
    display: flex;
  }

  .social-display {
    padding-bottom: 0;
    border-bottom: unset;
    margin-bottom: 36px;
  }

  #priority-dm {
    padding: 20px 0;
    border-bottom: 1px solid #616161 !important;
    border-top: 1px solid #616161 !important;
  }

  #priority-dm .savechanges-btn .save-btn {
    margin-bottom: 0px;
  }

  #face-toface {
    margin-top: 0px;
    padding: 20px 0;
    border-bottom: 1px solid #616161 !important;
  }

  #Experiences {
    margin-top: 0px;
    margin-bottom: 28px;
    border-bottom: 1px solid #616161 !important;
    padding: 20px 0;
  }

  #pricedropdown #dropdown-basic {
    height: 44px;
  }

  .price-dropdown .usd-input {
    height: 44px;
  }

  .total-completebtns .total-btns {
    width: 161px;
  }

  .total-completebtns .completed-btns {
    width: 161px;
  }

  .total-completebtns {
    margin-bottom: 18px;
    margin-top: 9px;
  }

  .user-accordionname p {
    font-size: 16px;
    line-height: 20px;
  }

  .user-setting h4 {
    font-size: 20px;
  }

  .priority-dm p {
    font-size: 16px;
  }

  .priority-dm {
    margin-bottom: 18px;
    justify-content: space-between;
  }

  .priority-dm .toggle-switch {
    margin: 0;
  }

  .response-time {
    margin-top: 16px;
  }

  #face-toface .priority-dm .toggle-switch {
    margin-left: 0;
    margin-right: 0;
  }

  #face-toface .save-btn {
    margin-top: 15px;
  }

  .range-datetext p {
    padding-top: 20px;
  }

  .faceto-schedule {
    padding-bottom: 0;
    border: unset;
    margin-bottom: 24.5px;
    padding-top: 4px;
  }

  #calender-schedule-picker button.react-calendar__tile.react-calendar__month-view__days__day {
    margin-bottom: 15px;
  }

  #calender-schedule-picker .react-calendar__month-view__weekdays__weekday {
    margin-bottom: 17px;
  }

  #calender-schedule-picker span {
    font-size: 16px;
  }

  #calender-schedule-picker abbr[data-original-title],
  abbr[title] {
    font-size: 16px;
  }

  #calender-schedule-picker button.react-calendar__tile.react-calendar__month-view__days__day {
    font-size: 16px;
  }

  #calender-schedule-picker button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
    color: #797979;
  }

  .schedule-tiomezone {
    margin-top: 20px;
  }

  .faceto-schedule .faceto-scheduletext p {
    display: none;
  }

  .faceto-schedule .faceto-scheduletext h4 {
    font-size: 20px;
  }

  .faceto-schedule .faceto-scheduletext {
    margin-bottom: 20px;
    justify-content: center;
  }

  #Experiences .experience-texts h4 {
    font-size: 16px;
  }

  #face-toface .total-completebtns {
    margin: 31px 0;
  }

  #face-toface .video-calltext h6 {
    padding-bottom: 8px;
    padding-top: 23px;
  }

  .video-calltext .usd-input {
    height: 44px;
    border-radius: 22px;
  }

  .video-calltext .usd-input h5 {
    display: none;
  }

  #face-toface .add-slotsbtn {
    display: none;
  }

  .sample-user-content svg {
    display: none;
  }

  #face-toface .priority-dm {
    margin-top: 24px;
  }

  .new-card-add p {
    font-size: 13px;
  }

  #billing-address-modal\ header-mobile-drawer {
    /* position: fixed;
    width: auto;
   
    pointer-events: none;
    bottom: 0;
    height: 100%;
    overflow: scroll; */
    margin: 0;
    align-items: flex-end;
    min-height: calc(100% - (.1rem * 0.1)) !important;
  }

  #billing-address-modal\ header-mobile-drawer .modal-content {
    justify-content: flex-end;
    /* height: 100vh; */
    background-color: transparent;
    /* overflow: scroll; */
  }

  #billing-address-modal\ header-mobile-drawer #address-modal {
    /* overflow: scroll; */
    padding: 8px 16px;
    border-radius: 35px 35px 0px 0px !important;
    background: linear-gradient(180deg, #181818 0%, #000 100%) !important;
    padding-top: 0 !important;
  }

  #billing-address-modal\ header-mobile-drawer #address-modal .modal-header {
    display: none;
  }

  #billing-address-modal\ header-mobile-drawer #address-modal .customer-billing {
    margin-top: 16px;
  }

  #billing-address-modal\ header-mobile-drawer .customer-billing .modal-header h4 {
    display: none;
  }

  #billing-address-modal\ header-mobile-drawer .footer-icon {
    display: block;
  }

  #billing-address-modal\ header-mobile-drawer .customer-billing h4 {
    display: block;
  }

  .payments-monthly .test-addrow {
    padding-left: 30px;
  }

  .show-listsection {
    flex-direction: column;
    text-align: center;
    margin-bottom: 25px;
  }

  .shows-name h4 {
    font-size: 16px;
    line-height: 16px;
  }

  .shows-name p {
    font-size: 14px;
    line-height: 14px;
  }

  .shows-name {
    padding-bottom: 12px;
  }

  .remember-modal {
    max-width: 19rem;
    padding: 20px 15px 35px 15px;
  }

}

@media only screen and (max-width: 480px) {
  .form-bg-mobile1 {
    padding-top: 122px;
  }

  #d-container2 {
    width: 100% !important;
  }

  .series-btn {
    margin-top: 10% !important;
  }

  .d-title-feature {
    font-size: 28px;
  }

  .veuit-channel-btn {
    font-size: 14px;
  }

  .veuit-channel-signin {
    font-size: 14px;
  }

  header .navbar ul li a,
  header .navbar ul li.menu-item a {
    padding: 10px !important;
  }

  .d-tvchannel-img {
    width: 148px !important;
    height: 95px !important;
  }

  .network-timeline {
    width: 96%;
  }

  .channel-registration {
    width: 95%;
  }

  /* .container-fluid {
        padding-left: 0 !important;
        padding-right: 0 !important;
    } */

  /* .show-movie-pills {
        overflow-x: scroll;
    } */

  .main-content.extra-tabs-set {
    width: 70%;
  }

  .remaining-photo {
    width: 25%;
  }

  .p-set-next {
    right: -46.5% !important;
  }

  header .navbar ul li a,
  header .navbar ul li.menu-item a {
    padding: 0px !important;
  }

  .footer-podscast {
    height: 654px;
  }

  #view-all-movie {
    flex: 50%;
    max-width: 50%;
  }

  div#viewall-row {
    padding-top: 20px !important;
  }

  #view-all-podcast {
    flex: 50%;
    max-width: 50%;
    padding: 0 32px;
  }

  #sign-in-logo1 {
    padding-top: 0;
  }

  #create-channels .set-domain-name1 {
    width: 75%;
    padding: 0 10px;
  }

  #create-channels .input-group-prepend {
    width: 24%;
  }

  .new-country-select {
    width: 180px;
    margin-right: 10px !important;
  }

  #zipcode-mobile {
    width: 90% !important;
  }

  .v-linkdetails .copyvlink {
    width: 85%;
  }

}

@media only screen and (max-width: 389px) {
  footer .footer-standard .widget ul li {
    padding-right: 20px;
  }

  .footer-podscast {
    height: 700px;
  }

  .show-episode-text {
    margin-left: 0px !important;
    width: 96px;
    font-size: 12px;
  }

  .show-movie-tab h4 {
    font-size: 12px;
  }

  .show-movie-tab {
    padding-left: 10px;
  }

  .show-time-details-mobile {
    margin-top: 4px;
  }

  #view-all-movie1 {
    flex: 100% 1;
    max-width: 100%;
  }

  #sign-in-logo1 {
    padding-top: 0;
  }

  .new-country-select {
    width: 147px;
  }

  #zipcode-mobile {
    width: 161px !important;
    padding: 0 11px !important;
  }

  #payment-zipcode {
    width: 94% !important;
    margin-left: 6px;
  }

  #monthlyautopay-tooltip-mobile .tooltip-inner {
    max-width: 300px;
  }

  #monthlyautopay-tooltip-mobile .right-arrow-mobile {
    top: 84%;
  }
}

@media only screen and (max-width: 375px) {
  #tech-section {
    width: 100%;
    margin: 0 auto;
  }

  .tech-border {
    padding: 5px !important;
  }

  .d-title-feature {
    font-size: 28px;
  }

  .form-bg-mobile1 {
    padding-top: 108px;
  }

  #form-bg-mobile1 {
    padding-top: 77px;
  }

  #sign-in-logo {
    padding-top: 62px;
  }

  #sign-in-logo1 {
    padding-top: 0;
  }

  input#txtLastname {
    width: 100%;
  }

  input#txtFirstname {
    width: 100%;
  }

  #set-input-width {
    width: 100%;
    margin: 0 auto;
  }

  #sign-inbtn:enabled {
    width: 100%;
  }

  #sign-inbtn:disabled {
    width: 100%;
  }

  .d-parallax-img {
    width: 98%;
  }

  .detail-actions {
    display: block;
  }

  .detail-action-btn {
    margin-left: 0px;
  }

  .show-episode {
    font-size: 11px;
    line-height: 15px;
  }

  .show-episode span {
    font-size: 11px;
  }

  .show-star-content h5 {
    font-size: 13px;
    line-height: 19px;
    padding-right: 9px;
  }

  .show-rating-details p {
    font-size: 13px;
    line-height: 19px;
  }

  .show-rating-details h4 {
    font-size: 13px !important;
    line-height: 19px !important;
    padding-left: 8px;
  }

  .show-rating-details h3 {
    font-size: 13px !important;
    line-height: 19px !important;
    padding-left: 8px;
  }

  .show-tv-btn {
    height: 19px;
    width: 53px;
    font-size: 12px;
    line-height: 16px;
    margin-left: 8px;
  }

  .show-mob-btn {
    margin-right: 9px;
  }

  .remaining-photo {
    width: 32%;
  }

  .main-content.extra-tabs-set {
    width: 60%;
  }

  .p-set-next {
    right: -70.5% !important;
  }

  button.btn.show-rent {
    font-size: 11px;
    line-height: 15px;
  }

  a#donate-now {
    margin-top: 10px !important;
    margin-left: 0;
  }

  #view-all-podcast {
    padding: 0 25px;
  }

  #forgot-inbtn {
    width: 100% !important;
  }

  .text-body-pwd {
    width: 100%;
  }

  #create-channels .input-group-prepend {
    width: 29%;
  }

  #create-channels .set-domain-name1 {
    width: 70%;
  }

  #create-channels .channel-label1 {
    font-size: 13px;
    margin-right: 15px;
  }

  .add-new-address-btn .delete-btn {
    width: 130px;
  }

  .add-new-address-btn .update-btn {
    width: 130px;
    margin-right: 10px;
  }

  .searchbar-products {
    align-items: unset;
    flex-direction: column;
  }

  .shows-search {
    justify-content: flex-end;
    margin-top: 20px;
  }

  .total-completebtns .total-btns {
    max-width: 171px;
    width: auto;
    padding: 0 10px;
  }

  .total-completebtns .completed-btns {
    max-width: 171px;
    width: auto;
    padding: 0 10px;
  }

  .existing-schedule-btn h5 {
    width: 21px;
  }

  #calender-schedule-picker {
    width: 286px;
  }

  #calender-schedule-picker .react-calendar {
    width: 286px;
  }

  #calender-schedule-picker .react-calendar__month-view__weekdays__weekday {
    margin-left: 4px;
  }

  #calender-schedule-picker button.react-calendar__tile.react-calendar__month-view__days__day {
    margin-left: 5px;
    width: 35px;
    max-width: 35px;
    height: 30px;
  }
}

@media only screen and (max-width: 360px) {
  .sign-in-forgot-pwd {
    width: auto;
  }

  .sign-in-input {
    font-size: 15px !important;
  }

  .sign-in-input-pwd {
    font-size: 15px !important;
  }

  .forgot-input {
    font-size: 15px !important;
  }

  .login-acc {
    padding-top: 0px;
  }

  .sign-in-page {
    background-position-y: 20% !important;
  }

  .tx-uppercase {
    font-size: 14px;
  }

  .d-image-1,
  .d-image-2 {
    width: 200px !important;
  }

  .channel-registration {
    width: 100%;
  }

  .set-progress-bg {
    width: 136px !important;
  }

  .our-ratings {
    width: 261px !important;
  }

  p.slider-text.d-slide-txt {
    font-size: 16px;
    line-height: 20px;
  }

  #home-slider h1.slider-text {
    font-size: 28px;
    line-height: 35px !important;
    margin: 0 10px !important;
  }

  .d-brand-network {
    font-size: 24px;
    line-height: 24px;
  }

  .d-movie-title {
    font-size: 20px !important;
  }

  .d-view-text {
    font-size: 12px;
    line-height: 14px;
  }

  .podcast-center-right {
    margin-left: 10px;
  }

  #view-all-podcast {
    padding: 0 10px;
  }

  #sign-in-logo1 {
    padding-top: 0;
  }

  #video-section #customVideo .jw-button-color:not(.jw-icon-cast),
  #customVideo .jw-button-color.jw-toggle.jw-off:not(.jw-icon-cast) {
    margin-left: -5px;
  }

  .payments-monthly {
    padding: 15.5px 12px;
  }

  .billing-address-selected {
    padding-left: 12px;
  }

  .billing-address-selected span {
    font-size: 13px;
  }

  .billing-address-selected p {
    font-size: 13px;
  }

  .app-selected {
    padding-left: 12px;
  }

  .app-selected span {
    font-size: 13px;
  }

  .app-selected p {
    font-size: 13px;
  }

  #new-channel-row #set-sign-in1 {
    width: 90%;
    margin: 0 auto;
  }

  #payment-modal-new #set-sign-in1 {
    width: 90%;
    margin: 0 auto;
  }

  .card-image {
    width: 100%;
    height: 168px;
    border-radius: 19.6934px;
  }

  #monthlyautopay-tooltip-mobile .tooltip-inner {
    max-width: 250px;
  }

  #monthlyautopay-tooltip-mobile .right-arrow-mobile {
    left: 42%;
    top: 86%;
  }

  .v-linkdetails .copyvlink {
    width: 84%;
  }

  .social-linktoggle .icons-set {
    margin-right: 16px;
  }

  .schedule-tiomezone h2 {
    padding: 0px 6px;
  }

  .existing-schedule-btn h5 {
    font-size: 14px;
  }

  .existing-schedule-btn p {
    font-size: 14px;
  }

  .existing-schedule-btn .delete-schedule-btn {
    font-size: 14px;
  }

  .existing-schedule-btn #delete-schedule-btn {
    margin-left: 25px;
  }

  .weekly-options {
    padding-left: 5px;
  }

  .existing-schedule-btn .delete-scheduleimg {
    margin-left: 15px;
  }

  .select-themes .theme-img {
    width: 130px;
    height: 200px;
  }
}